import { ReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { Deal, useRemoteData } from '@finn/platform-modules';

const returnEndpoint = (id: string) => `portal/api/bff/deals/${id}/return`;

export const useReturn = (id: string) => {
  return useRemoteData<ReturnData>(
    id && id !== 'no-deal' ? `return/${id}` : null,
    () => returnEndpoint(id)
  );
};

export const useScheduledReturn = (deal: Deal): ReturnData | undefined => {
  const hasGreenDragonReturnScheduled =
    deal?.return_type && deal?.belongs_to_subscription_service === true;
  const dealIdIfReturnScheduled = hasGreenDragonReturnScheduled
    ? deal.id
    : null;

  return useReturn(dealIdIfReturnScheduled)?.data;
};
