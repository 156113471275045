import { If } from '@finn/b2c-cp/features-components/IfRender';
import { useTextWithValues } from '@finn/b2c-cp/features-data';
import { FormDescription, FormItem, FormLabel } from '@finn/b2c-cp/ui/form';
import { Input } from '@finn/design-system/atoms/input';
import { InfoToolTip } from '@finn/ui-components';
import omit from 'lodash/omit';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerProps, useFormContext } from 'react-hook-form';

import { Field } from '../types';

type InputFieldProps = Field &
  Parameters<ControllerProps['render']>['0']['field'];

const DescriptionItem = ({ children }: { children: string }) => {
  const [description] = useTextWithValues(children);

  return (
    <FormDescription className="text-base font-normal">
      {description}
    </FormDescription>
  );
};

export const InputField = forwardRef(
  ({ ...field }: InputFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { formState } = useFormContext();
    if (
      field.type !== 'text' &&
      field.type !== 'number' &&
      field.type !== 'password'
    ) {
      return null;
    }

    const error = formState.errors?.[field.name];

    return (
      <FormItem className={field.className}>
        <If condition={Boolean(field.title)}>
          <FormLabel className="inline-flex gap-1 text-base font-normal">
            {field.title}
            {field.tooltip?.label ? (
              <InfoToolTip content={field.tooltip?.label} />
            ) : null}
          </FormLabel>
        </If>
        <Input
          ref={ref}
          label={field.label}
          error={error?.message}
          {...omit(field, 'visibilityConfig')}
        />
        {field.descriptions?.map((description) => (
          <DescriptionItem key={description}>{description}</DescriptionItem>
        ))}
      </FormItem>
    );
  }
);
