import { ReturnType } from '../useReturnData';

export type Values = {
  returnType?: ReturnType;
  date?: Date;
  mileage?: number;
  sameDeliveryAddress?: boolean;
  houseNumber?: string;
  streetName?: string;
  city?: string;
  zip?: string;
  extra?: string;
};

import { Deal } from '@finn/platform-modules';

enum DefectHandlers {
  CLAIMINI = 'claimini',
  ACTIVE_FLEET = 'active_fleet',
}

type TechnicalDefect = {
  key: string;
  handler: DefectHandlers;
  type?: string;
  area?: string;
  part?: string;
};

const TECHNICAL_DEFECTS: TechnicalDefect[] = [
  {
    type: '18',
    area: '5',
    part: '77',
    key: 'multi_media_system',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '64',
    key: 'battery',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '84',
    key: 'high_voltage',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '65',
    key: 'oil',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '66',
    key: 'coolant',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '72',
    key: 'fuelsystem',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '69',
    key: 'engine_light',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '71',
    key: 'abs_esp',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '68',
    key: 'brakes',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '70',
    key: 'tire_pressure',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '67',
    key: 'airbag',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '74',
    key: 'electric_door_locking_system',
    handler: DefectHandlers.ACTIVE_FLEET,
  },

  {
    type: '18',
    area: '5',
    part: '75',
    key: 'driver_assistance_system',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '79',
    key: 'heating',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '80',
    key: 'air_conditioning',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '82',
    key: 'gearbox',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '83',
    key: 'engine',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '96',
    key: 'trailer_hitch',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '98',
    key: 'marten_bites',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '21',
    area: '5',
    part: '162',
    key: 'lights',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '189',
    key: 'suspension',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  {
    type: '18',
    area: '5',
    part: '92',
    key: 'other',
    handler: DefectHandlers.ACTIVE_FLEET,
  },
  { key: 'bumper', handler: DefectHandlers.CLAIMINI },
  { key: 'fender', handler: DefectHandlers.CLAIMINI },
  {
    key: 'windshield',
    handler: DefectHandlers.CLAIMINI,
  },
  { key: 'door', handler: DefectHandlers.CLAIMINI },
  { key: 'rim', handler: DefectHandlers.CLAIMINI },
];

const parseValues = (values) => {
  const valueRetriever = (key) => {
    if (values[key]) return values[key];

    return null;
  };

  const defectType = valueRetriever('technicalDefect');
  const technicalDefect = TECHNICAL_DEFECTS.find(
    (defect) => defect.key === defectType
  );

  const getDamageDueDateTime = () => {
    const date = valueRetriever('damageOccurrenceDate');
    if (date) {
      return new Date(date).toISOString();
    } else {
      return null;
    }
  };

  const body = {
    damage_key: technicalDefect.key,
    damage_handler: technicalDefect.handler,
    damage_part_id: technicalDefect.part,
    damage_area_id: technicalDefect.area,
    damage_type_id: technicalDefect.type,
    damage_occurrence_date: getDamageDueDateTime(),
    damage_description: valueRetriever('damageDescription'),
    new_address: undefined,
  };

  if (values.maintenanceAddress === 'anotherAddress') {
    body.new_address = {
      street_name: valueRetriever('street'),
      house_number: valueRetriever('housenumber'),
      zip: valueRetriever('zip'),
      city: valueRetriever('city'),
    };
  }

  return body;
};

export const prepareData = (subscription: Deal, values: object) => {
  return {
    ...parseValues(values), // FIXME: place
    car_id: subscription.car_id,
    damage_report_date: new Date().toISOString(),
  };
};
