import { cn } from '@finn/ui-utils';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

export type RadioGroupProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Root
> & {
  label?: string;
  direction?: 'row' | 'col';
};

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, direction = 'col', ...props }, ref) => {
  return (
    <>
      {props.label ? (
        <p className="body-16-semibold mb-6">{props.label}</p>
      ) : null}
      <RadioGroupPrimitive.Root
        className={cn(
          'grid w-max max-w-full gap-6',
          {
            'grid-cols-1': direction === 'col',
            'grid-cols-[repeat(auto-fit,minmax(14px,1fr))]':
              direction === 'row',
          },
          className
        )}
        {...props}
        ref={ref}
      />
    </>
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export { RadioGroup };
