import { trackEvent } from '@finn/ui-utils';
import { useEffect, useRef } from 'react';

// we ensure that we track that button was shown only once per mount
export const useFeatureTracking = ({
  variant = 'action_button',
  isVisible,
  action,
  modalName,
  url,
}: {
  variant?: 'action_button' | 'banner';
  isVisible?: boolean;
  action: string;
  modalName?: string;
  url?: string;
}) => {
  const alreadyTracked = useRef(false);

  useEffect(() => {
    if (!alreadyTracked.current && isVisible) {
      const name =
        variant === 'action_button' ? 'Action Button Shown' : 'Banner Shown';
      trackEvent(name, { action, modalName, url });
      alreadyTracked.current = true;
    }
  }, [variant, isVisible, action, modalName, url]);
};
