import {
  Deal,
  useDeal,
  VisibilityConfig,
  VisibilityConfigs,
} from '@finn/platform-modules';
import { useMemo } from 'react';

import { useDealId } from '../../MetadataProvider';
import { useValue } from '../useValue';
import { doesValueSatisfiesConfig } from './doesValueSatisfiesConfig';

const doesConfigSatisfiesCondition = (
  item: VisibilityConfig,
  fieldsValueMap: { [key: string]: string | number | boolean },
  deal: Deal
) => {
  if ('exceptionId' in item) {
    if (item?.exceptionId === 'buy_car') {
      const vin = fieldsValueMap['{car_inventory.data.data.inventory.0.vin}'];
      const { date_field, min_days_before_return, min_days_till_return } =
        item?.config || {};
      const returnDate =
        deal?.[(date_field || 'end_date') as keyof Deal] ||
        fieldsValueMap['{deal.end_date}'];

      return (
        Boolean(vin) &&
        doesValueSatisfiesConfig(returnDate as string, {
          type: 'days',
          minValue: Number(min_days_before_return),
          maxValue: Number(min_days_till_return),
        })
      );
    }
  }

  const fieldValueKey = Array.isArray(item.field)
    ? item.field.find((field) => fieldsValueMap[field] !== undefined)
    : item.field;

  return doesValueSatisfiesConfig(fieldsValueMap[fieldValueKey], item);
};

const doesConfigsSatisfiesConditions = (
  item: VisibilityConfigs,
  fieldsValueMap: { [key: string]: string | number | boolean },
  deal: Deal,
  type: 'or' | 'and' = 'and'
) => {
  if (typeof item !== 'object') {
    return;
  }

  if ('type' in item && 'configs' in item) {
    return doesConfigsSatisfiesConditions(
      item.configs,
      fieldsValueMap,
      deal,
      item.type
    );
  }
  if (Array.isArray(item)) {
    const operations = {
      or: () =>
        item.some((config) =>
          doesConfigsSatisfiesConditions(config, fieldsValueMap, deal, 'or')
        ),
      and: () =>
        item.every((config) =>
          doesConfigsSatisfiesConditions(config, fieldsValueMap, deal, 'and')
        ),
    };

    return operations[type]?.();
  }

  return doesConfigSatisfiesCondition(
    item as VisibilityConfig,
    fieldsValueMap,
    deal
  );
};

// we convert our nested configs to flat array of fields
const getAllFields = (configs: VisibilityConfigs) => {
  if (typeof configs !== 'object') {
    return [];
  }

  if ('type' in configs && 'configs' in configs) {
    return getAllFields(configs.configs);
  }
  if (Array.isArray(configs)) {
    return configs.reduce((acc, config) => {
      acc.push(...getAllFields(config));

      return acc;
    }, []);
  }

  if ('exceptionId' in configs) {
    switch (configs.exceptionId) {
      case 'buy_car':
        return ['{car_inventory.data.data.inventory.0.vin}', '{deal.end_date}'];
      default:
        return [];
    }
  }

  if ('field' in configs) {
    return [configs.field];
  }

  return [];
};

export const useIsFeatureVisible = (
  config?: VisibilityConfigs,
  defaultState?: boolean
) => {
  const dealId = useDealId();
  const paths = useMemo(() => getAllFields(config), [config]);
  const [fieldValues] = useValue({ paths });
  // map paths to values and store in Map
  const fieldsValueMap = useMemo(
    () =>
      paths.reduce((acc, path, index) => {
        acc[Array.isArray(path) ? path[0] : path] = fieldValues[index];

        return acc;
      }, {}),
    [fieldValues, paths]
  );
  const { data: deal } = useDeal(dealId);

  // we check that all configs are satisfied, recursivly
  const result = useMemo(
    () => doesConfigsSatisfiesConditions(config, fieldsValueMap, deal),
    [fieldsValueMap, config, deal]
  );

  if (!config) {
    return defaultState === undefined ? true : defaultState;
  }

  const isValidConfig =
    typeof config === 'object' && (!Array.isArray(config) || config.length > 0);

  // we check that we did not receive any configs and return default data
  return isValidConfig || defaultState === undefined ? result : defaultState;
};
