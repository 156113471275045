import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useValue } from '@finn/b2c-cp/features-data';
import { InfoRow as InfoRowProps } from '@finn/platform-modules';

export const InfoRow = ({ label, type, field, style }: InfoRowProps) => {
  const [value, status] = useValue(field, { type });

  return (
    <div className="flex justify-between" style={style}>
      <span className="text-base font-semibold">{label}</span>
      <Skeleton loading={status !== 'success'} width={'100px'}>
        <span className="text-trustedBlue text-2xl font-semibold">{value}</span>
      </Skeleton>
    </div>
  );
};
