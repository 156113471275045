import { useRemoteData } from '@finn/platform-modules';
import { Customer } from '@finn/ua-auth';
import { useMemo } from 'react';

const driversEndpoint = () => `portal/api/bff/drivers`;

export const useDrivers = () => {
  const { data, status, mutate } = useRemoteData<Customer[]>(
    'drivers',
    driversEndpoint,
    []
  );

  return useMemo(
    () => ({
      mutate,
      drivers: data,
      driversStatus: status,
    }),
    [data, status, mutate]
  );
};
