import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useInvoicesData } from '@finn/b2c-cp/features-data/hooks/useInvoicesData';

import { Divider } from '../Divider';
import { InvoiceItem } from './InvoiceItem';

type InvoicesListProps = {
  title: string;
  translations: {
    invoice_labels: {
      paid: string;
      failed: string;
      pending: string;
    };
    open_invoice: string;
    no_invoices: string;
  };
};

export const InvoicesList = ({ title, translations }: InvoicesListProps) => {
  const { data: invoices, status } = useInvoicesData();

  return (
    <div>
      <h2 className="mobile-t2-semibold md:web-t2-semibold my-14">{title}</h2>
      <Skeleton loading={status === 'loading'} fullWidth>
        {invoices?.length > 0 ? (
          <div className="flex flex-col items-center">
            {invoices?.map((invoice, index) => {
              return (
                <>
                  {index !== 0 ? <Divider /> : null}
                  <InvoiceItem
                    invoice={invoice}
                    translations={translations}
                    key={invoice.id}
                  />
                </>
              );
            })}
          </div>
        ) : (
          <h5 className="global-t5-semibold my-14">
            {translations?.no_invoices}
          </h5>
        )}
      </Skeleton>
    </div>
  );
};
