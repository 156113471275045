import { Tabs, TabsList, TabsTrigger } from '@finn/design-system/atoms/tabs';
import { useDeals } from '@finn/platform-modules';
import { useSignOut } from '@finn/ua-auth';
import { isMobileApp, isServer, trackEvent, useSession } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export type NavigationProps = {
  tabs: {
    data: {
      value?: string;
      label: string;
      group?: string;
    };
  }[];
};

// we have urls on server different from client for SSR reasons
// you can find them in next.config.js
const getServerTabName = (path: string) => {
  if (path?.endsWith('my-car-details')) {
    return '/mycars';
  }
  // we need this small trick due to hidden navigation
  // that we use to optimize SSR
  if (path?.endsWith('drivers-list') || path?.endsWith('add-drivers')) {
    return '/myaccount/drivers';
  }
  if (
    path?.endsWith('edit-account') ||
    path?.endsWith('change-password') ||
    path?.endsWith('edit-company') ||
    path?.endsWith('invoices')
  ) {
    return '/myaccount';
  }

  return path;
};

const getClientTabName = (path?: string) => {
  if (path?.startsWith('/myaccount/drivers')) {
    return '/myaccount/drivers';
  } else if (path?.startsWith('/myaccount')) {
    return '/myaccount';
  } else if (path?.startsWith('/mycars')) {
    return '/mycars';
  }

  return path;
};

export const Navigation = ({ tabs }: NavigationProps) => {
  const router = useRouter();
  // we need to use local proxy state to instantly mark active tab, and not wait
  // for route to update url
  const [activeTab, setActiveTab] = useState(
    isServer()
      ? getServerTabName(router.asPath)
      : getClientTabName(router.asPath)
  );
  const [session] = useSession();
  const { handleSignOut } = useSignOut({ session });

  const actionTabs = tabs?.filter(({ data }) => data.group === 'actions');
  const regularTabs = tabs?.filter(({ data }) => data.group !== 'actions');
  const { deals, dealsStatus } = useDeals();
  const isCustomer =
    dealsStatus === 'loading' ? false : Boolean(deals?.length) || false;

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(value);
      trackEvent('NavItem Clicked', { option: value, is_customer: isCustomer });

      if (value === '/logout') {
        handleSignOut();
      } else if (value) {
        router.push(value, null, { shallow: false });
      }
    },
    [isCustomer, handleSignOut, router]
  );

  // we keep in sync url with internal state
  useEffect(() => {
    if (getClientTabName(router.asPath) !== activeTab) {
      setActiveTab(getClientTabName(router.asPath));
    }
  }, [activeTab, router.asPath]);

  if (isMobileApp()) {
    return null;
  }

  return (
    <Tabs
      className="sticky top-0 z-10 -my-4 w-full min-w-56 bg-white p-0 py-4 md:top-4 md:h-max md:max-w-56 md:py-0"
      value={activeTab}
      onValueChange={handleTabChange}
    >
      <TabsList direction="vertical" className="p-0 py-0.5 pl-0.5 pr-4 md:pr-0">
        {regularTabs?.map(({ data: { value, label } }) => (
          <TabsTrigger
            key={value}
            value={value}
            onClick={() => {
              // small trick to refresh/reload active tab
              if (activeTab === value) {
                router.push(value, null, { shallow: false });
              }
            }}
          >
            {label}
          </TabsTrigger>
        ))}
        <div className="border-pearl my-6 hidden border-t md:block" />
        {actionTabs?.map(({ data: { value, label } }) => (
          <TabsTrigger key={value} value={value}>
            {label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
