import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { Builder } from '@finn/b2c-cp/features-components/Builder';
import { InfoModal } from '@finn/b2c-cp/features-components/InfoModal';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useServiceBookingPortal } from '@finn/b2c-cp/features-data';
import { useDeals } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export type NoDealProps = {
  title?: string;
  selectLabel?: string;
  ctaLabel?: string;
  requiredField?: string;
  type?: 'report-damage' | 'schedule-service';
  error_modal: {
    title: string;
    affirmative_text: string;
    description: string;
  };
};

const NO_DEAL_ERROR_MODAL = 'NO_DEAL_ERROR_MODAL';

export const NoDeal = ({
  title,
  selectLabel,
  ctaLabel,
  requiredField,
  type = 'report-damage',
  error_modal,
}: NoDealProps) => {
  const { deals, dealsStatus } = useDeals();
  const router = useRouter();
  const { openModal } = useModalStore();

  // prepare list of options for select field
  const cars = useMemo(
    () =>
      deals?.map((sub) => {
        const licensePlate = sub?.car?.license_plate;

        return {
          label: `${sub?.car?.oem} ${sub?.car?.model} ${
            licensePlate ? `- ${licensePlate}` : ''
          }`,
          value: sub.id,
        };
      }),
    [deals]
  );

  // current selected car
  const selectedCar = useWatch({ name: 'selectedCar' });
  const currentCar = selectedCar || cars?.[0]?.value;

  const { data: serviceBookingPortal, status } =
    useServiceBookingPortal(currentCar);

  const continueLink =
    type === 'report-damage'
      ? `/mycars/${currentCar}/report-damage?utm_source=services`
      : (serviceBookingPortal?.url ?? `/mycars/${currentCar}/schedule-service`);

  const handleContinue = useCallback(() => {
    router.push(continueLink);
  }, [router, continueLink]);

  useEffect(() => {
    if (dealsStatus === 'success' && deals?.length === 0) {
      openModal(NO_DEAL_ERROR_MODAL);
    }
  }, [deals?.length, dealsStatus, openModal]);

  const fields = useMemo(
    () =>
      cars?.length
        ? [
            {
              type: 'select' as const,
              name: 'selectedCar',
              label: selectLabel,
              defaultValue: cars?.[0].value,
              validation: {
                required: requiredField,
              },
              labelsMap: cars?.reduce(
                (res, car) => ({ ...res, [car.value]: car.label }),
                {}
              ),
              values: cars?.map((car) => car.value),
              required: true,
            },
          ]
        : [],
    [cars, selectLabel, requiredField]
  );

  const renderErrorModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="error"
      open={open}
      onClose={onClose}
      heading={error_modal?.title}
      htmlDescription={error_modal?.description}
      affirmationText={error_modal?.affirmative_text}
      onAffirmation={() => {
        router.push(`${SelfServiceRoutes.MY_CARS}`);
      }}
    />
  );

  return (
    <>
      <Builder
        title={title}
        fields={fields}
        isCTADisabled={() =>
          dealsStatus === 'loading' ||
          deals?.length === 0 ||
          (type === 'schedule-service' && status === 'loading')
        }
        footer={{
          cta: {
            label: ctaLabel,
            action: 'next-step',
            onCtaClick: handleContinue,
          },
        }}
        renderContent={() =>
          cars?.length ? null : (
            <Skeleton fullWidth loading height="50px">
              <div />
            </Skeleton>
          )
        }
      />
      <ModalContainer
        name={NO_DEAL_ERROR_MODAL}
        renderModal={renderErrorModal}
      />
    </>
  );
};
