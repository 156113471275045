import { useFormField } from '@finn/b2c-cp/ui/form';
import { Input } from '@finn/design-system/atoms/input';
import { useCurrentLocale } from '@finn/ui-utils';
import dayjs from 'dayjs';
import { Ref } from 'react';
import { ControllerProps } from 'react-hook-form';
import { IMaskMixin } from 'react-imask';

import { Field } from '../types';

type DateInputFieldProps = Field & {
  type: 'date-custom' | 'date-input';
} & Parameters<ControllerProps['render']>['0']['field'];

// TODO used deprecated component, to match design
// refactor after design update

const IMaskInput = IMaskMixin(({ inputRef, defaultValue, ...props }) => {
  return (
    <Input
      ref={inputRef as Ref<HTMLInputElement>}
      defaultValue={defaultValue as string}
      {...props}
    />
  );
});

export const DateCustomField = ({ ...field }: DateInputFieldProps) => {
  const { error } = useFormField();

  const { locale } = useCurrentLocale();

  const formattedValue = dayjs(field.value).isValid()
    ? dayjs(field.value).toDate().toLocaleString(locale, {
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
      })
    : field.value;

  return (
    <IMaskInput
      {...field}
      mask="00.00.0000"
      lazy={false}
      placeholderChar="_"
      value={formattedValue}
      shouldShowLabelAtTop
      disabled={field.disabled}
      label={field.label}
      error={error?.message}
      onAccept={(nextValue) => {
        if (nextValue !== formattedValue) {
          field.onChange(nextValue);
        }
      }}
    />
  );
};
