import { useDealId } from '@finn/b2c-cp/features-data';
import { useDeal } from '@finn/platform-modules';
import { formatDate } from '@finn/ui-utils';
import { useFormContext } from 'react-hook-form';

type ReportDamageSummaryProps = {
  fields: { key: string; label: string }[];
  labels_map: { [key: string]: string };
};

export const ReportDamageSummary = ({
  fields,
  labels_map,
}: ReportDamageSummaryProps) => {
  const { getValues } = useFormContext();
  const formValues = getValues();
  const dealId = useDealId();
  const { data } = useDeal(dealId);
  const licensePlate = data?.car?.license_plate;
  const cosmicFormValuesMap = {
    vehicle: `${data?.car?.oem} ${
      data?.car?.model
    }${licensePlate ? `, ${licensePlate}` : ''}`,
    damageOccurenceDate: formValues.damageOccurrenceDate
      ? formatDate(formValues.damageOccurrenceDate, 'DD.MM.YYYY')
      : '-',
    technicalDefect: labels_map[formValues.technicalDefect],
    address: `${formValues.street} ${formValues.housenumber}, ${formValues.zip} ${formValues.city}`,
  };

  return (
    <div>
      {fields.map((field) => (
        <div key={field.key} className="flex justify-between">
          <span className="text-base font-normal">{field.label}</span>
          <span className="text-base font-semibold">
            {cosmicFormValuesMap[field.key]}
          </span>
        </div>
      ))}
    </div>
  );
};
