import { Skeleton } from '../../Skeleton';

export const BuilderLoader = () => {
  return (
    <>
      <div className="my-2" />
      <Skeleton loading>
        <div className="h-8 w-64" />
      </Skeleton>
      <div className="my-2" />
      <Skeleton loading>
        <div className="h-64 w-[440px]" />
      </Skeleton>
      <div className="my-2" />
      <Skeleton loading fullWidth>
        <div className="h-16" />
      </Skeleton>
    </>
  );
};
