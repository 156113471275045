import { cn } from '@finn/ui-utils';
import * as LabelPrimitive from '@radix-ui/react-label';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

const accessabilityClassNames =
  'focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-0';
const activeBorderClassNames =
  'active:ring-1 active:ring-black active:border-black';
const defaultBorderClassNames =
  'border border-pewter border-solid rounded-full group-hover/radio-item:ring-1 ring-pearl data-[state="checked"]:border-black data-[state="checked"]:disabled:border-pewter';

const layoutClassNames =
  'grid grid-cols-[min-content,1fr] grid-rows-[minmax(1.25rem, max-content)] gap-x-2';

export type RadioItemProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
> & {
  label: string;
  subtitle?: string;
  error?: boolean;
};

const RadioItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioItemProps
>(({ className, ...props }, ref) => {
  const id = props.id ?? `${props.label}-${props.value}`;

  return (
    <div
      className={cn(
        'group/radio-item cursor-pointer has-[:disabled]:pointer-events-none',
        layoutClassNames,
        {
          // we use 1.25rem as it comes from figma, which equals to 5 steps in tailwind
          // sadly we can not use the tailwind steps here, so we use 1.25rem
          'grid-rows-[1.25rem,max-content] gap-y-3': props.subtitle,
          'grid-rows-[minmax(1.25rem,1fr)]': !props.subtitle,
        },
        className
      )}
    >
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'group peer row-span-2 flex h-5 w-5 cursor-pointer items-center justify-center bg-white p-0',
          activeBorderClassNames,
          defaultBorderClassNames,
          accessabilityClassNames,
          {
            // we use important, as in case of error nothing can override the red color
            '!ring-red !border-red ring-1': props.error,
          }
        )}
        id={id}
        {...props}
      >
        <RadioGroupPrimitive.Indicator
          className={cn(
            'group-disabled:bg-steel inline-block min-h-3 min-w-3 rounded-full bg-black',
            {
              'bg-red': props.error,
            }
          )}
        />
      </RadioGroupPrimitive.Item>
      <LabelPrimitive.Root
        className={cn(
          'peer-disabled:text-steel inline-block cursor-pointer self-center',
          props.subtitle ? 'body-14-semibold' : 'body-14-light',
          {
            'text-red peer-disabled:text-red': props.error,
          }
        )}
        htmlFor={id}
      >
        {props.label}
      </LabelPrimitive.Root>
      {props.subtitle ? (
        <LabelPrimitive.Root
          className={cn(
            'body-14-light peer-disabled:text-steel inline-block cursor-pointer',
            {
              'text-red': props.error,
            }
          )}
          htmlFor={id}
        >
          {props.subtitle}
        </LabelPrimitive.Root>
      ) : null}
    </div>
  );
});
RadioItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioItem };
