export type Address = {
  house_number: string;
  street: string;
  city: string;
  zip: string;
  address_extra?: string;
};

export const formatAddress = (address?: Address): string => {
  if (!address) {
    return '-';
  }

  const streetName = address.street || '-';
  const houseNumber = address.house_number || '';
  const zipCode = address.zip || '';
  const city = address.city || '';
  const extra = address.address_extra?.trim();

  return `${streetName} ${houseNumber}, ${
    extra?.length ? `${extra},` : ''
  } ${zipCode} ${city}`;
};
