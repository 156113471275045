import ModalContainer, {
  ModalProps,
} from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { appointmentStep } from '@finn/b2c-cp/features/EditReturn/steps/appointmentStep';
import { contactStep } from '@finn/b2c-cp/features/EditReturn/steps/contactStep';
import { locationStep } from '@finn/b2c-cp/features/EditReturn/steps/locationStep';
import {
  constructReturnRequest,
  overviewStep,
} from '@finn/b2c-cp/features/EditReturn/steps/overviewStep';
import { returnTypeSelectionStep } from '@finn/b2c-cp/features/EditReturn/steps/returnTypeSelectionStep';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { useDealId, useReturnMetadata } from '@finn/b2c-cp/features-data';
import { useUpdateReturnFormSubmit } from '@finn/b2c-cp/features-data/hooks/useFormSubmit/useUpdateReturnSubmit';
import { usePreReturnAppraisal } from '@finn/b2c-cp/features-data/hooks/usePreReturnAppraisal';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { Deal, useCosmicModule, useDeal } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';
import { useCallback, useMemo, useState } from 'react';

type EditReturnModalProps = ModalProps & {
  subscription?: Deal;
};

const MULTISTEP_EDIT_RETURN_MODAL = 'MULTISTEP_EDIT_RETURN';

export const EditReturnModal = (props: EditReturnModalProps) => {
  const dealId = useDealId();
  const content = useCosmicModule('advanced-edit-return');
  const { data: deal, mutate: mutateDeal } = useDeal(dealId);
  const { data: returnMetadata, status: availabilitiesLoadingStatus } =
    useReturnMetadata(dealId);
  const { data: returnData, mutate: mutateReturn } = useReturn(dealId);
  const { mutate: mutatePreReturnAppraisal } = usePreReturnAppraisal(dealId);

  const availabilitiesIsLoading = availabilitiesLoadingStatus === 'loading';
  const [canSubmit, setCanSubmit] = useState(true);
  const { openModal, closeModal } = useModalStore();
  const { handleSubmit } = useUpdateReturnFormSubmit({
    success_toast_title: content?.toast?.success?.title,
    success_toast_description: content?.toast?.success?.description,
    error_toast_title: content?.toast?.error?.title,
    error_toast_description: content?.toast?.error?.description,
  });

  const handleFormSubmit = useCallback(
    async (formData) => {
      const payload = constructReturnRequest(formData);

      const status = await handleSubmit(payload);

      if (status === 200) {
        trackEvent('Edit Return Submitted', {
          continue_to_pre_return_appraisal_booking:
            formData.proceed_to_pre_return_appraisal_booking ? 'true' : null,
        });
        closeModal();
        mutateDeal();
        mutateReturn();
        mutatePreReturnAppraisal();

        if (formData.proceed_to_pre_return_appraisal_booking) {
          openModal('onsite-appraisal-booking');
        }
      } else {
        openModal('request-error-modal');
      }
    },
    [
      handleSubmit,
      closeModal,
      mutateDeal,
      mutateReturn,
      mutatePreReturnAppraisal,
      openModal,
    ]
  );

  const steps = useMemo(
    () => [
      {
        ...returnTypeSelectionStep({
          content: {
            title: content?.location_selection?.title,
            footerCta: content?.location_selection?.cta,
          },
          returnMetadata,
          availabilitiesIsLoading,
        }),
        id: 'locationSelectionStep',
        slug: 'locationSelection',
      },
      {
        ...locationStep({
          content: {
            title: content?.location?.title,
            footerCta: content?.location?.cta,
            validation: {
              street: content?.location?.street?.validation,
              housenumber: content?.location?.housenumber?.validation,
              city: content?.location?.city?.validation,
              postcodeRequired: content?.location?.postcode?.required,
              postcodeMinLength: content?.location?.postcode?.minLength,
              postcodeMaxLength: content?.location?.postcode?.maxLength,
              germanZipCodeForDelivery:
                content?.location?.postcode?.germanZipCodeForDelivery,
            },
          },
          returnMetadata,
          returnData,
          availabilitiesIsLoading,
        }),
        id: 'locationStep',
        slug: 'location',
      },
      {
        ...appointmentStep({
          deal,
          title: content?.appointment?.title,
          footerCta: content?.appointment?.cta,
        }),
        id: 'appointmentStep',
        slug: 'appointment',
      },
      {
        ...contactStep({
          deal,
          title: content?.contact.title,
          cta: content?.contact?.cta,
          phoneNumberRequiredValidation:
            content?.contact?.phone_number?.required_validation,
          phoneNumberFormatValidation:
            content?.contact?.phone_number?.format_validation,
        }),
        id: 'contactStep',
        slug: 'contact',
      },
      {
        ...overviewStep({
          deal,
          title: content?.overview?.title,
          cta: content?.overview?.cta,
          canSubmit,
          setCanSubmit: (value) => setCanSubmit(value),
          compoundLocations: returnMetadata?.compound_locations,
        }),
        id: 'overviewStep',
        slug: 'overview',
      },
    ],
    [
      content?.location_selection?.title,
      content?.location_selection?.cta,
      content?.location?.title,
      content?.location?.cta,
      content?.location?.street?.validation,
      content?.location?.housenumber?.validation,
      content?.location?.city?.validation,
      content?.location?.postcode?.required,
      content?.location?.postcode?.minLength,
      content?.location?.postcode?.maxLength,
      content?.location?.postcode?.germanZipCodeForDelivery,
      content?.appointment?.title,
      content?.appointment?.cta,
      content?.contact.title,
      content?.contact?.cta,
      content?.contact?.phone_number?.required_validation,
      content?.contact?.phone_number?.format_validation,
      content?.overview?.title,
      content?.overview?.cta,
      returnMetadata,
      availabilitiesIsLoading,
      returnData,
      deal,
      canSubmit,
    ]
  );

  return (
    <ModalBuilder
      open={props.open}
      onFormSubmit={handleFormSubmit}
      onClose={props.onClose}
      steps={steps}
      location={MULTISTEP_EDIT_RETURN_MODAL}
    />
  );
};

const renderNewEditReturnModal = (open: boolean, onClose: () => void) => {
  return <EditReturnModal open={open} onClose={onClose} />;
};

export const EditReturnModalContainer = () => {
  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer
      name={MULTISTEP_EDIT_RETURN_MODAL}
      renderModal={renderNewEditReturnModal}
    />
  );
};
