import { isSameDaySwapHandover } from '@finn/b2c-cp/bff/bff-helpers';
import { Divider } from '@finn/b2c-cp/features/Divider';
import { getFlowVariant } from '@finn/b2c-cp/features/EditHandover/helpers';
import { useHandoverChangeFee } from '@finn/b2c-cp/features/EditHandover/hooks/useHandoverChangeFee';
import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import {
  daysFromNowToDate,
  useHandoverMetadata,
} from '@finn/b2c-cp/features-data';
import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { formatAddress } from '@finn/b2c-cp/services/format-address';
import { Deal, useCosmicModule } from '@finn/platform-modules';
import { CountryCode, trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

const OverviewStep = ({
  deal,
  setCanSubmit,
}: {
  deal: Deal;
  setCanSubmit: (canSubmit: boolean) => void;
}) => {
  const content = useCosmicModule('advanced-edit-handover');
  const methods = useFormContext();
  const { locale, region } = useCurrentLocale();
  const formData = methods.getValues();
  const { formatNumber } = useIntl();

  const { data: handover } = useHandover(deal.id);
  const deliveryFeeInEuros = handover?.fee;

  const { data: handoverMetadata } = useHandoverMetadata(deal?.id, {
    postcode: formData.postcode,
  });

  const slots = handoverMetadata?.availabilities
    ?.map((item) => item.available_slots)
    .flat();
  const slotLabel = slots?.find(
    (slot) => slot.value === formData.slot
  )?.formatted_time;

  const formattedAddress = formatAddress({
    street: formData.street,
    house_number: formData.housenumber,
    zip: formData.postcode,
    city: formData.city,
    address_extra: formData.address_suffix,
  });

  const appointmentDisplay = `${new Intl.DateTimeFormat(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(
    formData.appointment_preference
  )} ${content?.overview?.address?.at} ${String(slotLabel)}`;

  const { data: changeFee, isLoading: feeDataIsLoading } = useHandoverChangeFee(
    deal?.id,
    {
      handover_firstname: formData.firstname,
      handover_lastname: formData.lastname,
      handover_housenumber: formData.housenumber,
      handover_street: formData.street,
      handover_city: formData.city,
      handover_zip: formData.postcode,
      handover_phone_number: formData.phone_number,
      handover_address_extra: formData.address_suffix,
      preferred_handover_date: formData.slot,
    }
  );

  const modalVariant = getFlowVariant(
    changeFee,
    isSameDaySwapHandover(deal),
    feeDataIsLoading
  );

  useEffect(() => {
    setCanSubmit(!feeDataIsLoading);
  }, [feeDataIsLoading, setCanSubmit]);

  useEffect(() => {
    if (!feeDataIsLoading) {
      trackEvent('Edit Handover Overview Shown', {
        daysUntilHandover: daysFromNowToDate(
          handover?.planned_delivery_date ?? handover?.preferred_delivery_date
        ),
        deliveryFee: deliveryFeeInEuros,
        changeFeedisplayAmount: changeFee,
      });
    }
  }, [feeDataIsLoading, handover, deliveryFeeInEuros, changeFee]);

  return (
    <div className="mt-8">
      <h6 className="global-t6-semibold">
        {content?.overview?.address?.title}
      </h6>
      <TextBlock text={formattedAddress} className="mt-6 flex flex-row" />
      <TextBlock text={String(formData.address_suffix)} />
      <TextBlock text={appointmentDisplay} className="mt-6" />

      <Divider className="my-6" />
      <h6 className="global-t6-semibold">
        {content?.overview?.contact?.title}
      </h6>
      <TextBlock
        className="mt-6"
        text={`${String(formData.firstname)} ${String(formData.lastname)}`}
      />
      <TextBlock text={String(formData.phone_number)} />
      <Divider className="my-6" />
      <h6 className="global-t6-semibold">{content?.overview?.costs?.title}</h6>
      <div className="mt-6 flex flex-row justify-between">
        <TextBlock text={content?.overview?.costs?.handover_fee} />
        <TextBlock
          text={formatNumber(deliveryFeeInEuros, {
            currency: region === CountryCode.US ? 'USD' : 'EUR',
            style: 'currency',
          })}
        />
      </div>

      <>
        <Skeleton loading={feeDataIsLoading} width="100%">
          <div className="mt-1 flex flex-row justify-between">
            <TextBlock
              text={content?.overview?.costs?.short_term_changes_fee}
            />
            <TextBlock
              text={formatNumber(changeFee, {
                currency: region === CountryCode.US ? 'USD' : 'EUR',
                style: 'currency',
              })}
            />
          </div>
          <div className="mt-1 flex flex-row justify-between">
            <TextBlock text={content?.overview?.costs?.total} />
            <TextBlock
              className="font-bold"
              text={`${formatNumber(changeFee + deliveryFeeInEuros, {
                currency: region === CountryCode.US ? 'USD' : 'EUR',
                style: 'currency',
              })}</strong>`}
            />
          </div>
        </Skeleton>
        <TextBlock
          className="mt-4 text-xs"
          text={content?.overview?.costs?.fees_on_different_invoices_disclaimer}
        />
      </>

      {modalVariant === 'no-fee-swap' || modalVariant === 'fee-swap' ? (
        <>
          <Divider className="my-6" />
          <TextBlock text={content?.overview?.swap_changes_disclaimer} />
        </>
      ) : null}
    </div>
  );
};

export const overviewStep = ({
  deal,
  title,
  cta,
  canSubmit,
  setCanSubmit,
}: {
  deal: Deal;
  title?: string;
  cta?: string;
  setCanSubmit?: (canSubmit: boolean) => void;
  canSubmit?: boolean;
}) => {
  return prepareWizardStep({
    title,
    render: () => <OverviewStep deal={deal} setCanSubmit={setCanSubmit} />,
    isCTADisabled: () => !canSubmit,
    footer: { cta: { action: 'submit', label: cta } },
    fields: [],
  });
};
