import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useTextWithValues, useValue } from '@finn/b2c-cp/features-data';
import { formatValue } from '@finn/b2c-cp/features-data/hooks/useValue/helpers';
import { FormItem, FormMessage, useFormField } from '@finn/b2c-cp/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { cn, trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { ControllerProps } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SelectField as SelectFieldType } from '../types';

type SelectFieldProps = SelectFieldType &
  Parameters<ControllerProps['render']>['0']['field'];

const SelectItemWithValue = ({ children, value }) => {
  // we inject some data if required
  const [formattedLabel, status] = useTextWithValues(children);

  return (
    <SelectItem value={value}>
      <Skeleton width="120px" loading={status === 'loading'}>
        {status !== 'loading' ? formattedLabel || value : null}
      </Skeleton>
    </SelectItem>
  );
};

export const SelectField = forwardRef(
  ({ ...field }: SelectFieldProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { error } = useFormField();

    // reading options form a dynamic resource that is expected to yield a string array
    const [dynamicValues] = useValue<string[]>(field.valuesPath);
    const intl = useIntl();
    const { region, locale } = useCurrentLocale();
    const valueFormatConfig = useMemo(
      () => ({ intl, region, locale }),
      [intl, region, locale]
    );

    // for any dynamic value, format it to a label (e.g. with 'labelsFormat' being 'date') and store it in a map
    const dynamicLabels = dynamicValues?.reduce((res, cur) => {
      res[cur] = formatValue(cur, {
        ...valueFormatConfig,
        type: field.labelsFormat,
      });

      return res;
    }, {});

    const handleChange = useCallback(
      (value) => {
        field?.onChange(value);
        trackEvent('Select Option Clicked', { value });
      },
      [field]
    );

    // if there are values from dynamic source, do only consider to use them
    // otherwise use the non-dynamic values
    // in any case, fallback to an empty array if nothing is defined
    const values =
      (dynamicValues?.length ? dynamicValues : null) ?? field?.values ?? [];

    // combine dynamic labels with the static labels map so that dynamic values (via 'valuesPath') can also leverage static labels
    const labels = {
      ...(dynamicLabels ?? {}),
      ...(field?.labelsMap ?? {}),
    };

    return (
      <FormItem className={field.className}>
        <Select
          value={field.value || field.defaultValue}
          onValueChange={handleChange}
          defaultValue={field.value || undefined}
        >
          <SelectTrigger
            className={cn(
              field.className,
              'shadow-pearl w-full border-none bg-white',
              {
                'border-red text-red border-2': Boolean(error),
              }
            )}
            ref={ref}
          >
            <SelectValue placeholder={field.placeholder || ''} />
          </SelectTrigger>
          <SelectContent>
            {values.map((value) => (
              <SelectItemWithValue key={value} value={value}>
                {labels?.[value]}
              </SelectItemWithValue>
            ))}
          </SelectContent>
        </Select>
        <FormMessage className="text-red" />
      </FormItem>
    );
  }
);
