import { EditReturnModalContainer } from '@finn/b2c-cp/features/EditReturn/EditReturnModal';
import { Modal } from '@finn/platform-modules';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { memo, useEffect } from 'react';

import ModalContainer from '../deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '../deprecated/modules/modals/store';
import { BuyCarModalContainer } from '../features/BuyCarModal';
import { ConfirmDeleteAccountModalContainer } from '../features/ConfirmDeleteAccountModal';
import { EditHandoverModalContainer } from '../features/EditHandover/EditHandoverModal';
import { ReportMileageModalContainer } from '../features/reportMileage/ReportMileageModal';
import { SwapProlongationModalContainer } from '../features/SwapProlongationModal';
import { trackFeatureAction } from '../features-data/hooks/useFeatureAction';
import { ModalBuilder } from './ModalBuilder';

type ModalsRegistryProps = {
  modals?: Modal[];
};

const renderModal = (modal: Modal) => (open: boolean, onClose: () => void) => (
  <ModalBuilder
    {...modal.metadata}
    open={open}
    onClose={onClose}
    name={modal.slug}
  />
);

export const ModalsRegistry = memo(({ modals }: ModalsRegistryProps) => {
  const { openModal } = useModalStore();
  const route = useRouter();

  useEffect(() => {
    const parsedURLQuery = queryString.parse(window.location.search);
    if (parsedURLQuery.modalName) {
      openModal(parsedURLQuery.modalName as string);
      trackFeatureAction({
        action: 'open_modal',
        modalName: parsedURLQuery.modalName as string,
      });
    }
    // we added route.asPath dep to ensure that modal opens after internal navigation too
  }, [openModal, route.asPath]);

  return (
    <>
      {modals?.map((modal) => (
        <ModalContainer
          key={modal?.slug}
          name={modal?.slug}
          renderModal={renderModal(modal)}
        />
      ))}
      <EditHandoverModalContainer />
      <SwapProlongationModalContainer />
      <BuyCarModalContainer />
      <ConfirmDeleteAccountModalContainer />
      <EditReturnModalContainer />
      <ReportMileageModalContainer />
    </>
  );
});
