import { Portal } from '@finn/design-system/atoms/portal';
import React, { memo, useEffect, useState } from 'react';

import { useModalStore } from '../../store';

const UNMOUNT_DELAY = 250;

export type ModalProps = {
  open: boolean;
  onClose(): void;
};

type CommonProps = {
  /** One of the names of modals defined in ModalKey */
  name: string;

  /** If true, renders a modal in a portal */
  portal?: boolean;

  /** A delay in ms after which a modal is unmounted */
  unmountDelay?: number;
};

type WithModalComponentProps = CommonProps & {
  /** A component type for rendering Modal */
  ModalComponent: React.ComponentType<ModalProps>;
};

type WithRenderModalProps = CommonProps & {
  /** A function which returns an instance of a modal */
  renderModal(
    open: boolean,
    onClose: () => void
  ): React.ReactElement<ModalProps>;
};

type Props = WithModalComponentProps | WithRenderModalProps;

/**
 * ModalContainer controls rendering state of a modal component.
 * It mounts a modal when it's open, unmounts when it's closed.
 *
 * It allows to not run hooks when the modal is closed and flush the state on close.
 * Also, it allows to bypass rendering a modal in VDOM when it's not displayed.
 */
const ModalContainer: React.FC<Props> = ({
  name,
  unmountDelay = UNMOUNT_DELAY,
  ...rest
}: Props) => {
  const { getIsModalOpen, closeModal } = useModalStore();
  const isOpen = getIsModalOpen(name);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (isOpen && !isMounted) {
      setIsMounted(true);
    } else if (isMounted && !isOpen) {
      setTimeout(() => setIsMounted(false), unmountDelay);
    }
  }, [isOpen, isMounted, unmountDelay]);

  // Close modal when ModalContainer is unmounted
  useEffect(() => {
    return () => {
      if (getIsModalOpen(name)) {
        closeModal();
      }
    };
  }, [closeModal, getIsModalOpen, name]);

  if (!isOpen && !isMounted) {
    return null;
  }

  let modalElement: React.ReactElement<ModalProps>;

  if ('renderModal' in rest) {
    modalElement = rest.renderModal(isOpen && isMounted, closeModal);
  } else {
    modalElement = (
      <rest.ModalComponent open={isOpen && isMounted} onClose={closeModal} />
    );
  }

  return <Portal>{modalElement}</Portal>;
};

export default memo(ModalContainer);
