import { getPlatformFeatures } from '@finn/platform-modules';
import { cosmicFactory, PlatformCosmicModule } from '@finn/ui-cosmic';
import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';

import { getSelfServiceFeatures } from '../features';

const factory = cosmicFactory({
  ...getPlatformFeatures(),
  ...getSelfServiceFeatures(),
});

export const CosmicModule = factory.make();

type PlatformComponentProps = {
  module: PlatformCosmicModule;
};

export const PlatformComponent = ({ module }: PlatformComponentProps) => {
  // if module marked as devonly, we show it only
  // when cosmic helper enabled
  if (module?.devonly || module?.metadata?.devonly) {
    const isDevMode =
      Cookies.get(CookieKeys.DEV_PASS) ===
      Cookies.get(CookieKeys.COSMIC_HELPER);
    if (!isDevMode) {
      return null;
    }
  }

  if (module === null) {
    return null;
  }

  if (!module?.metadata?.modules) {
    return <CosmicModule data={module} />;
  }

  return (
    <div
      className="grid w-full"
      style={{ gridRowGap: module?.metadata?.gap_between_modules }}
    >
      {module.metadata.modules.map((moduleItem) => (
        <PlatformComponent key={moduleItem.id} module={moduleItem} />
      ))}
    </div>
  );
};
