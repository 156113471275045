import { Button } from '@finn/design-system/atoms/button';
import { ArrowBackIos } from '@finn/design-system/icons/arrow-back-ios';
import { isMobileApp } from '@finn/ui-utils';
import Link from 'next/link';

// TODO remove hardcode
export const BackButton = ({
  href = '/mycars',
  label,
}: {
  href?: string;
  label?: string;
}) => {
  if (isMobileApp()) {
    return null;
  }

  return (
    <Link href={href} legacyBehavior>
      <Button
        variant="link"
        href={href}
        size="md"
        className="group -mb-16 !gap-0 !no-underline md:-mb-14"
        contentClassName="gap-2"
      >
        <ArrowBackIos className="group-hover:fill-iron !size-4" />
        {label ?? 'Zurück'}
      </Button>
    </Link>
  );
};
