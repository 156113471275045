import { PreReturnAppraisal } from '@finn/b2c-cp/pages/api/bff/deals/[id]/pre-return-appraisal';
import { useRemoteData } from '@finn/platform-modules';
import queryString from 'query-string';

const preReturnAppraisalEndpoint = (id: string, query: string) =>
  `portal/api/bff/deals/${id}/pre-return-appraisal?${query}`;

export const usePreReturnAppraisal = (
  id: string,
  opts?: { selected_date: string }
) => {
  const query = queryString.stringify(
    {
      selected_date: opts?.selected_date,
    },
    { skipNull: true }
  );

  return useRemoteData<PreReturnAppraisal>(
    id && id !== 'no-deal' ? `pre-return-appraisal/${id}/${query}` : null,
    () => preReturnAppraisalEndpoint(id, query)
  );
};
