import { ReactNode, Ref } from 'react';
import { z } from 'zod';

import { FeatureForm, FeatureFormApi } from '../../FeatureForm';
import { Field } from '../../FeatureForm/types';

const dummy = () => void 0;

type FormWrapperProps<T extends z.ZodRawShape> = {
  children: ReactNode;
  fields: Field[];
  customSchema?: z.ZodEffects<z.ZodObject<T>>;
  className?: string;
  formApi?: Ref<FeatureFormApi>;
  onSubmit?: (data: { [key: string]: string | object }) => void;
};

export const FormWrapper = <T extends z.ZodRawShape>({
  children,
  fields,
  customSchema,
  className,
  formApi,
  onSubmit = dummy,
}: FormWrapperProps<T>) => (
  <FeatureForm
    ref={formApi}
    className={className}
    fields={fields}
    onSubmit={onSubmit}
    customSchema={customSchema}
  >
    {children}
  </FeatureForm>
);
