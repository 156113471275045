import { FLEET_VIEW_DE } from '@finn/ua-constants';
import {
  GenericVehicleDetails,
  VehicleOfferType,
  VehiclesResponse,
} from '@finn/ua-vehicle';
import { generateLocalizationHeaders } from '@finn/ui-utils';
import config from '@finn/ui-utils/lib/config';
import * as Sentry from '@sentry/node';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import omit from 'lodash/omit';
import { stringify } from 'query-string';

export type Filters = {
  sort?: string;
  sort_co2?: string;
  brands?: Array<string>;
  cartypes?: Array<string>;
  models?: Array<string>;
  gearshifts?: Array<string>;
  terms?: Array<string>;
  fuels?: Array<string>;
  colors?: Array<string>;
  view?: Array<string> | string;
  'coming-soon'?: Array<string> | string;
  has_hitch?: boolean;
  is_young_driver?: boolean;
  is_for_business?: boolean;
  min_price?: string;
  max_price?: string;
  available_from?: string;
  available_to?: string;
  offer_type?: string;
  sort_by_popularity?: boolean;
  zipcode?: string;
};

const stringifyFilter = (filterValues: Filters) => {
  return stringify(filterValues, {
    arrayFormat: 'none',
    skipNull: true,
  });
};

type GetVehiclesParams = {
  filter?: Filters;
  offset?: number;
  limit?: number;
  view?: string;
  offerType?: VehicleOfferType;
  locale: string;
};

type GetVehiclesRequestUrl = (params: {
  filter?: Filters;
  offset?: number;
  limit?: number;
  view?: string;
  offerType?: VehicleOfferType;
}) => string;

export const instance = axios.create({
  baseURL: config.PRODUCT_API_URL,
  transformRequest: (data, headers) => {
    if (process.env.NODE_ENV !== 'development') {
      const requestId =
        new Date().getTime().toString(36) +
        Math.random().toString(36).substr(2, 9);
      Sentry.withScope((scope) => {
        scope.setTag('X-Finn-Request-Id', requestId);
      });
      headers['X-Finn-Request-Id'] = requestId;
    }

    return data;
  },
});

axiosRetry(instance, { retries: 3, shouldResetTimeout: true });

// we need primitive type for swr
export const getVehiclesRequestUrl: GetVehiclesRequestUrl = ({
  filter,
  offset = 0,
  limit = 9,
  view,
  offerType = VehicleOfferType.SUBSCRIPTION,
}) => {
  const params = filter ? stringifyFilter(filter) : '';
  const extraParams = stringify(
    {
      limit,
      offset,
      view,
      offer_type: offerType,
    },
    {
      arrayFormat: 'none',
      skipNull: true,
    }
  );

  return `/cars?${extraParams}&${params}`;
};

export const getVehiclesFromRequestUrl = async (
  url: string,
  locale: string
) => {
  const response = await instance.get(url, {
    headers: generateLocalizationHeaders(locale),
  });

  const plpRelevantData = {
    offset: response.data?.offset,
    total_results: response.data?.total_results,
    results: (response.data?.results || []).map(
      (vehicle: GenericVehicleDetails) => {
        return omit(vehicle, [
          'brand.thumbnails',
          'config',
          'description',
          'efficiency_image',
          'equipment',
          'pictures',
        ]);
      }
    ),
  };

  return plpRelevantData;
};

export async function getVehicles({
  filter,
  offset = 0,
  limit = 9,
  view,
  offerType = VehicleOfferType.SUBSCRIPTION,
  locale,
}: GetVehiclesParams): Promise<VehiclesResponse> {
  if (!view && locale === 'de-DE') {
    view = FLEET_VIEW_DE;
  }
  const vehiclesRequestUrl = getVehiclesRequestUrl({
    filter,
    offset,
    limit,
    view,
    offerType,
  });

  return await getVehiclesFromRequestUrl(vehiclesRequestUrl, locale);
}
