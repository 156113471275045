import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { LoyaltyRewardOption } from '@finn/platform-modules';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn, traceabilityHeadersBrowser } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback } from 'react';

import useFormattedNumber from '../hooks/useFormattedNumber';
import { RedemptionModalData } from './RedemptionModal';

type Props = {
  pointsLabel: string;
  totalCoins?: number;
  benefit?: LoyaltyRewardOption;
  openRedemptionModal: (redemptionData: RedemptionModalData) => void;
};

export const RedeemableBenefitChip = ({
  pointsLabel,
  totalCoins,
  benefit,
  openRedemptionModal,
}: Props) => {
  const hasEnoughCoins = totalCoins >= benefit?.value;
  const formattedValue = useFormattedNumber(benefit?.value);
  const onRedemptionConfirm = useCallback(async () => {
    await axios.post(
      '/api/redeemLoyaltyBenefits',
      {
        reward_action_name: benefit?.name,
      },
      {
        headers: traceabilityHeadersBrowser(),
        validateStatus: (status) => status === 200,
      }
    );
    interactionTrackingEvent(
      TrackingEventName.REDEEMABLE_LOYALTY_ITEM_REDEEMED,
      {
        item: benefit?.name,
      }
    );
  }, [benefit?.name]);

  const onChipClick = useCallback(() => {
    interactionTrackingEvent(
      TrackingEventName.REDEEMABLE_LOYALTY_ITEM_CLICKED,
      {
        item: benefit?.name,
      }
    );
    if (!hasEnoughCoins) {
      return;
    }
    openRedemptionModal({
      totalCoins,
      coinsNeeded: benefit?.value,
      benefitTitle: benefit?.description,
      onModalConfirm: onRedemptionConfirm,
      onModalClose: () => {
        interactionTrackingEvent(
          TrackingEventName.REDEEMABLE_LOYALTY_ITEM_CLOSED,
          {
            item: benefit?.name,
          }
        );
      },
    });
  }, [
    benefit?.description,
    benefit?.name,
    benefit?.value,
    hasEnoughCoins,
    onRedemptionConfirm,
    openRedemptionModal,
    totalCoins,
  ]);

  return (
    <div
      className={cn('flex items-center', {
        'cursor-pointer': hasEnoughCoins,
        'pointer-events-none opacity-30': !hasEnoughCoins,
      })}
      onClick={onChipClick}
    >
      <div className="bg-pewter flex min-h-[76px] min-w-[76px] items-center justify-center overflow-hidden rounded-bl rounded-tl">
        <img
          className="h-[76px] min-h-[76px] w-[76px] min-w-[76px] rounded-bl"
          src={benefit?.image?.url}
          alt={benefit?.description}
        />
      </div>
      <div className="bg-snow flex h-full min-h-[76px] max-w-[calc(100%-76px)] flex-1 items-center gap-4 rounded-br rounded-tr px-4">
        <div className="flex flex-col gap-4">
          <span className="body-14-semibold">{benefit?.description}</span>
          <span className="body-12-semibold">
            {`${formattedValue} ${pointsLabel}`}
          </span>
        </div>
        <div className="ml-auto leading-none">
          {hasEnoughCoins ? <KeyboardArrowRight /> : null}
        </div>
      </div>
    </div>
  );
};
