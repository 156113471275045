import { AppointmentFormFields } from '@finn/b2c-cp/features/AppointmentFormFields';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { useReturnMetadata } from '@finn/b2c-cp/features-data';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { Deal, useCosmicModule } from '@finn/platform-modules';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export const AppointmentStep = ({ deal }: { deal: Deal }) => {
  const postcode = useWatch({ name: 'postcode' });
  const returnType = useWatch({ name: 'return_type' });
  const { data, status } = useReturnMetadata(deal?.id, {
    postcode,
    returnType,
  });
  const { data: returnData } = useReturn(deal?.id);
  const content = useCosmicModule('advanced-edit-return');

  const availabilities = useMemo(() => {
    return data?.availabilities?.map((item) => ({
      date: item.date,
      slots: item.available_slots.map((slot) => ({
        label: slot.formatted_time,
        value: slot.value,
      })),
    }));
  }, [data]);

  return (
    <AppointmentFormFields
      currentAppointmentDate={
        returnData?.planned_delivery_date ?? returnData?.preferred_delivery_date
      }
      currentAppointmentSlot={returnData?.slot}
      availabilities={availabilities}
      isLoading={status === 'loading'}
      content={content}
    />
  );
};

export const appointmentStep = ({
  deal,
  title,
  footerCta,
}: {
  deal: Deal;
  title?: string;
  footerCta?: string;
}) => {
  return prepareWizardStep({
    title,
    render: () => <AppointmentStep deal={deal} />,
    footer: { cta: { action: 'next-step', label: footerCta } },
    fields: [
      {
        name: 'appointment_preference',
        type: 'calendar',
      },
      {
        name: 'slot',
        type: 'radio',
      },
    ],
  });
};
