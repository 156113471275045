import { Slot } from '@finn/b2c-cp/bff/core-api/types';
import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

dayjs.extend(timezone);

function findSlotsOfSelectedDate(
  availabilities: FormAvailability[],
  selectedDate: Date
) {
  if (!selectedDate) {
    return null;
  }

  const availability = availabilities?.find((a) =>
    dayjs
      .tz(a.date, 'Europe/Berlin')
      .isSame(dayjs.tz(selectedDate, 'Europe/Berlin'), 'day')
  );

  return availability?.slots || null;
}

type FormAvailability = {
  date: string;
  slots: { label: string; value: string }[];
};

export const AppointmentFormFields = ({
  currentAppointmentDate,
  currentAppointmentSlot,
  availabilities,
  isLoading,
  content,
}: {
  currentAppointmentDate: string;
  currentAppointmentSlot: Slot;
  availabilities: FormAvailability[];
  isLoading: boolean;
  content: { appointment: { slotTitle: string } };
}) => {
  const { setValue } = useFormContext();
  const selectedDate = useWatch({ name: 'appointment_preference' });
  const selectedSlot = useWatch({ name: 'slot' });

  const slotsOfSelectedDate = useMemo(
    () => findSlotsOfSelectedDate(availabilities, selectedDate),
    [availabilities, selectedDate]
  );

  const availableDates = useMemo(
    () => availabilities?.map((a) => a.date),
    [availabilities]
  );

  const firstCurrentDateSlot = slotsOfSelectedDate?.[0]?.value;

  // which availability represents the currently booked appointment?
  const availabilityOfCurrentAppointment = availabilities?.find((a) =>
    dayjs
      .tz(a.date, 'Europe/Berlin')
      .isSame(dayjs(currentAppointmentDate), 'day')
  );

  // date of availability of current appointment (fallback: date of 1st availability)
  const defaultDate =
    availabilityOfCurrentAppointment?.date ?? availabilities?.[0]?.date;

  const canSetInitialSlotValue = Boolean(
    selectedDate &&
      // means: the current slot is not one of the slots of the selected date
      !slotsOfSelectedDate?.find((slot) => slot.value === selectedSlot)
  );
  useEffect(() => {
    if (canSetInitialSlotValue) {
      const newlySelectedSlot =
        slotsOfSelectedDate?.find(
          (s) => s.value === currentAppointmentSlot?.value
        )?.value ?? firstCurrentDateSlot;

      setValue('slot', newlySelectedSlot);
    }
  }, [
    canSetInitialSlotValue,
    currentAppointmentSlot?.value,
    firstCurrentDateSlot,
    setValue,
    slotsOfSelectedDate,
  ]);

  const slotTitle = content?.appointment?.slotTitle || 'Terminauswahl';

  return (
    <div className="mt-8">
      <Skeleton loading={isLoading} width="100%">
        <FeatureFormField
          type="calendar"
          name="appointment_preference"
          availableDates={availableDates}
          defaultValue={defaultDate}
        />
        <div className="mt-8">
          <h6 className="global-t6-semibold">{slotTitle}</h6>
          <div className="mt-4">
            <FeatureFormField
              type="radio"
              name="slot"
              defaultValue={firstCurrentDateSlot}
              radioItems={slotsOfSelectedDate}
              variant="circle"
            />
          </div>
        </div>
      </Skeleton>
    </div>
  );
};
