import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { PasswordStrengthCheck } from '@finn/ua-auth';
import { useFormContext } from 'react-hook-form';

import { Fields } from '../Fields';

const ChangePasswordForm = ({
  fields,
  serverError,
  serverErrorMessage,
}: {
  fields: Field[];
  serverError?: string;
  serverErrorMessage?: string;
}) => {
  const methods = useFormContext();

  return (
    <>
      <div className="grid gap-4">
        <Fields fields={fields} />
      </div>

      <div className="my-8">
        <PasswordStrengthCheck value={methods.getValues().newPassword} />
      </div>

      {serverError ? (
        <div className="mb-8 flex items-center gap-2">
          <InfoOutlined className="fill-red" />
          <span className="text-red body-14-medium">{serverErrorMessage}</span>
        </div>
      ) : null}
    </>
  );
};

export const changePasswordForm = ({
  fields,
  title,
  serverError,
  serverErrorMessage,
  footer_cta,
}: {
  fields: Field[];
  title: string;
  serverError?: string;
  serverErrorMessage?: string;
  footer_cta: string;
}) => {
  return prepareWizardStep({
    fields: [],
    title,
    render: () => (
      <ChangePasswordForm
        fields={fields}
        serverError={serverError}
        serverErrorMessage={serverErrorMessage}
      />
    ),
    footer: { cta: { action: 'submit', label: footer_cta } },
    isCTADisabled: (errors) =>
      Boolean(errors.currentPassword) ||
      Boolean(errors.newPassword) ||
      Boolean(errors.confirmedNewPassword),
  });
};
