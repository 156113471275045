import { Switch } from '@finn/design-system/atoms/switch';
import useHotkeys from '@reecelucas/react-use-hotkeys';

type Props = {
  text: string;
  hotkey: string;
  helperText?: string;
  onClick?: () => void;
  isActive?: boolean;
};

const DevModalSwitch = ({
  text,
  hotkey,
  helperText,
  onClick = () => null,
  isActive,
}: Props) => {
  useHotkeys(hotkey, onClick);

  return (
    <div
      className="hover:bg-cotton flex cursor-pointer items-center justify-between md:p-4"
      onClick={onClick}
    >
      <div className="flex flex-col gap-1">
        <p className="body-14-regular">{text}</p>
        {(helperText || hotkey) && (
          <p className="body-14-regular text-steel pr-8">
            {helperText || hotkey}
          </p>
        )}
      </div>
      <Switch checked={Boolean(isActive)} value="checkedA" color="default" />
    </div>
  );
};
export default DevModalSwitch;
