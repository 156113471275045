import { isProlongation } from '@finn/b2c-cp/bff/bff-helpers';
import { daysFromNowToDate } from '@finn/b2c-cp/features-data/helpers';
import { usePendingHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { Deal } from '@finn/platform-modules';
import { useIntl } from 'react-intl';

const getLabel = (deal: Deal, deliveryDaysLeft: number) => {
  if (deal?.state === 'CREATED') {
    if (
      deliveryDaysLeft > 1 ||
      (deliveryDaysLeft === 1 && isProlongation(deal))
    ) {
      return {
        id: isProlongation(deal)
          ? 'mycars.labels.prolongationDaysRemaining'
          : 'mycars.labels.deliveryDaysRemaining',
        value: { days: deliveryDaysLeft },
      };
    } else if (deliveryDaysLeft === 1 && !isProlongation(deal)) {
      return {
        id: 'mycars.labels.deliveryIsTomorrow',
      };
    } else if (deliveryDaysLeft === 0 && !isProlongation(deal)) {
      return {
        id: 'mycars.labels.deliveryIsToday',
      };
    }
  }

  return null;
};

export const useHandoverDeliveryDaysLabel = (deal: Deal) => {
  const { formatMessage } = useIntl();

  const { data: handover, status } = usePendingHandover(deal);

  const deliveryDate =
    handover?.planned_delivery_date ?? handover?.preferred_delivery_date;

  const daysLeft = daysFromNowToDate(deliveryDate);
  const label = getLabel(deal, daysLeft);

  return {
    label: label ? formatMessage(label, label.value) : '',
    isLoading: status === 'loading',
  };
};
