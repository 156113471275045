import { If } from '@finn/b2c-cp/features-components/IfRender';
import { ReactNode } from 'react';

import { Link } from '../../Link';

type ContentProps = {
  children?: ReactNode;
  supportLink?: {
    label?: string;
    url?: string;
  };
};

export const Content = ({ children, supportLink }: ContentProps) =>
  children ? (
    <div className="flex flex-1 flex-col gap-4">
      {children}
      <If condition={Boolean(supportLink?.label)}>
        <Link href={supportLink?.url}>
          <span className="link-14">{supportLink?.label}</span>
        </Link>
      </If>
    </div>
  ) : null;
