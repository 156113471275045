import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useValue } from '@finn/b2c-cp/features-data';
import {
  DataModuleCell,
  DataModuleRow,
} from '@finn/design-system/modules/DataModule';
import { ValueType } from '@finn/platform-modules';

type ValueItemProps = {
  label: string;
  value: string | number;
  valueType?: ValueType;
};

export const ValueItem = ({ label, value, valueType }: ValueItemProps) => {
  const [formattedValue, valueStatus] = useValue(String(value), {
    type: valueType,
  });

  return (
    <DataModuleRow>
      <DataModuleCell>{label}</DataModuleCell>

      <DataModuleCell>
        <Skeleton
          loading={valueStatus === 'loading'}
          placeholder={<div className="h-7 w-28" />}
        >
          {formattedValue}
        </Skeleton>
      </DataModuleCell>
    </DataModuleRow>
  );
};
