import { isProlongation } from '@finn/b2c-cp/bff/bff-helpers';
import { OrderStatusStep } from '@finn/b2c-cp/bff/core-api/types';
import { DSRowWithFallback } from '@finn/b2c-cp/features-components/DataRow';
import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { Button } from '@finn/design-system/atoms/button';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import {
  ProgressModule,
  ProgressModuleBody,
  ProgressModuleHeader,
  ProgressModuleRow,
  ProgressModuleRowSubtitle,
  ProgressModuleRowTitle,
  ProgressModuleTitle,
} from '@finn/design-system/modules/ProgressModule';
import {
  Action,
  useDeal,
  ValueType,
  VisibilityConfig,
} from '@finn/platform-modules';
import { parseToHtml, trackEvent } from '@finn/ui-utils';

import {
  daysFromNowToDate,
  useDealId,
  useFeatureAction,
  useHandoverMetadata,
  useOrderStatus,
} from '../../features-data';

type OrderStatusFooterProps = {
  cta?: {
    action: Action;
    label?: string;
    modalName?: string;
    visibilityConfig?: VisibilityConfig;
  };
  secondaryCTA?: {
    action: Action;
    label?: string;
    modalName?: string;
  };
};

export type OrderStatusProps = {
  title?: string;
  steps?: Record<string, { title: string; description: string }>;
  delivery_label?: {
    type: ValueType;
    field: string | string[];
    valueMap: Record<string, string>;
  };
  delivery_info?: {
    title: string;
    rows: {
      label: string;
      field: string | string[];
      type: ValueType;
    }[];
  };
  footer?: OrderStatusFooterProps;
};

const ORDER_STEPS_NORMAL: OrderStatusStep[] = [
  'customer_verified',
  'car_arrived_at_compound',
  'car_ready_for_delivery',
  'car_in_delivery',
  'delivery_completed',
];

const ORDER_STEPS_EARLY_BOOKING: OrderStatusStep[] = [
  'customer_verified',
  'delivery_confirmation',
  'car_arrived_at_compound',
  'car_ready_for_delivery',
  'car_in_delivery',
  'delivery_completed',
];

const getStepStatues = (currentIndex: number, stepIndex: number) => {
  if (currentIndex === stepIndex + 1) {
    return 'in-progress';
  }
  if (currentIndex <= stepIndex) {
    return 'completed';
  }

  return 'upcoming';
};

export const OrderStatus = ({
  title,
  delivery_info,
  footer,
  steps,
}: OrderStatusProps) => {
  const dealId = useDealId();
  const { data } = useDeal(dealId);
  const { data: orderStepRaw } = useOrderStatus(dealId);
  const { data: handover } = useHandover(dealId);
  const { data: handoverMetadata } = useHandoverMetadata(dealId);
  const runCTAAction = useFeatureAction({
    ...footer?.cta,
    location: 'order_status',
  });
  const runSecondaryCTAAction = useFeatureAction({
    ...footer?.secondaryCTA,
    location: 'order_status',
  });
  const isMainCTAVisible = handoverMetadata?.availabilities?.length > 0;

  // if no steps or last steps already done -> do not show order status
  // as user already got a car; if deal marked as prolongation -> again does not make sense to show
  const canShowOrderStatus =
    orderStepRaw &&
    orderStepRaw !== 'delivery_completed' &&
    orderStepRaw !== 'order_received' &&
    !isProlongation(data) &&
    daysFromNowToDate(data?.end_date) > 0;

  const deliveryDate =
    data?.actual_handover_date || handover?.planned_delivery_date;

  if (!canShowOrderStatus) {
    return null;
  }

  // for lack of better data point we're treating missing deliveryDate as early booking (low confidence)
  // downside: once the subscription is created and the delivery date is set,
  // the newly added status (delivery_confirmation) will vanish instead of showing as complete
  const isEarlyBooking = !deliveryDate;
  const ORDER_STEPS = isEarlyBooking
    ? ORDER_STEPS_EARLY_BOOKING
    : ORDER_STEPS_NORMAL;

  const activeStepIndex = ORDER_STEPS.indexOf(orderStepRaw);

  return (
    <>
      <ProgressModule>
        <ProgressModuleHeader>
          <ProgressModuleTitle asChild>
            <h4>{parseToHtml(title)}</h4>
          </ProgressModuleTitle>
        </ProgressModuleHeader>
        <ProgressModuleBody>
          {ORDER_STEPS.map((step, index) => (
            <ProgressModuleRow
              className="grid-cols-[max-content,1fr]"
              status={getStepStatues(index, activeStepIndex)}
              key={index}
            >
              <ProgressModuleRowTitle>
                {steps[step]?.title}
              </ProgressModuleRowTitle>
              <ProgressModuleRowSubtitle>
                {parseToHtml(steps[step]?.description)}
              </ProgressModuleRowSubtitle>
            </ProgressModuleRow>
          ))}
        </ProgressModuleBody>
      </ProgressModule>
      {!!deliveryDate && (
        <DataModule className="bg-white">
          <DataModuleHeader>
            <DataModuleTitle asChild>
              <h3>{delivery_info?.title}</h3>
            </DataModuleTitle>
          </DataModuleHeader>
          <DataModuleBody>
            {delivery_info?.rows.map((row) => (
              <DSRowWithFallback
                key={row?.field?.toString()}
                value={row?.field as string}
                type={row?.type}
                label={row?.label}
              />
            ))}
          </DataModuleBody>
          <DataModuleFooter>
            {footer?.cta && isMainCTAVisible ? (
              <Button
                variant="outline"
                className="h-12 w-full md:w-auto"
                onClick={() => {
                  runCTAAction();
                  if (
                    footer?.cta.action === 'open_modal' &&
                    footer?.cta.modalName === 'edit_handover_delivery'
                  ) {
                    trackEvent('Edit Handover Opened', {
                      daysUntilHandover: daysFromNowToDate(
                        handover?.planned_delivery_date ??
                          handover?.preferred_delivery_date
                      ),
                    });
                  }
                }}
              >
                {footer?.cta?.label}
              </Button>
            ) : null}
            {footer?.secondaryCTA ? (
              <Button variant="action" onClick={runSecondaryCTAAction}>
                {footer?.secondaryCTA?.label}
              </Button>
            ) : null}
          </DataModuleFooter>
        </DataModule>
      )}
    </>
  );
};
