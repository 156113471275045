import {
  ModalContainer,
  ModalKey,
  useOpenModal,
} from '@finn/b2c-cp/deprecated/modules/modals';
import { Skeleton } from '@finn/design-system/atoms/skeleton';
import { LoyaltyBenefitsData, LoyaltyInfo } from '@finn/platform-modules';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import {
  HowToCollectPoints,
  InfoModal,
  InfoModalData,
  LoyaltyBenefits,
} from '@finn/ui-modules';
import { cn, identifyEvent, useSession } from '@finn/ui-utils';
import { useCallback, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';

import { useShowInfoModal } from '../hooks/useShowInfoModal';
import { LoyaltyOverview } from './LoyaltyOverview';
import { LoyaltyRedeemableBenefits } from './LoyaltyRedeemableBenefits';
import { RedemptionModal, RedemptionModalData } from './RedemptionModal';

type Props = {
  data?: LoyaltyCosmicMetadata;
  loyaltyInfo: LoyaltyInfo | null;
  loyaltyBenefits: LoyaltyBenefitsData | null;
  isLoadingLoyaltyInfo?: boolean;
};

// old endpoint returns 'Level 1', 'Level 2' etc as loyalty levels, new endpoint returns 'Silver', 'Gold' etc
const LevelToIndex = {
  'Level 1': 0,
  'Level 2': 1,
  'Level 3': 2,
  'Level 4': 3,
  Silver: 0,
  Gold: 1,
  Platinum: 2,
  Black: 3,
};

export const PersonalizedLoyaltySection = ({
  data,
  loyaltyInfo,
  loyaltyBenefits,
  isLoadingLoyaltyInfo,
}: Props) => {
  const { mutate } = useSWRConfig();
  const [infoModalData, setInfoModalData] = useState<InfoModalData>({});
  const [redemptionModalData, setRedemptionModalData] =
    useState<RedemptionModalData>({});
  const openModal = useOpenModal();
  const [session] = useSession();
  const title = data?.rewards_title;

  const openInfoModal = useCallback(
    (infoData: InfoModalData) => {
      setInfoModalData(infoData);
      openModal(ModalKey.INFO_MODAL);
    },
    [openModal]
  );
  const openThankYouModal = useCallback(() => {
    setInfoModalData({
      title: data.redemption_modal_thank_you_title,
      description: data.redemption_modal_thank_you_description,
      closeButtonText: data.redemption_modal_thank_you_close_label,
      onModalClose: () => {
        // revalidate relevant swr query to get the new points after redemption
        mutate(
          (arg: Record<string, string>) => {
            return (arg?.key as string)?.includes?.('loyaltyInfo');
          },
          undefined,
          { revalidate: true }
        );
      },
    });
    openModal(ModalKey.INFO_MODAL);
  }, [data, openModal, mutate]);

  const openRedemptionModal = useCallback(
    (redemptionData: RedemptionModalData) => {
      setRedemptionModalData({
        ...redemptionData,
        cosmicData: data,
        openThankYouModal,
      });
      openModal(ModalKey.REDEMPTION_MODAL);
    },
    [data, openModal, openThankYouModal]
  );

  const levelIndex = LevelToIndex[loyaltyInfo?.level] ?? 0;
  const hasLoyaltyInfo = !!loyaltyInfo?.level;

  useEffect(() => {
    if (!hasLoyaltyInfo) {
      return;
    }
    identifyEvent(session?.user?.hs_contact_id, {
      ...loyaltyInfo,
      loggedIn: !!session,
    });

    interactionTrackingEvent(TrackingEventName.LOYALTY_PAGE_OPENED, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoyaltyInfo]);
  useShowInfoModal({ openInfoModal, data });

  if (session === undefined || isLoadingLoyaltyInfo) {
    return (
      <div className={cn('container', 'mb-24')}>
        <Skeleton className="h-[300px] w-full" />
      </div>
    );
  }

  if (session === null) {
    return (
      <div className={cn('container', 'mb-24')}>
        <h3 className="mobile-t3-semibold md:web-t3-semibold">
          {data?.login_needed_label}
        </h3>
      </div>
    );
  }

  return (
    <>
      <div className="m-0">
        <h2 className="mobile-t2-semibold md:web-t2-semibold mb-6 md:mb-8">
          {title}
        </h2>
        <LoyaltyOverview
          data={data}
          loyaltyInfo={loyaltyInfo}
          levelIndex={levelIndex}
        />
        <LoyaltyBenefits
          data={data}
          levelIndex={levelIndex}
          openInfoModal={openInfoModal}
          className="md:grid-cols-2"
        />
        <LoyaltyRedeemableBenefits
          data={data}
          loyaltyBenefits={loyaltyBenefits}
          loyaltyInfo={loyaltyInfo}
          openRedemptionModal={openRedemptionModal}
        />
        <HowToCollectPoints
          className="md:grid-cols-2"
          data={data}
          openInfoModal={openInfoModal}
        />
      </div>
      <ModalContainer
        modalKey={ModalKey.INFO_MODAL}
        ModalComponent={InfoModal}
        infoModalData={infoModalData}
      />
      <ModalContainer
        modalKey={ModalKey.REDEMPTION_MODAL}
        ModalComponent={RedemptionModal}
        redemptionModalData={redemptionModalData}
      />
    </>
  );
};
