import { UpdateHandoverData } from '@finn/b2c-cp/bff/core-api/types';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';

const makeRequest = async (
  requestUrl: string | undefined,
  method: Method,
  headers?: Record<string, string> | null,
  payload?: Record<string, unknown>
) => {
  const reqConfig: AxiosRequestConfig = {
    method,
    url: requestUrl,
  };
  if (payload) {
    reqConfig.data = payload;
  }
  // If we dont provide any headers/cookie
  // to auth the user, we send the credentials
  if (headers) {
    reqConfig.headers = headers;
  } else {
    reqConfig.withCredentials = true;
  }
  try {
    const { data, status } = await axios(reqConfig);

    return { data, status };
  } catch (err: unknown) {
    const axiosError = err as AxiosError;
    const { response } = axiosError;

    if (response) {
      const { status, data } = response;

      return { status, data };
    }

    return {
      status: 500,
      data: {},
    };
  }
};

export const reportDamage = async (
  subscriptionId: string,
  values: { [key: string]: string }
) => {
  const requestUrl = `${window.origin}/portal/api/bff/deals/${subscriptionId}/report-damage`;

  return makeRequest(requestUrl, 'POST', null, values);
};

export const getStripeEditPaymentSession = async (
  dealId: string,
  successUrl: string,
  cancelUrl: string
) => {
  const requestUrl = `${window.origin}/portal/api/bff/payment-methods/${dealId}`;

  return makeRequest(requestUrl, 'POST', null, {
    success_url: successUrl,
    cancel_url: cancelUrl,
  });
};

export const updateProfile = async (payload: Record<string, unknown>) => {
  const requestUrl = `${window.origin}/api/checkout/v2/contact`;

  return makeRequest(requestUrl, 'PATCH', null, payload);
};

export const saveHandoverData = async (
  subscriptionId: string,
  data: UpdateHandoverData
) => {
  const requestUrl = `${window.origin}/portal/api/bff/deals/${subscriptionId}/handover`;

  return await makeRequest(requestUrl, 'PUT', null, data);
};

export const createAccountDeletionRequest = async (): Promise<{
  status: number;
  data: boolean;
}> => {
  const requestUrl = `${window.origin}/portal/api/bff/deletion-request`;

  return await makeRequest(requestUrl, 'POST', null, {});
};
