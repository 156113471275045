import { reportDamage } from '@finn/b2c-cp/deprecated/self-service-api';
import { showToast } from '@finn/design-system/atoms/toast';
import { useDeal } from '@finn/platform-modules';

import { useDealId } from '../../MetadataProvider';
import { useFeatureAction } from '../useFeatureAction';
import { prepareData } from './helpers';

type UseFormSubmit = {
  success_toast?: string;
  error_toast?: string;
  success_action?: Parameters<typeof useFeatureAction>[0];
  error_action?: Parameters<typeof useFeatureAction>[0];
};

export const useReportDamageSubmit = ({
  success_toast,
  error_toast,
  success_action,
  error_action,
}: UseFormSubmit) => {
  const runSuccessAction = useFeatureAction(success_action);
  const runErrorAction = useFeatureAction(error_action);
  const dealId = useDealId();
  const { data: subscription } = useDeal(dealId);

  const handleSubmit = async (values) => {
    values.maintenanceAddress = values.sameAddress
      ? 'sameAdress'
      : 'anotherAddress';
    if (values.sameAddress) {
      delete values.street;
      delete values.housenumber;
      delete values.zip;
      delete values.city;
    }

    // If customers choose the current day as the damage occurrence date, the damageOccurrenceDate is set as the DateTimeStamp of the selection.
    // This causes 403 errors in the called endpoint because date is in the past, as quick fix we set time here to 23:59.
    // sets the time to 11:59 in UTC zone
    const modifiedDamageReportDate = new Date();
    modifiedDamageReportDate.setUTCHours(23, 59);

    const requestBody = prepareData(subscription, values);

    const { status } = await reportDamage(dealId, requestBody);
    showToast({
      description: status === 201 ? success_toast : error_toast,
      status: status === 201 ? 'success' : 'error',
    });
    if (status === 201) {
      runSuccessAction();
    } else {
      runErrorAction();
    }

    return status;
  };

  return { handleSubmit };
};
