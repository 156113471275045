import { isRententionWindowOpen } from '@finn/b2c-cp/bff/bff-helpers';
import { formatAddress } from '@finn/b2c-cp/services/format-address';
import { ValueType } from '@finn/platform-modules';
import {
  CountryCode,
  getMinMaxDates,
  isDateValid,
  isNumberValid,
} from '@finn/ui-utils';
import { get } from 'lodash';
import { IntlShape } from 'react-intl';

import { daysFromNowToDate } from '../../helpers';
import { useDataForFeature } from './useDataForFeature';

type FormatValue = {
  label?: string;
  type?: ValueType;
  valueMap?: { [key: string]: string };
  region?: CountryCode;
  locale?: string;
  intl?: IntlShape;
};

const replaceMatchesWithFormattedValue = ({
  value,
  matches,
  formatFunction,
}: {
  value: string | number;
  matches?: RegExpMatchArray;
  formatFunction: (value: string) => string;
}) => {
  // in case if value defined with some contex, we want to keep it,
  // for example it can be "extra +{59}" - we want to format just 59
  // so we match and extract it
  return matches.reduce((acc, match) => {
    const rawValue = match.replace('{', '').replace('}', '');

    return acc.replace(match, formatFunction(rawValue));
  }, String(value));
};

export const formatValue = (
  value: string | number | boolean | undefined,
  { type, label, valueMap, region, intl }: FormatValue
) => {
  const { formatDate, formatNumber, formatMessage } = intl;
  if (value === undefined || !type) {
    return label || value;
  }
  const message = label || value;
  const pattern = /\{.*?\}/g;

  switch (type) {
    case 'days': {
      const valueMatches = String(value)?.match?.(pattern);
      if (valueMatches?.length) {
        return replaceMatchesWithFormattedValue({
          value: String(value),
          matches: valueMatches,
          formatFunction: (valueToFormat) =>
            String(Math.floor(daysFromNowToDate(valueToFormat))),
        });
      }

      return daysFromNowToDate(String(value));
    }
    case 'date':
      // in case if value defined with some contex, we want to keep it,
      const valueMatches = String(value)?.match?.(pattern);
      if (valueMatches?.length) {
        return replaceMatchesWithFormattedValue({
          value: String(value),
          matches: valueMatches,
          formatFunction: (valueToFormat) =>
            formatDate(valueToFormat, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
        });
      }

      if (!isDateValid(String(value))) {
        return value;
      }

      return formatDate(String(value), {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    case 'map':
      return valueMap[String(value)];
    case 'currency':
      const matches = String(value)?.match?.(pattern);
      if (matches?.length) {
        return replaceMatchesWithFormattedValue({
          value: String(value),
          matches,
          formatFunction: (valueToFormat) =>
            formatNumber(Number(valueToFormat), {
              currency: region === CountryCode.US ? 'USD' : 'EUR',
              style: 'currency',
            }),
        });
      }

      const numberPattern = /([0-9]*[.])?[0-9]+/g;
      const numberMatches = String(value)?.match?.(numberPattern);
      if (numberMatches?.length) {
        return replaceMatchesWithFormattedValue({
          value: String(value),
          matches: numberMatches,
          formatFunction: (valueToFormat) =>
            formatNumber(Number(valueToFormat), {
              currency: region === CountryCode.US ? 'USD' : 'EUR',
              style: 'currency',
            }),
        });
      }

      if (!isNumberValid(Number(value))) {
        return value;
      }

      return formatNumber(Number(value), {
        currency: region === CountryCode.US ? 'USD' : 'EUR',
        style: 'currency',
      });
    case 'distance':
      return `${value} ${region === CountryCode.US ? 'mi' : 'km'}`;
    case 'message':
      return formatMessage(
        { id: String(message), defaultMessage: String(message) },
        { value }
      );
    case 'date_month_year':
      return formatDate(String(value), {
        month: 'long',
        year: 'numeric',
      });
    default:
      return value;
  }
};

const parsePath = (originalPath: string): { type: string; path: string[] } => {
  const [type, ...path] =
    originalPath
      ?.replace?.('{', '')
      ?.replace?.('}', '')
      ?.replace?.('[', '.')
      ?.replace?.(']', '')
      ?.split?.('.') || [];

  return { type, path };
};

const getValueByPath = (
  curPathToValue: string,
  {
    formData,
    returnData,
    handoverMetadata,
    handoverData,
    preReturnAppraisal,
    returnMetadata,
    deal,
    carInventoryData,
    ...customResources
  }: Partial<ReturnType<typeof useDataForFeature>>
): string | number | boolean | Date | null => {
  const { type, path } = parsePath(curPathToValue);

  if (!curPathToValue?.includes?.('{')) {
    return get(deal, curPathToValue?.split?.('.')) ?? curPathToValue ?? null;
  }
  if (curPathToValue === '{today}') {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return date.toISOString();
  }

  if (type === 'min_date') {
    return (
      getMinMaxDates(
        (getValueByPath(`{${path?.join('.')}}`, {
          formData,
          handoverMetadata,
          handoverData,
          returnMetadata,
          deal,
          carInventoryData,
          ...customResources,
        }) as unknown as string[]) || []
      )?.minDate ?? null
    );
  }
  if (type === 'max_date') {
    return (
      getMinMaxDates(
        (getValueByPath(`{${path?.join('.')}}`, {
          formData,
          handoverMetadata,
          handoverData,
          returnMetadata,
          deal,
          carInventoryData,
          ...customResources,
        }) as unknown as string[]) || []
      )?.maxDate ?? null
    );
  }
  if (type === 'form_data') {
    return get(formData, path) ?? null;
  }
  if (type === 'handover') {
    return get(handoverData, path) ?? null;
  }
  if (type === 'new_return_data' || type === 'return_data') {
    return get(returnData, path) ?? null;
  }
  if (type === 'pre_return_appraisal') {
    return get(preReturnAppraisal, path) ?? null;
  }
  if (type === 'return_metadata') {
    return get(returnMetadata, path) ?? null;
  }
  if (type === 'handover_metadata') {
    return get(handoverMetadata, path) ?? null;
  }
  if (type === 'deal') {
    if (curPathToValue === '{deal.retention.is_retention_window_open}') {
      return isRententionWindowOpen(deal);
    }

    return get(deal, path) ?? null;
  }
  if (type === 'handover_address') {
    return formatAddress(handoverData?.address);
  }
  if (type === 'car_inventory') {
    return get(carInventoryData, path) ?? null;
  }
  if (customResources?.[type]) {
    return get(customResources[type], path) ?? null;
  }
};

export const getValueFromPath = (
  curPathToValue: string,
  resourcesData: ReturnType<typeof useDataForFeature>
): string | number | boolean | Date | null => {
  const pattern = /\{.*?\}/g;
  const matches = curPathToValue?.match?.(pattern);

  // it means that we have pure value, not string with value
  if (
    curPathToValue?.trim?.()?.startsWith?.('{') &&
    curPathToValue?.trim()?.endsWith?.('}') &&
    matches?.length === 1
  ) {
    return getValueByPath(curPathToValue, resourcesData);
  }

  if (matches?.length) {
    const res = matches.reduce((acc, match) => {
      const valueFromMatch = getValueByPath(match, resourcesData);

      return acc.replace(match, `{${valueFromMatch ?? ''}}`);
    }, curPathToValue);

    return res;
  }

  return getValueByPath(curPathToValue, resourcesData);
};

export const toArray = <T>(val: T | T[]) => {
  if (val === undefined || val === null) {
    return [] as T[];
  }

  return Array.isArray(val) ? val : [val];
};
