import { ReturnMetadata } from '@finn/b2c-cp/bff/core-api/types';
import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Grid } from '@finn/b2c-cp/features-components/Grid/Grid';
import { GridRow } from '@finn/b2c-cp/features-components/Grid/GridRow';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { useCosmicModule } from '@finn/platform-modules';
import React from 'react';
import { useIntl } from 'react-intl';

interface ReturnTypeSelectionStepProps {
  returnMetadata: ReturnMetadata;
  isLoading: boolean;
}

const ReturnTypeSelectionStep: React.FC<ReturnTypeSelectionStepProps> = ({
  returnMetadata,
  isLoading,
}) => {
  const content = useCosmicModule('advanced-edit-return');
  const { formatNumber } = useIntl();
  const returnFee = returnMetadata?.return_fee;

  const { selfReturn, pickupReturn } =
    content?.location_selection?.returnPreferences ?? {};

  const pickupReturnLabel = pickupReturn?.label || 'Abholung';
  const selfReturnLabel = selfReturn?.label || 'Selbstruckgabe';

  const returnInstructions = content?.location_selection?.instructions || [];

  return (
    <div className="mt-8">
      <Skeleton loading={isLoading}>
        <Grid cols={4}>
          <GridRow colsSpan={4}>
            <FeatureFormField
              type="radio"
              name="return_type"
              fullWidth
              radioItems={[
                {
                  label: pickupReturnLabel,
                  value: 'PICKUP',
                  endAdornment: (
                    <p className="body-14-light">
                      {formatNumber(returnFee, {
                        currency: 'EUR',
                        style: 'currency',
                      })}
                    </p>
                  ),
                },
                {
                  label: selfReturnLabel,
                  value: 'SELF_RETURN',
                  endAdornment: (
                    <p className="body-14-light">{selfReturn?.priceLabel}</p>
                  ),
                },
              ]}
              variant="card"
              className="md:grid-cols-1"
              orientation="horizontal"
            />
          </GridRow>
          <GridRow colsSpan={4}>
            <h6 className="global-t6-semibold">
              {content?.location_selection?.subtitle}
            </h6>
            <ol className="mt-4 list-none space-y-2 pl-0">
              {returnInstructions.map((instruction, index) => (
                <li key={index} className="mb-2 flex">
                  <strong className="body-14-semibold mr-2">{index + 1}</strong>
                  <TextBlock text={instruction} className="flex-1" />
                </li>
              ))}
            </ol>
          </GridRow>
        </Grid>
      </Skeleton>
    </div>
  );
};

export const returnTypeSelectionStep = ({
  content,
  returnMetadata,
  availabilitiesIsLoading,
}: {
  content?: {
    title?: string;
    footerCta?: string;
  };
  returnMetadata: ReturnMetadata;
  availabilitiesIsLoading: boolean;
}) => {
  return prepareWizardStep({
    render: () => (
      <ReturnTypeSelectionStep
        returnMetadata={returnMetadata}
        isLoading={availabilitiesIsLoading}
      />
    ),
    title: content?.title,
    footer: { cta: { action: 'next-step', label: content?.footerCta } },
    fields: [
      {
        name: 'return_type',
        type: 'radio',
        defaultValue: 'PICKUP',
      },
    ],
  });
};
