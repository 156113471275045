import { cn } from '@finn/ui-utils';
import { CalendarDate, getWeeksInMonth } from '@internationalized/date';
import { forwardRef } from 'react';
import { CalendarAria, useCalendarGrid, useLocale } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { Cell } from './Cell';
import { TableControls } from './TableControls';

type TableProps = {
  state: CalendarState | RangeCalendarState;
  withOneClickRangeSelection?: boolean;
  className?: string;
  cellClassName?: string;
} & CalendarAria;

export const Table = forwardRef<HTMLDivElement, TableProps>(
  (
    {
      className,
      cellClassName,
      calendarProps,
      prevButtonProps,
      nextButtonProps,
      title,
      state,
      withOneClickRangeSelection,
    },
    ref
  ) => {
    const { locale } = useLocale();

    const { gridProps, headerProps, weekDays } = useCalendarGrid(
      {
        weekdayStyle: 'short',
      },
      state
    );

    // Get the number of weeks in the month so we can render the correct number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    const weeks = [...new Array(weeksInMonth).fill(true)].map(
      (_, weekIndex) => {
        const daysInThisWeek = state.getDatesInWeek(
          weekIndex
        ) as CalendarDate[];

        return {
          days: daysInThisWeek,
        };
      }
    );

    return (
      <div
        {...calendarProps}
        ref={ref}
        className={cn(
          'box-border flex w-full flex-col items-center bg-white px-8 py-6',
          className
        )}
      >
        <TableControls
          title={title}
          prevButtonProps={prevButtonProps}
          nextButtonProps={nextButtonProps}
        />
        <table
          {...gridProps}
          cellPadding={0}
          className={cn(
            'w-full border-separate text-black',
            // we add border spacing according to figma, as tables do not support margins
            // we also need to compensate for the border-spacing at left, right, top and bottom
            '-mx-2 -mt-4 border-spacing-x-2 border-spacing-y-4'
          )}
        >
          <thead {...headerProps} className="border-spacing-0">
            <tr>
              {weekDays.map((day, index) => (
                <th key={index}>
                  <div
                    className={cn(
                      'body-14-medium relative mx-auto flex h-10 w-10 items-center justify-center text-center',
                      cellClassName
                    )}
                  >
                    {day}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map(({ days }, weekIndex) => (
              <tr key={weekIndex}>
                {days.map((date, dateIndex) => (
                  <Cell
                    className={cellClassName}
                    key={dateIndex}
                    date={date}
                    state={state}
                    withOneClickRangeSelection={withOneClickRangeSelection}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
