import { HandoverData } from '@finn/b2c-cp/bff/core-api/types';
import { isInThePast } from '@finn/b2c-cp/deprecated/time';
import { SwapDataSection } from '@finn/b2c-cp/features-components/banners/SwapBanner/SwapDataSection';
import { If } from '@finn/b2c-cp/features-components/IfRender';
import { Address, formatAddress } from '@finn/b2c-cp/services/format-address';
import { Button } from '@finn/design-system/atoms/button';
import { Deal } from '@finn/platform-modules';
import Link from 'next/link';

const isCarDelivered = (date?: string) => {
  return isInThePast(date);
};

type SwapHandoverSectionProps = {
  labelsMap: { [key: string]: string };
  deal?: Deal;
  handoverData?: HandoverData;
};

export const SwapHandoverSection = ({
  labelsMap,
  deal,
  handoverData,
}: SwapHandoverSectionProps) => {
  let address: Address;
  let date;
  let time;

  if (deal?.variant === 'LEAD') {
    date = deal?.preferred_handover_date;
    address = {
      street: deal?.handover_street,
      house_number: deal?.handover_housenumber,
      zip: deal?.handover_zip,
      city: deal?.handover_city,
      address_extra: deal?.handover_address_extra,
    };
  }

  if (deal?.variant === 'SUBSCRIPTION') {
    date =
      handoverData?.planned_delivery_date ??
      handoverData?.preferred_delivery_date;
    address = handoverData?.address;
    time = handoverData?.slot?.formatted_time;
  }

  const isDelivered = isCarDelivered(date);

  return (
    <SwapDataSection
      labelsMap={labelsMap}
      deal={deal}
      date={date}
      time={time}
      address={formatAddress(address)}
      title={isDelivered ? labelsMap.car_delivered : labelsMap.car_in_delivery}
    >
      <If condition={!isDelivered}>
        <Link
          href={`mycars/${deal?.id}?modalName=MULTISTEP_EDIT_HANDOVER`}
          legacyBehavior
        >
          <Button
            className="mt-16 h-max px-6 py-2"
            variant="outline"
            href={`mycars/${deal?.id}?modalName=MULTISTEP_EDIT_HANDOVER`}
          >
            {labelsMap.edit_handover_button_label}
          </Button>
        </Link>
      </If>
    </SwapDataSection>
  );
};
