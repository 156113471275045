import { Fields } from '@finn/b2c-cp/features/Fields';
import { InfoRow } from '@finn/b2c-cp/features/return/components/InfoRow';
import { ReturnRentetionBanner } from '@finn/b2c-cp/features/return/components/ReturnRentetionBanner';
import { PlatformComponent } from '@finn/b2c-cp/features-components/PlatformComponent';
import { useValue } from '@finn/b2c-cp/features-data';
import {
  ModalBadge,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import {
  Banner,
  Description as DescriptionType,
  FieldPath,
  InfoRow as InfoRowType,
} from '@finn/platform-modules';
import { PlatformCosmicModule } from '@finn/ui-cosmic';
import { parseToHtml, useSession } from '@finn/ui-utils';
import { Fragment, ReactNode, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { Field } from '../FeatureForm/types';
import { IfRender } from '../IfRender';
import { BuilderLoader } from './components/BuilderLoader';
import { Caption } from './components/Caption';
import { Content } from './components/Content';
import { Description } from './components/Description';
import { Footer, FooterProps } from './components/Footer';
import { Title } from './components/Title';

export type BuilderProps = {
  title?: string | { label?: string };
  fields?: Field[];
  footer?: FooterProps;
  caption?: string;
  location?: string;
  name?: string;
  modules?: PlatformCosmicModule[];
  description?: DescriptionType;
  sub_description?: DescriptionType;
  info_rows?: InfoRowType[];
  banners?: Banner[];
  renderContent?: () => ReactNode;
  renderAfterContent?: () => ReactNode;
  isCTADisabled?: FooterProps['isCTADisabled'];
  onCTAClick?: ({ isValid }: { isValid?: boolean }) => void;
  onSecondaryCTAClick?: () => void;
  waitForUserSession?: boolean;
  config?: {
    waitForResource?: FieldPath;
  };
  isModal?: boolean;
};

export const Builder = ({
  title,
  fields,
  caption,
  footer,
  modules,
  description,
  info_rows,
  banners,
  sub_description,
  location,
  config,
  renderContent,
  renderAfterContent,
  isCTADisabled,
  onCTAClick,
  onSecondaryCTAClick,
  waitForUserSession,
  isModal,
}: BuilderProps) => {
  const [, status] = useValue(config?.waitForResource);
  const shouldWaitForResource = status === 'loading' && config?.waitForResource;
  const modulesToRender = useMemo(
    () =>
      modules?.map((module) => ({
        ...module,
        id: uuid(),
      })) || [],
    [modules]
  );
  const formMethods = useFormContext();

  const [session, isSessionLoading] = useSession();

  const isCTADisabledFallback = useCallback(() => {
    const errors = formMethods?.formState?.errors || {};

    return Object.keys(errors)
      .filter((key) => errors[key])
      .some((key) => fields?.find((field) => field.name === key));
  }, [formMethods, fields]);

  const handleCTAClick = useCallback(async () => {
    await formMethods?.trigger(fields?.map((field) => field.name) || []);

    onCTAClick?.({ isValid: !isCTADisabledFallback() });
    footer?.cta?.onCtaClick?.({ isValid: !isCTADisabledFallback() });
  }, [footer, isCTADisabledFallback, fields, formMethods, onCTAClick]);

  if (waitForUserSession) {
    if (isSessionLoading || shouldWaitForResource) {
      return <BuilderLoader />;
    }
    if (!isSessionLoading && !session) {
      return null;
    }
  }

  const HeaderContainer = isModal ? ModalHeader : Fragment;
  const CaptionComponent = isModal ? ModalBadge : Caption;
  const TitleComponent = isModal ? ModalTitle : Title;

  return (
    <>
      <HeaderContainer>
        {caption ? (
          <CaptionComponent>{parseToHtml(caption)}</CaptionComponent>
        ) : null}
        {title ? (
          <TitleComponent>
            {parseToHtml(typeof title === 'string' ? title : title?.label)}
          </TitleComponent>
        ) : null}
      </HeaderContainer>
      <Content supportLink={footer?.link}>
        {renderContent?.()}
        <Description description={description} />
        {modulesToRender?.map((module) => (
          <PlatformComponent key={module?.id} module={module} />
        ))}
        {fields?.length > 0 && <Fields fields={fields} />}
        {info_rows?.map((infoRow, index) => (
          <IfRender
            key={`${infoRow.label}-${index}`}
            visibilityConfig={infoRow?.visibilityConfig}
          >
            <InfoRow {...infoRow} />
          </IfRender>
        ))}
        {banners?.map((banner) => (
          <IfRender
            key={banner.description}
            visibilityConfig={banner.config?.visibilityConfig}
          >
            <ReturnRentetionBanner {...banner} />
          </IfRender>
        ))}
        <Description description={sub_description} />
        {renderAfterContent?.()}
      </Content>
      <Footer
        location={location}
        {...footer}
        isCTADisabled={isCTADisabled || isCTADisabledFallback}
        onSecondaryCTAClick={onSecondaryCTAClick}
        onCTAClick={handleCTAClick}
        isModal={isModal}
      />
    </>
  );
};
