import Link from 'next/link';

export type InvoicesProps = {
  title?: string;
  description?: string;
  link?: {
    label?: string;
    url?: string;
  };
};

import { ClockOutlined } from '@finn/design-system/icons/clock-outlined';
import { ErrorFilled } from '@finn/design-system/icons/error-filled';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { WarningFilled } from '@finn/design-system/icons/warning-filled';
import { cn, trackEvent } from '@finn/ui-utils';
import React from 'react';

type IconType = 'info' | 'warning' | 'error' | 'clock';

type BannerProps = {
  iconType?: IconType;
  title?: string;
  description: string | React.ReactNode;
  c2a?: React.ReactNode;
};

const Banner = ({ iconType, title, description, c2a }: BannerProps) => {
  const getIcon = () => {
    switch (iconType) {
      case 'warning':
        return <WarningFilled />;
      case 'info':
        return <InfoOutlined />;
      case 'error':
        return <ErrorFilled />;
      case 'clock':
        return <ClockOutlined />;
    }
  };

  return (
    <div
      className={cn('bg-snow flex items-center gap-x-2 gap-y-5 rounded p-4')}
    >
      {iconType && getIcon()}
      <div className="flex flex-col gap-x-2">
        {title && <span className="body-14-semibold">{title}</span>}
        <span className="body-14-light">
          {description}
          {c2a}
        </span>
      </div>
    </div>
  );
};

export const Invoices = ({ title, description, link }: InvoicesProps) => (
  <div className="mt-4 grid gap-6" id="invoices">
    <h3 className="global-t5-semibold md:web-t4-semibold">{title}</h3>
    <Banner
      iconType="info"
      description={
        <p className="body-16-regular flex flex-wrap items-center md:flex-nowrap">
          {description}
          <Link
            href={link?.url || ''}
            className="link-16 ml-0 w-full md:ml-2 md:w-auto"
            onClick={() => trackEvent('Forward to Invoices Clicked', {})}
          >
            {link?.label}
          </Link>
        </p>
      }
    />
  </div>
);
