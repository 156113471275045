import { useCompaniesData } from '@finn/b2c-cp/features-data';
import { Button } from '@finn/design-system/atoms/button';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { useDeals } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';
import Link from 'next/link';

import { DataItemProps } from '../../DataList';
import { ValueItem } from './ValueItem';

export type CompanySectionCosmicConfig = {
  section_title?: string;
  section_cta?: {
    label?: string;
    url?: string;
  };
  section_items?: DataItemProps[];
};

type CompanySectionProps = {
  content: CompanySectionCosmicConfig;
  className?: string;
};

export const CompanySection = ({ content }: CompanySectionProps) => {
  const { data: companyResults } = useCompaniesData();
  const { deals } = useDeals();
  const hasMiniB2BDeals = deals?.some((sub) => sub.type === 'MINIB2B') || false;
  const hasCompanyData = companyResults?.length > 0 && hasMiniB2BDeals;

  if (!hasCompanyData) {
    return null;
  }

  return (
    <DataModule>
      <DataModuleHeader>
        <DataModuleTitle asChild>
          <p>{content?.section_title}</p>
        </DataModuleTitle>
      </DataModuleHeader>
      <DataModuleBody>
        {content?.section_items.map((item, index) => (
          <ValueItem
            key={`row-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}
      </DataModuleBody>
      <DataModuleFooter>
        <Link
          href={content.section_cta?.url}
          onClick={() => {
            trackEvent('CTA Clicked', {
              location,
              label: content.section_cta?.label,
            });
          }}
        >
          <Button size="lg" variant="outline" className="w-full md:w-max">
            {content.section_cta?.label}
          </Button>
        </Link>
      </DataModuleFooter>
    </DataModule>
  );
};
