import { KeyboardArrowLeft } from '@finn/design-system/icons/keyboard-arrow-left';
import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { useRef } from 'react';
import { AriaButtonProps, useButton } from 'react-aria';

import { Button } from '../button';

type TableControlsProps = {
  title: string;
  prevButtonProps: AriaButtonProps;
  nextButtonProps: AriaButtonProps;
};

export const TableControls = (props: TableControlsProps) => {
  const prevMonthRef = useRef<HTMLButtonElement>(null);
  const nextMonthRef = useRef<HTMLButtonElement>(null);

  const { buttonProps: prevButtonProps } = useButton(
    props.prevButtonProps,
    prevMonthRef
  );
  const { buttonProps: nextButtonProps } = useButton(
    props.nextButtonProps,
    nextMonthRef
  );

  /* 
    The root div has a min-width of 328px according to figma.
    In order to make this header be positioned in the center of the calendar
    and left button right corner need to be aligned with weekday center we need to do some math.
    We take current available width(100%) and remove border spacing from it(16px).
    Than we need to account for the difference in table growth between minimal width(by figma) and 
    growth, to do so we need to take 100% and subtract minimal width and divide it by 7(number of days) to get the
    difference. Than we substract this difference from initially calculated width.
    As a result we will have correctly sized calender that can properly scale.
  */
  return (
    <div className="mb-6 flex w-[calc(100%-16px-((100%-328px)/7))] min-w-[328px] items-center justify-between">
      <Button
        variant="secondaryWhite"
        className="h-max w-max p-0 disabled:bg-transparent"
        {...prevButtonProps}
      >
        <KeyboardArrowLeft />
      </Button>
      <h2 className="body-14-semibold">{props.title}</h2>
      <Button
        variant="secondaryWhite"
        className="h-max w-max p-0 disabled:bg-transparent"
        {...nextButtonProps}
      >
        <KeyboardArrowRight />
      </Button>
    </div>
  );
};
