import {
  Button as ButtonDS,
  ButtonProps as ButtonPropsDS,
} from '@finn/design-system/atoms/button';
import { Action, VisibilityConfig } from '@finn/platform-modules';
import { useCallback } from 'react';

import { useFeatureAction, useIsFeatureVisible } from '../features-data';

export type ButtonProps = {
  action?: Action;
  label?: string;
  loading?: boolean;
  disabled?: VisibilityConfig;
  visibilityConfig?: VisibilityConfig;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonPropsDS['variant'];
  href?: string;
  size?: ButtonPropsDS['size'];
  className?: string;
  trackingProps?: Record<string, string | { field: string }>;
  isDisabled?: () => boolean;
  onClick?: () => void;
};

export const Button = ({
  action,
  label,
  disabled,
  className,
  variant = 'primary',
  size = 'lg',
  loading,
  type = 'button',
  isDisabled,
  onClick,
  ...rest
}: ButtonProps) => {
  const runAction = useFeatureAction({
    ...rest,
    action: action ?? 'close_modal',
  });
  const isVisible = useIsFeatureVisible(rest.visibilityConfig, true);

  const isDisabledCTA = useIsFeatureVisible(disabled, false) || isDisabled?.();

  const handleClick = useCallback(() => {
    runAction?.();
    onClick?.();
  }, [runAction, onClick]);

  if (!label || !isVisible) {
    return null;
  }

  return (
    <ButtonDS
      className={className}
      loading={loading}
      disabled={isDisabledCTA}
      size={size}
      variant={variant}
      type={type}
      href={rest.href}
      onClick={handleClick}
    >
      {label}
    </ButtonDS>
  );
};
