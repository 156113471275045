import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useDealId } from '@finn/b2c-cp/features-data';
import { useCurrentLocale } from '@finn/ui-utils';
import { useEffect } from 'react';

import { useCurrentDeal } from '../features-data/hooks/useCurrentDeal';

const SWAP_MODAL = 'swap_modal';

type SwapProlongationModalProps = {
  open: boolean;
  onClose: () => void;
};

const SwapProlongationModal = ({
  open,
  onClose,
}: SwapProlongationModalProps) => {
  // For now we do now want to show modal, just instant redirect to shopping product
  // it may change in the future

  const { data: deal } = useCurrentDeal();
  const { locale } = useCurrentLocale();

  useEffect(() => {
    if (!open) {
      return;
    }
    onClose?.();
    const returnDate = deal?.preferred_return_date || deal?.end_date;
    window.location.href = `/${locale}/subscribe?available_from=${returnDate?.split?.('T')?.[0]}`;
  }, [deal, locale, open, onClose]);

  return <></>;
};

const renderSwapProlongationModal = (open: boolean, onClose: () => void) => (
  <SwapProlongationModal open={open} onClose={onClose} />
);

export const SwapProlongationModalContainer = () => {
  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer
      name={SWAP_MODAL}
      renderModal={renderSwapProlongationModal}
    />
  );
};
