import { cn } from '@finn/ui-utils';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';

import { Button, ButtonProps } from '../../atoms/button/button';
import { Slot } from '../../internal/Slot';

type ExecutionItemProps = ButtonProps & {
  variant?: never;
  size?: never;
};

export const ExecutionItem = (props: ExecutionItemProps) => {
  return <Button {...props} variant="action" />;
};

const gridColsDesktopMap = {
  1: 'sm:grid-cols-1',
  2: 'sm:grid-cols-2',
  3: 'sm:grid-cols-3',
};

export const ExecutionModule = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

export const ExecutionModuleContent = ({
  cols = 1,
  children,
  size,
  className,
}: {
  children: ReactNode;
  cols?: 1 | 2 | 3;
  size?: ButtonProps['size'];
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'grid grid-cols-1 gap-5',
        gridColsDesktopMap[cols],
        className
      )}
    >
      {Children.map(
        children as ReactElement<{ size?: ButtonProps['size'] }>[],
        (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { size });
          }

          return null;
        }
      )}
    </div>
  );
};

export const ExecutionModuleHeader = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className="w-full">{children}</div>;
};

export const ExecutionModuleTitle = ({
  children,
  asChild,
}: {
  children: ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'p';

  return <Comp className="global-t5-semibold mb-6">{children}</Comp>;
};

export const ExecutionModuleSubtitle = ({
  children,
  asChild,
}: {
  children: ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'p';

  return <Comp className="body-14-regular mb-6">{children}</Comp>;
};
