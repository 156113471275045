import { CalendarDate, isSameDay, parseDate } from '@internationalized/date';
import { CalendarState, RangeCalendarState } from 'react-stately';

type SelectionData = {
  date: CalendarDate;
  isSelected?: boolean;
  withOneClickRangeSelection?: boolean;
};

// this heper function returns metadata about is current cell selected, selections tart, end or in range
// this data later can be used to style calendar properly
export const getSelectionData = (
  state: RangeCalendarState | CalendarState,
  { date, isSelected, withOneClickRangeSelection }: SelectionData
) => {
  // library marks disabeld dates as not selected, even if they are in the range
  // to fix this we manually change the state of it
  if (
    !isSelected &&
    'highlightedRange' in state &&
    state.highlightedRange?.start
  ) {
    isSelected =
      state.highlightedRange &&
      date.compare(state.highlightedRange.start) >= 0 &&
      date.compare(state.highlightedRange.end) <= 0;
  }

  // default state for single calendar
  const selectionResult = {
    isSelectionStart: isSelected,
    isSelectionEnd: isSelected,
    isOnlySingleDaySelected: false,
    hasWrapperWithInSelection: false,
    hasWrapperSectionStart: false,
    hasWrapperSelectionEnd: false,
  };

  if ('highlightedRange' in state) {
    // The start and end date of the selected range will have
    // an emphasized appearance.
    selectionResult.isSelectionStart = state.highlightedRange
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;
    selectionResult.isSelectionEnd = state.highlightedRange
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;
  }

  let isCellSelected = isSelected;
  if (withOneClickRangeSelection && state?.value && 'start' in state.value) {
    const isInSelectedRange =
      state.value &&
      date.compare(state.value.start) >= 0 &&
      date.compare(state.value.end) <= 0;

    isCellSelected = isSelected || isInSelectedRange;
    selectionResult.hasWrapperWithInSelection =
      isCellSelected &&
      !selectionResult.isSelectionStart &&
      !selectionResult.isSelectionEnd;
  }

  selectionResult.hasWrapperWithInSelection = Boolean(
    isCellSelected &&
      !selectionResult.isSelectionStart &&
      !selectionResult.isSelectionEnd
  );
  selectionResult.hasWrapperSectionStart = Boolean(
    selectionResult.isSelectionStart && !selectionResult.isSelectionEnd
  );
  selectionResult.hasWrapperSelectionEnd = Boolean(
    selectionResult.isSelectionEnd && !selectionResult.isSelectionStart
  );

  return selectionResult;
};

export const toCalendarDate = (date?: Date | string) => {
  if (!date) {
    return undefined;
  }

  if (typeof date === 'string') {
    return parseDate(date?.split?.('T')?.[0]);
  }

  return new CalendarDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );
};
