import { ModalKey } from '@finn/b2c-cp/deprecated/modules/modals';
import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { InfoModal } from '@finn/b2c-cp/features-components/InfoModal';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useDrivers } from '@finn/b2c-cp/features-data';
import {
  DataModule,
  DataModuleBody,
  DataModuleCell,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleRow,
  DataModuleSubtitle,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { parseToHtml, trackEvent } from '@finn/ui-utils';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { MessageBannerProps } from '../../features-components/banners/MessageBanner';
import { Banners } from '../Banners';
import { Button, ButtonProps } from '../button';

type DriversListProps = {
  statusesMap?: {
    [key: string]: string;
  };
  cta?: ButtonProps;
  deleteItem?: {
    failed?: {
      title: string;
      description: string;
      CTA: string;
    };
    modal?: {
      title?: string;
      description?: string;
      CTA?: string;
      secondaryCTA?: string;
    };
  };
};

type DriversModuleProps = {
  title?: string;
  description?: string;
  cta?: ButtonProps;
  banners?: MessageBannerProps[];
  drivers?: DriversListProps;
};

const DELETE_DRIVER = 'delete_driver';

export const DriversModule = ({
  title,
  description,
  banners,
  drivers,
  cta,
}: DriversModuleProps) => {
  const router = useRouter();
  const { drivers: driversList, driversStatus, mutate } = useDrivers();
  const [isDeleting, setIsDeleting] = useState(false);
  const { openModal } = useModalStore();
  const [driverIdToDelete, setDriverIdToDelete] = useState('');

  const handeClick = () => {
    trackEvent('Add Driver Clicked', {});
    router.push('/myaccount/drivers/add');
  };

  const tryToDelete = async (id: string) => {
    setIsDeleting(true);

    try {
      const { status } = await axios.delete(`/portal/api/bff/drivers/${id}`);
      trackEvent('Delete Driver Succesfull', { driverId: id });
      if (status === 200) {
        // mutate client cache to remove driver from it
        mutate({
          data: {
            data: driversList?.filter((driver) => driver.id !== id),
          },
          status: 200,
        });
      }
    } catch (error) {
      openModal(ModalKey.ERROR_MODAL);
    }
    setIsDeleting(false);
  };

  const handleDelete = async (id: string) => {
    const driver = driversList.find((item) => item.id === id);
    trackEvent('Delete Driver Clicked', { driverId: id });
    if (driver?.properties?.status !== 'rejected') {
      // driver is active and Modal is shown
      setDriverIdToDelete(id);
      openModal(DELETE_DRIVER);
    } else {
      tryToDelete(id);
    }
  };

  const renderErrorModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="error"
      open={open}
      onClose={onClose}
      heading={drivers?.deleteItem?.failed?.title}
      htmlDescription={drivers?.deleteItem?.failed?.description}
      affirmationText={drivers?.deleteItem?.failed?.CTA}
      onAffirmation={() => {
        onClose();
      }}
    />
  );

  const renderDeleteDriverModal = (open: boolean, onClose: () => void) => {
    return (
      <ModalBuilder
        title={drivers?.deleteItem?.modal?.title}
        description={drivers?.deleteItem?.modal?.description}
        footer={{
          cta: {
            action: 'empty',
            label: drivers?.deleteItem?.modal?.CTA,
            onCtaClick: () => {
              tryToDelete(driverIdToDelete);
              onClose();
            },
          },
          secondaryCTA: {
            action: 'close_modal',
            label: drivers?.deleteItem?.modal?.secondaryCTA,
          },
        }}
        open={open}
        onClose={onClose}
      />
    );
  };

  return (
    <div className="grid gap-y-8">
      <DataModule className="bg-white">
        <DataModuleHeader>
          <DataModuleTitle asChild>
            <h3>{title}</h3>
          </DataModuleTitle>
          <DataModuleSubtitle>
            {description ? parseToHtml(description) : null}
          </DataModuleSubtitle>
        </DataModuleHeader>

        <DataModuleBody>
          {driversStatus === 'loading' ? (
            <>
              <DataModuleRow>
                <Skeleton fullWidth loading>
                  <div className="h-8" />
                </Skeleton>
              </DataModuleRow>
              <DataModuleRow>
                <Skeleton fullWidth loading>
                  <div className="h-8" />
                </Skeleton>
              </DataModuleRow>
            </>
          ) : null}
          {driversList?.map((driver) => {
            const name = `${driver?.properties?.firstname} ${driver?.properties?.lastname}`;
            const canDelete =
              driver.type === 'secondary' &&
              driver?.properties?.status !== 'pending';

            return (
              <DataModuleRow key={driver.id}>
                <DataModuleCell>{name}</DataModuleCell>
                <DataModuleCell>
                  {drivers?.statusesMap?.[driver?.properties?.status as string]}
                </DataModuleCell>
                <DataModuleCell>
                  <Button
                    {...drivers?.cta}
                    loading={isDeleting}
                    size="md"
                    variant="outline"
                    className={canDelete ? 'body-14-semibold' : 'invisible'}
                    onClick={() => handleDelete(driver.id)}
                  />
                </DataModuleCell>
              </DataModuleRow>
            );
          })}
        </DataModuleBody>
        <Banners banners={banners} />
        <DataModuleFooter>
          <Button
            className="w-full md:max-w-sm"
            {...cta}
            onClick={handeClick}
          />
        </DataModuleFooter>
      </DataModule>
      <ModalContainer
        name={ModalKey.ERROR_MODAL}
        renderModal={renderErrorModal}
      />
      <ModalContainer
        name={DELETE_DRIVER}
        renderModal={renderDeleteDriverModal}
      />
    </div>
  );
};
