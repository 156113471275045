import { useGetLoyaltyInfo } from '@finn/platform-modules';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { LoyaltyLevels } from '@finn/ui-modules';

import { PersonalizedLoyaltySection } from './components/PersonalizedLoyaltySection';
import { useGetLoyaltyBenefits } from './hooks/useGetLoyaltyBenefits';

export const Loyalty = (metadata: LoyaltyCosmicMetadata) => {
  const { data: loyaltyInfo, isLoading } = useGetLoyaltyInfo();
  const { data: loyaltyBenefits, isLoading: isLoadingLoyaltyBenefits } =
    useGetLoyaltyBenefits();

  return (
    <>
      <PersonalizedLoyaltySection
        data={metadata}
        loyaltyInfo={loyaltyInfo}
        isLoadingLoyaltyInfo={isLoading || isLoadingLoyaltyBenefits}
        loyaltyBenefits={loyaltyBenefits}
      />
      <LoyaltyLevels className="grid" data={metadata} isUsedAsModule />
    </>
  );
};
