import { Builder, FormWrapper } from '@finn/b2c-cp/features-components/Builder';
import { toStepFields, Wizard } from '@finn/b2c-cp/features-components/Wizard';
import { useDealId, useFormSubmit } from '@finn/b2c-cp/features-data';
import { Step } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { trackEvent } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { NoDeal, NoDealProps } from './NoDeal';

type ReportDamageProps = {
  error_toast?: string;
  success_toast?: string;
  steps: Step[];
  no_deal?: NoDealProps;
};

/* cosmic part https://app-v1.cosmicjs.com/finnauto/edit-object/66191d25e971b8d9139e4db2 */

// we render FormWrapper around Wizard
// wizard has steps and onFormSubmit callback
// steps defined in cosmic, each step can contain fields, description and other simple components
// onFormSubmit is a callback that is called when form is submitted

export const ReportDamage = ({
  steps,
  no_deal,
  error_toast,
  success_toast,
}: ReportDamageProps) => {
  const router = useRouter();
  const dealId = useDealId();
  const { handleSubmit: handleFormSubmit } = useFormSubmit('report-damage', {
    error_toast,
    success_toast,
    location: 'report_damage',
    step: steps?.length,
  });

  const handleSubmit = useCallback(
    async (data) => {
      trackEvent('Report Damage Submitted', {});
      const status = await handleFormSubmit(data);

      // we redirect to my cars page
      if (status === 201) {
        trackEvent('Report Damage Submitted Successful', {});
        await router.push(`${SelfServiceRoutes.MY_CARS}/${dealId}`);
      }
    },
    [dealId, handleFormSubmit, router]
  );

  const fieldsArr = useMemo(() => toStepFields(steps), [steps]);

  return (
    <FormWrapper fields={fieldsArr}>
      {dealId === 'no-deal' ? (
        <NoDeal {...no_deal} />
      ) : (
        <Wizard
          location="report_damage"
          steps={steps}
          renderChildren={(wizardProps) => <Builder {...wizardProps} />}
          onFormSubmit={handleSubmit}
        />
      )}
    </FormWrapper>
  );
};
