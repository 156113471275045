import { ReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { MessageBanner } from '@finn/b2c-cp/features-components/banners/MessageBanner';
import { usePreReturnAppraisal } from '@finn/b2c-cp/features-data/hooks/usePreReturnAppraisal';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { PreReturnAppraisal } from '@finn/b2c-cp/pages/api/bff/deals/[id]/pre-return-appraisal';
import { Button } from '@finn/design-system/atoms/button';
import { CalendarOutlined } from '@finn/design-system/icons/calendar-outlined';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleSubtitle,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { Action, useDeal, VisibilityConfig } from '@finn/platform-modules';
import { parseToHtml } from '@finn/ui-utils';
import { get } from 'lodash';
import { useMemo } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';

import { DataRowItem, DSRow } from '../features-components/DataRow';
import {
  useDealId,
  useFeatureAction,
  useIsFeatureVisible,
  useReturnMetadata,
} from '../features-data';
import { formatAddress } from '../services/format-address';

type Icon = 'calendar';

const iconsMap = {
  calendar: CalendarOutlined,
};

type Item = {
  label: string;
  icon?: Icon;
  action: Action;
  modalName: string;
  variant?: 'ghost';
  visibilityConfig: VisibilityConfig;
};

export type ReturnInfoProps = {
  title?: string;
  description?: string;
  data_rows?: { data: DataRowItem }[];
  pre_return_appraisal_banner?: {
    title: string;
    description: string;
    link: {
      label: string;
      url: string;
    };
  };
  footer?: Item[];
};

const ActionItem = ({ item }: { item: Item }) => {
  const runAction = useFeatureAction(item);
  const isVisible = useIsFeatureVisible(item?.visibilityConfig);

  if (!isVisible) {
    return null;
  }

  const Icon = iconsMap[item?.icon];

  return (
    <Button
      variant={item?.variant || 'outline'}
      className="w-full md:w-auto"
      onClick={runAction}
    >
      {Icon ? <Icon className="mr-2 max-w-5" /> : null}
      {item?.label}
    </Button>
  );
};

const useReturnInfo = () => {
  const dealId = useDealId();
  const { formatDate } = useIntl();
  const { data: subscription } = useDeal(dealId);
  const { data: returnData } = useReturn(dealId);
  const { data: returnMetadata } = useReturnMetadata(dealId);
  const { data: preReturnAppraisal } = usePreReturnAppraisal(dealId);

  const isDealInTheRightState =
    subscription &&
    preReturnAppraisal &&
    returnData?.type &&
    returnMetadata &&
    ['STOPPED', 'ACTIVE'].includes(subscription.state);

  return useMemo(() => {
    if (!isDealInTheRightState) {
      return null;
    }

    const firstName = returnData?.address.first_name;
    const lastName = returnData?.address.last_name;
    const contactName = `${firstName} ${lastName}`;

    return {
      ...returnData,
      preReturnAppraisal,
      pre_return_appraisal_date: formatPreReturnAppraisalDate(
        preReturnAppraisal,
        formatDate
      ),
      contact_name: contactName,
      return_date: formatReturnDate(returnData, formatDate),
      return_price: returnData?.fee,
      address: formatAddress(returnData?.address),
    };
  }, [isDealInTheRightState, returnData, preReturnAppraisal, formatDate]);
};

export const ReturnInfo = ({
  title,
  description,
  data_rows,
  pre_return_appraisal_banner,
  footer,
}: ReturnInfoProps) => {
  const returnInfo = useReturnInfo();
  const canBookPreReturnAppraisal =
    returnInfo?.preReturnAppraisal?.options &&
    !returnInfo?.preReturnAppraisal?.appointment;

  if (!returnInfo) {
    return null;
  }

  return (
    <DataModule className="bg-white">
      <DataModuleHeader>
        <DataModuleTitle asChild>
          <h3>{title}</h3>
        </DataModuleTitle>
        <DataModuleSubtitle>
          {description ? parseToHtml(description) : null}
        </DataModuleSubtitle>
      </DataModuleHeader>

      <DataModuleBody>
        {data_rows.map((row) => (
          <DSRow
            key={row?.data?.key}
            value={get(returnInfo, row?.data?.key)}
            config={row.data}
          />
        ))}
      </DataModuleBody>

      {canBookPreReturnAppraisal ? (
        <MessageBanner
          title={pre_return_appraisal_banner?.title}
          description={pre_return_appraisal_banner?.description}
          link={{
            label: pre_return_appraisal_banner?.link?.label,
            url: pre_return_appraisal_banner?.link?.url,
            newTab: true,
          }}
        />
      ) : null}

      <DataModuleFooter>
        {footer?.map((item) => <ActionItem key={item?.label} item={item} />)}
      </DataModuleFooter>
    </DataModule>
  );
};

type FormatDate = IntlFormatters['formatDate'];

function formatReturnDate(
  returnData: ReturnData | undefined,
  formatDate: FormatDate
): string {
  const returnDate =
    returnData?.planned_delivery_date ?? returnData?.preferred_delivery_date;

  if (!returnDate) {
    return null;
  }

  const formattedReturnDate = formatDate(returnDate, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return `${formattedReturnDate} um ${returnData?.slot?.formatted_time}`;
}

function formatPreReturnAppraisalDate(
  preReturnAppraisal: PreReturnAppraisal | undefined,
  formatDate: FormatDate
): string | null {
  const preReturnAppraisalDate = preReturnAppraisal?.appointment?.date;

  if (!preReturnAppraisalDate) {
    return null;
  }

  const formattedPreReturnAppraisalDate = formatDate(preReturnAppraisalDate, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  let slotTime: string;
  // we changed between different appointment slots already, this logic approx. displays what the customers
  // saw during the booking back then but also what they see if they booked just now
  // TODO: this will be integrated more maturely in a later iteration behind the API
  if (
    preReturnAppraisalDate.includes('14:00') ||
    preReturnAppraisalDate.includes('13:00')
  ) {
    slotTime = '13:00 - 17:00';
  } else if (preReturnAppraisalDate.includes('12:00')) {
    slotTime = '12:00 - 15:30';
  } else {
    slotTime = '08:00 - 12:00';
  }

  return `${formattedPreReturnAppraisalDate} um ${slotTime} Uhr`;
}
