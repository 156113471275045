import ModalContainer, {
  ModalProps,
} from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { rememberThatUserRequestedToBuyACar } from '@finn/b2c-cp/services/storage';
import { Deal, useCarSaleInfo, useCosmicModule } from '@finn/platform-modules';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ModalBuilder } from '../features-components/ModalBuilder';
import { useDealId } from '../features-data';
import { useCurrentDeal } from '../features-data/hooks/useCurrentDeal';

const BUY_CAR_MODAL = 'buy_car_modal';

type BuyCarModalProps = ModalProps & {
  subscription?: Deal;
  vin?: string;
  modal?: {
    title?: string;
    description?: string;
    cta?: string;
    successTitle?: string;
    successDescription?: string;
    successCTA?: string;
  };
};

const addMarkupAndVat = (basePrice: number) => {
  // formula provided in ticket UA-5299
  return Math.ceil(((basePrice * 1.03 + 1500) * 1.19) / 10) * 10;
};

export const BuyCarModal = ({ open, onClose }: BuyCarModalProps) => {
  const content = useCosmicModule('buy-car');
  const intl = useIntl();
  const modal = content?.config?.modal;
  const { data: deal } = useCurrentDeal();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setIsSubmitting] = useState(false);

  const finnCarId = deal?.car_id;
  const { data: carSaleInfo } = useCarSaleInfo(finnCarId);

  const carToSell = (carSaleInfo?.data?.data?.inventory || []).find(
    (car) => car.finn_car_id === finnCarId
  );

  const vin = carToSell?.vin;
  const salePrice =
    Number(carToSell?.aop_rv_presale_target || 0) -
    Number(carToSell?.aop_stock_rv_damage_equivalent || 0) -
    Number(carToSell?.aop_stock_rv_km_equivalent || 0);

  const salePriceWithMarkup = addMarkupAndVat(salePrice);

  const formattedSalePrice = intl.formatNumber(salePriceWithMarkup, {
    currency: 'EUR',
    style: 'currency',
    maximumFractionDigits: 0,
  });

  const handleClick = async () => {
    if (!submitted && !submitting) {
      setIsSubmitting(true);
      await fetch(
        `https://hook.finn.integromat.cloud/1zc75wd6git4es5kpo03ln0pun1iby5y?vin=${vin}&dealId=${deal?.id}&price=${salePriceWithMarkup}`
      );
      setSubmitted(true);
      setIsSubmitting(false);
      if (deal?.id) {
        rememberThatUserRequestedToBuyACar(deal?.id);
      }
    } else {
      onClose();
    }
  };

  const title = submitted ? modal?.successTitle : modal?.title;
  const description = submitted
    ? modal?.successDescription
    : salePrice
      ? [
          {
            // not sure if we can/should manage this from cosmic
            data: `<span class="block mt-2 font-semibold">Unverbindliches Kaufangebot: ${formattedSalePrice}</span>`,
          },
          { data: modal?.description },
        ]
      : modal?.description;
  const cta = submitted ? modal?.successCTA : modal?.cta;

  return (
    <ModalBuilder
      title={title}
      description={description}
      footer={{
        cta: {
          action: 'empty',
          label: cta,
          onCtaClick: handleClick,
        },
      }}
      open={open}
      onClose={onClose}
    />
  );
};

const renderBuyCarModal = (open: boolean, onClose: () => void) => {
  return <BuyCarModal open={open} onClose={onClose} />;
};

export const BuyCarModalContainer = () => {
  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer name={BUY_CAR_MODAL} renderModal={renderBuyCarModal} />
  );
};
