import { Button } from '@finn/design-system/atoms/button';
import { trackEvent } from '@finn/ui-utils';
import { useMemo } from 'react';

import { Link } from '../features-components/Link';
import { Skeleton } from '../features-components/Skeleton';
import { useDealId, useDocuments } from '../features-data';

export type DocumentsProps = {
  title?: string;
  document_cta?: string;
  document_type?: {
    [key: string]: string;
  };
};

export const Documents = ({
  title,
  document_cta,
  document_type,
}: DocumentsProps) => {
  const dealId = useDealId();

  const { status, data: documents } = useDocuments(dealId);
  const documentsWithUrls = useMemo(
    () =>
      (documents ?? [])
        .filter((document) => !!document.url)
        .map((document) => ({
          ...document,
          documentType: document.name.toLowerCase().replace('.pdf', ''),
        })),
    [documents]
  );

  if (status !== 'loading' && !documentsWithUrls?.length) {
    return null;
  }

  return (
    <div className="mt-4 grid gap-6" id="documents">
      <Skeleton loading={status === 'loading'}>
        <h3 className="global-t5-semibold md:web-t4-semibold">{title}</h3>
      </Skeleton>
      <Skeleton height="90px" fullWidth loading={status === 'loading'}>
        <div>
          {documentsWithUrls?.map((doc) => (
            <div
              key={doc.url}
              className="border-pewter flex items-center justify-between border-b py-6 last:border-white"
            >
              <p className="body-16-regular">
                {document_type?.[doc.documentType]}
              </p>
              <Link
                href={doc.url}
                onClick={() =>
                  trackEvent('Document Opened', {
                    document_type: doc.documentType,
                  })
                }
              >
                <Button
                  href={doc.url}
                  target="_blank"
                  variant="outline"
                  className="h-max px-5 py-2"
                >
                  {document_cta}
                </Button>
              </Link>
            </div>
          ))}
        </div>
      </Skeleton>
    </div>
  );
};
