import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type ModalStore = {
  modalKey: string | null;
  openModal(modalKey: string): void;
  closeModal(): void;
  getIsModalOpen(modalKey: string): boolean;
};

export const useModalStore = create<ModalStore>()(
  devtools(
    (set, get) => ({
      modalKey: null,

      openModal(modalKey) {
        set(() => ({ modalKey }), false, 'modal/openModal');
      },

      closeModal() {
        set(() => ({ modalKey: null }), false, 'modal/closeModal');
      },

      getIsModalOpen(modalKey) {
        return get().modalKey === modalKey;
      },
    }),
    { name: 'modal' }
  )
);
