import { useRemoteData } from '@finn/platform-modules';

export type Company = {
  id: string;
  properties: {
    address: string;
    city: string;
    createdate: string;
    hs_lastmodifieddate: string;
    hs_object_id: string;
    name: string;
    phone: string;
    zip: string;
  };
};

const companyEndpoint = (id: string) => () => `portal/api/bff/companies/${id}`;

export const useCompanyData = (companyId: string) =>
  useRemoteData<Company>(
    companyId ? `company/${companyId}` : null,
    companyEndpoint(companyId)
  );
