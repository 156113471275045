import { If } from '@finn/b2c-cp/features-components/IfRender';
import { useDealId, useOrderStatus } from '@finn/b2c-cp/features-data';

import { MessageBanner, MessageBannerProps } from './MessageBanner';

export const VerificationBanner = (props: MessageBannerProps) => {
  const dealId = useDealId();
  const { data: orderStep, status: orderStatus } = useOrderStatus(dealId);
  // as order_received is the step before customer_verified, we can assume that the customer is verified if the order step is not order_received
  const isCustomerVerified = orderStep !== 'order_received';

  if (orderStatus !== 'success') {
    return null;
  }

  return (
    <If condition={!isCustomerVerified}>
      <MessageBanner {...props} />
    </If>
  );
};
