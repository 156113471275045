import { HandoverMetadata } from '@finn/b2c-cp/bff/core-api/types';
import { useRemoteData } from '@finn/platform-modules';
import queryString from 'query-string';

const handoverMetadataEndpoint = (id: string, query) =>
  `portal/api/bff/deals/${id}/handover-metadata?${query}`;

export const useHandoverMetadata = (
  id: string,
  preferences?: {
    postcode: string;
  }
) => {
  const query = queryString.stringify(
    {
      customer_postcode: preferences?.postcode,
    },
    { skipNull: true }
  );

  return useRemoteData<HandoverMetadata>(
    id && id !== 'no-deal'
      ? `handover-metadata/${id}/${preferences?.postcode ?? '-'}`
      : null,
    () => handoverMetadataEndpoint(id, query)
  );
};
