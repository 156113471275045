import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { showToast } from '@finn/design-system/atoms/toast';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { parseToHtml, replaceStrKeys } from '@finn/ui-utils';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import useFormattedNumber from '../hooks/useFormattedNumber';

export type RedemptionModalData = {
  cosmicData?: LoyaltyCosmicMetadata;
  totalCoins?: number;
  coinsNeeded?: number;
  benefitTitle?: string;
  onModalClose?(): void;
  openThankYouModal?(): void;
  onModalConfirm?(): Promise<void>;
};

type Props = {
  open: boolean;
  onClose(): void;
  redemptionModalData: RedemptionModalData;
};

export const RedemptionModal = ({
  open,
  onClose,
  redemptionModalData,
}: Props) => {
  const i18n = useIntl();
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const {
    redemption_modal_title,
    redemption_modal_description,
    redemption_modal_confirm_label,
    redemption_modal_cancel_label,
  } = redemptionModalData?.cosmicData || {};

  const { totalCoins, coinsNeeded, benefitTitle } = redemptionModalData || {};
  const remainingCoins = Number(totalCoins) - Number(coinsNeeded);
  const coinsNeededFormatted = useFormattedNumber(coinsNeeded);
  const remainingCoinsFormatted = useFormattedNumber(remainingCoins);

  const onModalClose = useCallback(() => {
    redemptionModalData?.onModalClose?.();
    onClose();
  }, [redemptionModalData, onClose]);

  const onModalConfirm = useCallback(async () => {
    setConfirmationInProgress(true);
    try {
      await redemptionModalData?.onModalConfirm?.();
      redemptionModalData?.openThankYouModal?.();
    } catch (e) {
      showToast({
        status: 'error',
        description: i18n.formatMessage({
          id: 'userAccount.serverErrors.generalError',
        }),
      });
    } finally {
      onClose();
      setConfirmationInProgress(false);
    }
  }, [i18n, onClose, redemptionModalData]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose?.()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{redemption_modal_title}</ModalTitle>
        </ModalHeader>
        <div>
          {parseToHtml(
            replaceStrKeys(redemption_modal_description, {
              coinsNeeded: coinsNeededFormatted,
              benefitTitle,
              remainingCoins: remainingCoinsFormatted,
            })
          )}
        </div>
        <ModalFooter>
          <Button
            onClick={onModalConfirm}
            disabled={confirmationInProgress}
            loading={confirmationInProgress}
          >
            {redemption_modal_confirm_label}
          </Button>
          <Button
            variant="outline"
            onClick={onModalClose}
            disabled={confirmationInProgress}
          >
            {redemption_modal_cancel_label}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
