import { useCurrentDeal } from '@finn/b2c-cp/features-data';
import { parametrizeUrl } from '@finn/b2c-cp/features-data/helpers';
import { SeoHeaderData } from '@finn/ui-cosmic';
import { config, isMobileApp, replaceStrKeys } from '@finn/ui-utils';
import get from 'lodash/get';
import Head from 'next/head';
import React from 'react';

type Props = {
  seoHeader: {} | SeoHeaderData;
  canonicalUrl?: string;
  isNoIndexNoFollow?: boolean;
  tabTitle?: string;
  appDeepLink?: string;
};

const parseBoolean = (value: string) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

const isBannerAllowed = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return !window?.location?.search?.includes?.('no_banner=true');
};

const HeadTags: React.FunctionComponent<Props> = ({
  seoHeader = {},
  canonicalUrl,
  isNoIndexNoFollow,
  tabTitle,
  appDeepLink,
}) => {
  const { data: deal } = useCurrentDeal();
  const noIndexParamExist = typeof isNoIndexNoFollow !== 'undefined';
  const isNoIndex = noIndexParamExist
    ? isNoIndexNoFollow
    : parseBoolean(get(seoHeader, 'metadata.no_index', ''));

  const getDescription = () => {
    let description = get(seoHeader, 'metadata.og_description', '');
    description = replaceStrKeys(description, {
      brand: get(deal, 'car.oem', ''),
      model: get(deal, 'car.model', ''),
    });

    return description;
  };
  const appDeepLinkWithParams =
    deal && appDeepLink
      ? parametrizeUrl({ url: appDeepLink, deal })
      : appDeepLink;

  return (
    <Head>
      <meta
        property="og:image"
        content={get(seoHeader, 'metadata.og_image.url', '')}
      />
      <meta
        property="og:title"
        content={get(seoHeader, 'metadata.title', '')}
      />
      <title>
        {tabTitle ? tabTitle : get(seoHeader, 'metadata.title', '')}
      </title>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta name="description" content={getDescription()} />
      <meta name="keywords" content={get(seoHeader, 'metadata.keywords', '')} />
      <meta name="google-site-verification" content={config.GMC_TAG} />
      {isNoIndex && (
        <>
          <meta name="robots" content="noindex,follow" />
          <meta name="googlebot" content="noindex,follow" />
        </>
      )}
      <meta
        property="og:type"
        content={get(seoHeader, 'metadata.og_type', '')}
      />
      <meta property="og:url" content={get(seoHeader, 'metadata.og_url', '')} />
      <meta property="og:description" content={getDescription()} />
      <meta
        property="og:image:secure_url"
        content={get(seoHeader, 'metadata.og_image_secure_url.url', '')}
      />
      <meta
        property="og:image:url"
        content={get(seoHeader, 'metadata.og_image_url.url', '')}
      />
      <meta
        property="og:image:type"
        content={get(seoHeader, 'metadata.og_image_type', '')}
      />
      <meta
        property="og:image:width"
        content={get(seoHeader, 'metadata.og_image_width', '')}
      />
      <meta
        property="og:image:height"
        content={get(seoHeader, 'metadata.og_image_height', '')}
      />
      <meta
        property="og:site_name"
        content={get(seoHeader, 'metadata.og_site_name', '')}
      />
      {appDeepLink && !isMobileApp() && isBannerAllowed() ? (
        <script id="apps_flyer_smart_banner">
          {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${config.APPS_FLYER_SMART_BANNER_KEY}"}});
        AF('banners', 'showBanner', { additionalParams: { af_dp: '${appDeepLinkWithParams}', deep_link_value: '${appDeepLinkWithParams}' }}); `}
        </script>
      ) : null}
    </Head>
  );
};

export default HeadTags;
