import { useProlongationData } from '@finn/b2c-cp/hooks/useProlongationData';
import { Button } from '@finn/design-system/atoms/button';
import { showToast } from '@finn/design-system/atoms/toast';
import { ProlongationTerm } from '@finn/platform-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDealId } from '../features-data';

export type ProlongationTermsFormProps = {
  title: string;
  cta?: { label?: string };
  error?: string;
  downpayment_note?: string;
};

type ProlongationResponse = {
  deal: {
    id: string;
  };
  checkoutStep: string;
  contact: {
    id: string;
  };
  hash: string;
};

export const ProlongationTermsForm: React.FC<ProlongationTermsFormProps> = ({
  title,
  cta,
  error: errorText,
  downpayment_note,
}) => {
  const { locale } = useCurrentLocale();
  const [isLoading, setIsLoading] = useState(false);
  const dealId = useDealId();
  const prolongationData = useProlongationData(dealId);
  const [selectedTerm, setSelectedTerm] = useState(0);
  const prolongationOptions = useMemo(
    () =>
      [...(prolongationData?.data?.prolongation_options || [])].sort(
        (a, b) => Number(b?.term) - Number(a?.term)
      ),
    [prolongationData]
  );

  const isDownpayment = prolongationData?.data?.is_downpayment_subscription;

  useEffect(() => {
    const firstTerm = prolongationOptions[0]?.term;
    if (!selectedTerm && firstTerm) {
      setSelectedTerm(firstTerm);
    }
  }, [selectedTerm, prolongationOptions]);

  const handleContinueProlongation = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post<ProlongationResponse>(
        '/api/checkout/prolongation/create',
        {
          term: Number(selectedTerm),
          dealId,
        },
        {
          validateStatus: (status) => status === 200,
        }
      );

      if (!response?.data) {
        throw new Error('Error while creating prolongation');
      }
      // switching to UA site so router.push will first generate an error as it's external link
      // using location.href to avoid this
      window.location.href = `/${locale}/checkout/prolongation/${response?.data?.contact?.id}/${response?.data?.deal?.id}/${response?.data?.hash}`;
    } catch (error) {
      showToast({
        status: 'error',
        description: errorText,
      });
      setIsLoading(false);
    }
  }, [locale, selectedTerm, dealId, errorText]);

  return (
    <div>
      {isDownpayment && (
        <p className="body-16-regular mb-6">{downpayment_note}</p>
      )}
      <p className="mb-4 font-semibold">{title}</p>
      <div className="mb-8 flex flex-col gap-4">
        {prolongationOptions.map((option) => (
          <ProlongationTerm
            key={option?.term}
            selectedTerm={selectedTerm}
            currentTerm={option?.term}
            originalPrice={option?.original_price}
            price={option?.price}
            setSelectedTerm={setSelectedTerm}
            isDownpayment={isDownpayment}
          />
        ))}
      </div>
      <Button
        onClick={handleContinueProlongation}
        className="w-full"
        loading={isLoading}
      >
        {cta?.label}
      </Button>
    </div>
  );
};
