import { useRedirectToAuthIfNotLoggedIn } from '@finn/b2c-cp/hooks/useRedirectToAuthIfNotLoggedIn';
import { useDeals } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';

import { useCompaniesData, usePaymentMethodsData } from '../../features-data';
import {
  AccountSection,
  AccountSectionCosmicConfig,
} from './sections/AccountSection';
import {
  CompanySection,
  CompanySectionCosmicConfig,
} from './sections/CompanySection';
import {
  ContactSection,
  ContactSectionCosmicConfig,
} from './sections/ContactSection';
import {
  PaymentSection,
  type PaymentSectionCosmicConfig,
} from './sections/PaymentSection';

type UserAccountDataProps = {
  title?: string;
  contact_section?: ContactSectionCosmicConfig;
  account_section?: AccountSectionCosmicConfig;
  payment_section?: PaymentSectionCosmicConfig;
  company_section?: CompanySectionCosmicConfig;
};

const getSectionsNumber = ({
  hasPaymentMethods,
  hasCompanyData,
}: {
  hasPaymentMethods: boolean;
  hasCompanyData: boolean;
}) => {
  if (hasPaymentMethods && hasCompanyData) {
    return 5;
  } else if (hasPaymentMethods || hasCompanyData) {
    return 4;
  } else {
    return 3;
  }
};

export const UserAccountData = ({
  contact_section,
  company_section,
  account_section,
  payment_section,
}: UserAccountDataProps) => {
  const { data: companyResults } = useCompaniesData();
  const { data: paymentMethodsData } = usePaymentMethodsData();
  const hasPaymentMethods = paymentMethodsData?.length > 0;
  const { deals } = useDeals();
  const hasMiniB2BDeals = deals?.some((sub) => sub.type === 'MINIB2B') || false;
  const hasCompanyData = companyResults?.length > 0 && hasMiniB2BDeals;

  const sectionsNumber = getSectionsNumber({
    hasPaymentMethods,
    hasCompanyData,
  });

  useRedirectToAuthIfNotLoggedIn();

  return (
    <div className="w-full">
      <div className={cn('flex flex-col gap-20')}>
        <ContactSection content={contact_section} />
        <CompanySection
          content={company_section}
          className={
            sectionsNumber === 4 ? 'grid-in-section' : 'grid-in-company'
          }
        />
        <PaymentSection content={payment_section} />
        <AccountSection content={account_section} />
      </div>
    </div>
  );
};
