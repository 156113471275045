import { LoyaltyBenefitsData, LoyaltyInfo } from '@finn/platform-modules';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { IntlShape, useIntl } from 'react-intl';

import { RedeemableBenefitChip } from './RedeemableBenefitChip';
import { RedemptionModalData } from './RedemptionModal';

type Props = {
  data?: LoyaltyCosmicMetadata;
  loyaltyInfo: LoyaltyInfo | null;
  loyaltyBenefits: LoyaltyBenefitsData | null;
  openRedemptionModal: (redemptionData: RedemptionModalData) => void;
};

const getTranslation = (i18n: IntlShape, key: string | undefined) => {
  switch (key) {
    case 'mileage':
      return i18n.formatMessage({ id: 'userAccount.loyalty.mileage' });
    case 'voucher':
      return i18n.formatMessage({ id: 'userAccount.loyalty.voucher' });
    default:
      return key;
  }
};

export const LoyaltyRedeemableBenefits = ({
  data,
  loyaltyBenefits,
  openRedemptionModal,
  loyaltyInfo,
}: Props) => {
  const i18n = useIntl();
  const rewardGroups = loyaltyBenefits?.rewards;
  const pointsLabel = data?.points_label;

  return (
    <div className="mb-14 md:mb-32" data-testid="loyalty-benefit-chips">
      <h3
        className="mobile-t3-semibold md:web-t3-semibold mb-4 md:mb-8"
        data-testid="redeemable-benefits-title"
      >
        {data?.redeemable_benefits_title}
      </h3>
      <div className="flex flex-col gap-8">
        {rewardGroups?.map((group) => (
          <div key={group?.type}>
            <h5 className="global-t5-semibold mb-4 md:mb-8">
              {getTranslation(i18n, group?.type)}
            </h5>
            <div className="grid grid-flow-row grid-cols-[repeat(1,1fr)] gap-4 md:grid-cols-[repeat(2,1fr)] md:gap-6">
              {(group.options || []).map((benefit) => (
                <RedeemableBenefitChip
                  totalCoins={loyaltyInfo?.total_coins}
                  pointsLabel={pointsLabel}
                  benefit={benefit}
                  key={benefit?.name}
                  openRedemptionModal={openRedemptionModal}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
