import { Skeleton as SkeletonDS } from '@finn/design-system/atoms/skeleton';
import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

type SkeletonProps = {
  loading: boolean;
  /**
   * Be careful with what node you pass here, this can potentially trigger hydration error
   * (e.g. passing a div as placeholder where the actual children is span)
   */
  placeholder?: ReactNode;
  children: ReactNode;
  width?: string;
  height?: string;
  fullWidth?: boolean;
  numFields?: number;
  numCols?: number;
  numRows?: number;
};

const gridColsClass = {
  1: 'grid-cols-1 md:grid-cols-1',
  2: 'grid-cols-1 md:grid-cols-2',
  3: 'grid-cols-1 md:grid-cols-3',
  4: 'grid-cols-1 md:grid-cols-4',
  5: 'grid-cols-1 md:grid-cols-5',
  6: 'grid-cols-1 md:grid-cols-6',
};

export const Skeleton = ({
  fullWidth,
  width,
  height,
  loading,
  placeholder,
  children,
  numFields,
  numCols,
  numRows,
}: SkeletonProps) => {
  if (!loading) {
    return <>{children}</>;
  }

  if (numFields) {
    return (
      <>
        {Array.from({ length: numFields }).map((_, index) => (
          <SkeletonDS key={index} className="h-14 w-full rounded-sm" />
        ))}
      </>
    );
  }

  if (numCols) {
    return (
      <div className={cn('grid gap-6', gridColsClass[numCols])}>
        {Array.from({ length: numCols * (numRows ?? 1) }).map((_, index) => (
          <SkeletonDS
            key={index}
            style={{ height, width: fullWidth ? '100%' : width }}
          >
            <div className="opacity-0">{placeholder || children}</div>
          </SkeletonDS>
        ))}
      </div>
    );
  }

  return (
    <SkeletonDS style={{ height, width: fullWidth ? '100%' : width }}>
      <div className="opacity-0">{placeholder || children}</div>
    </SkeletonDS>
  );
};
