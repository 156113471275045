import { isMobileApp, useSession } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRedirectToAuthIfNotLoggedIn = () => {
  const [session, isSessionLoading] = useSession();
  const contactId = session?.user?.hs_contact_id;
  const { basePath, asPath } = useRouter();

  useEffect(() => {
    // we do nothing on mobile
    if (isMobileApp()) {
      return;
    }

    // if user not authenticated, redirect to login page and mark how to get back
    if (!contactId && !isSessionLoading) {
      window.location.href = `${window.location.origin}?login=true&next=${basePath}${asPath}`;
    }
  }, [contactId, basePath, asPath, isSessionLoading]);
};
