import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { MessageBanner } from '@finn/b2c-cp/features-components/banners/MessageBanner';
import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { useFormContext } from 'react-hook-form';

export const ContactFormFields = ({ content }) => {
  const methods = useFormContext();
  const returnType = methods ? methods.getValues('return_type') : null;

  return (
    <div className="mt-8">
      <div className="mb-4">
        <FeatureFormField
          type="text"
          name="firstname"
          label={content?.contact?.firstname?.label}
          disabled
        />
      </div>

      <div className="mb-4">
        <FeatureFormField
          type="text"
          name="lastname"
          label={content?.contact?.lastname?.label}
          disabled
        />
      </div>

      <div className="mb-8">
        <FeatureFormField
          type="text"
          name="phone_number"
          label={content?.contact?.phone_number?.label}
        />
      </div>

      {returnType === 'PICKUP' && (
        <MessageBanner
          title={content?.contact?.power_of_attorney?.title}
          description={
            <TextBlock
              fontSize={14}
              text={content?.contact?.power_of_attorney?.description}
            />
          }
        />
      )}
    </div>
  );
};
