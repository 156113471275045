import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { usePreReturnAppraisal } from '@finn/b2c-cp/features-data/hooks/usePreReturnAppraisal';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { useProlongationData } from '@finn/b2c-cp/hooks/useProlongationData';
import {
  FieldPath,
  getDataStatus,
  useCarSaleInfo,
  useDeal,
} from '@finn/platform-modules';
import platformCacheJSON from '@finn/platform-modules/cache/cache.json';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCompaniesData } from '../useCompaniesData';
import { useCompanyData } from '../useCompanyData';
import { useHandoverMetadata } from '../useHandoverMetadata';
import { useProfileData } from '../useProfileData';
import { useReturnMetadata } from '../useReturnMetadata';
import { useCustomResource } from './useCustomResource';

// this is helper that receives a string or array of strings
// and detects what resources are needed to get the value
// for example ['{deal.handover_street}'] -> we return back deal in resources
export const pathToResources = (pathToValue: FieldPath) => {
  const paths = Array.isArray(pathToValue) ? pathToValue : [pathToValue];

  const resources = paths.reduce(
    (res, path) => {
      const pattern = /\{.*?\}/g;
      const matches = path?.match?.(pattern);

      matches?.forEach((match) => {
        if (match === '{handover_address}') {
          res.deal = true;
        }
        if (match?.includes('min_date') || match?.includes('max_date')) {
          const extra = pathToResources(
            match?.replace('min_date.', '').replace('max_date.', '')
          ) || { resources: {} };
          Object.assign(res, extra?.resources);
        } else {
          res[match?.replace?.('{', '').replace('}', '').split('.')?.[0]] =
            true;
        }
      });

      return res;
    },
    {} as { [key: string]: boolean }
  );

  return { resources, paths };
};

// This magic hook accepts map of required resources and deal id
// after it conditionally loaded resources that we may need for feature
export const useDataForFeature = (
  dealId: string | null,
  resources: { [key: string]: boolean }
) => {
  const { data: returnData, status: returnDataStatus } = useReturn(
    resources.new_return_data || resources.return_data ? dealId : null
  );
  const { data: preReturnAppraisal, status: preReturnAppraisalStatus } =
    usePreReturnAppraisal(resources.pre_return_appraisal ? dealId : null);
  const { data: handoverData, status: handoverDataStatus } = useHandover(
    resources.handover || resources.handover_address ? dealId : null
  );
  const { data: returnMetadata, status: returnMetadataStatus } =
    useReturnMetadata(resources.return_metadata ? dealId : null);
  const { data: handoverMetadata, status: handoverMetadataStatus } =
    useHandoverMetadata(resources.handover_metadata ? dealId : null);
  const { data: deal, status: dealStatus } = useDeal(
    resources.deal ? dealId : null
  );

  const { data: profileData, status: profileStatus } = useProfileData(
    resources.profile ? { shouldFetch: true } : { shouldFetch: false }
  );

  const { data: companyResults } = useCompaniesData(
    resources.company ? { shouldFetch: true } : { shouldFetch: false }
  );
  const hasAssociatedCompany = companyResults && companyResults.length > 0;
  const companyId = hasAssociatedCompany ? companyResults[0].id : undefined;
  const { data: companyData, status: companyStatus } = useCompanyData(
    resources.company ? companyId : null
  );

  const customResourcesToFetch = Object.keys(resources).filter(
    (key) =>
      resources[key] &&
      platformCacheJSON?.resources?.find((item) => item?.slug === key)
  );

  const { data: customResourcesData, status: customResourcesStatus } =
    useCustomResource(
      customResourcesToFetch.map(
        (key) =>
          platformCacheJSON?.resources?.find((item) => item?.slug === key)?.url
      )
    );
  const customResources = customResourcesToFetch?.reduce(
    (acc, key, index) => ({ ...acc, [key]: customResourcesData?.[index] }),
    {}
  );

  // TODO migrate useProlongationData on new infra
  const { data: prolongationData, isLoading } = useProlongationData(
    resources.prolongation ? dealId : null
  );

  const carId = resources.car_inventory && deal?.car_id;
  const { data: carInventoryData } = useCarSaleInfo(carId);

  const prolongationDataStatus = isLoading
    ? ('loading' as const)
    : ('success' as const);
  const status = getDataStatus([
    returnDataStatus,
    handoverMetadataStatus,
    handoverDataStatus,
    dealStatus,
    prolongationDataStatus,
    returnMetadataStatus,
    customResourcesStatus,
    profileStatus,
    companyStatus,
    preReturnAppraisalStatus,
  ]);

  const formMethods = useFormContext();

  return useMemo(
    () => ({
      status,
      ...customResources,
      formData: formMethods?.watch?.(),
      returnData,
      preReturnAppraisal,
      handoverMetadata,
      handoverData,
      returnMetadata,
      deal,
      prolongation: prolongationData,
      prolongationData,
      carInventoryData,
      profile: profileData?.properties,
      company: companyData?.properties,
    }),
    [
      status,
      customResources,
      formMethods,
      returnData,
      preReturnAppraisal,
      handoverMetadata,
      handoverData,
      returnMetadata,
      deal,
      prolongationData,
      carInventoryData,
      profileData?.properties,
      companyData?.properties,
    ]
  );
};
