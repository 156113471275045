import { VisibilityConfig } from '@finn/platform-modules';

import { FeatureFormField } from '../features-components/FeatureForm';
import { Field } from '../features-components/FeatureForm/types';
import { useIsFeatureVisible } from '../features-data';

type FieldsProps = {
  fields: Field[];
  visibilityConfig?: VisibilityConfig;
};

export const Fields = ({ fields, visibilityConfig }: FieldsProps) => {
  const isFieldsVisible = useIsFeatureVisible(visibilityConfig);

  if (!isFieldsVisible) {
    return null;
  }

  return (
    <>
      {fields?.map((field) => <FeatureFormField key={field.name} {...field} />)}
    </>
  );
};
