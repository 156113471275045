export const UNSUPPORTED_GERMAN_ZIP_CODES_FOR_DELIVERIES = [
  '18565',
  '25845',
  '25846',
  '25847',
  '25849',
  '25859',
  '25863',
  '25869',
  '25929',
  '25930',
  '29531',
  '25932',
  '25933',
  '25938',
  '25939',
  '25940',
  '25941',
  '25942',
  '25946',
  '25947',
  '25948',
  '25949',
  '25952',
  '25953',
  '25954',
  '25955',
  '25961',
  '25962',
  '25963',
  '25964',
  '25965',
  '25966',
  '25967',
  '25968',
  '25969',
  '25970',
  '25980',
  '25985',
  '25986',
  '25988',
  '25989',
  '25990',
  '25992',
  '25993',
  '25994',
  '25996',
  '25997',
  '25998',
  '25999',
  '26465',
  '26474',
  '26486',
  '26548',
  '26571',
  '26579',
  '26757',
  '27498',
  '83256',
];
