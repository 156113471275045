import { ReturnMetadata } from '@finn/b2c-cp/bff/core-api/types';
import { useRemoteData } from '@finn/platform-modules';
import queryString from 'query-string';

const returnMetadataEndpoint = (id: string, query: string) =>
  `portal/api/bff/deals/${id}/return-metadata?${query}`;

export const useReturnMetadata = (
  id: string,
  preferences?: {
    postcode: string | null;
    returnType: string | null;
  }
) => {
  const query = queryString.stringify(
    {
      customer_postcode: preferences?.postcode,
      return_type: preferences?.returnType,
    },
    { skipNull: true }
  );

  return useRemoteData<ReturnMetadata>(
    id && id !== 'no-deal' ? `return-metadata/${id}/${query}` : null,
    () => returnMetadataEndpoint(id, query)
  );
};
