import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { useLink } from '@finn/b2c-cp/features-components/Link';
import { showToast } from '@finn/design-system/atoms/toast';
import { Action } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import { parametrizeUrl } from '../helpers';
import { useCurrentDeal } from './useCurrentDeal';
import { useValue } from './useValue';

type FeatureActionConfig = {
  action: Action;
  modalName?: string;
  status?: 'error' | 'success' | 'warning';
  description?: string;
  title?: string;
  url?: string;
  openUrlInNewTab?: boolean;
  // describes place where action was triggered
  // for example 'technical support' or 'report damage'
  location?: string;
  // step represents the step in wizard on which action was triggered
  step?: string | number;
  // resources that should be reloaded after action
  resources_to_reload?: string[];
  trackingProps?: {
    eventName?: string;
    [key: string]: string | { field: string };
  };
};

export const trackFeatureAction = (
  config?: FeatureActionConfig,
  extraProps?: { [key: string]: string }
) => {
  const trackingProps = config?.trackingProps || {};

  if (trackingProps?.eventName) {
    return trackEvent(trackingProps.eventName, omit(extraProps, 'eventName'));
  }

  switch (config?.action) {
    case 'open_modal':
    case 'next-step':
    case 'submit':
    case 'prev-step':
    case 'open_url':
    case 'open_chat':
    case 'reload': {
      return trackEvent('CTA Clicked', {
        action: config.action,
        location: config.location,
        wizardStep: config.step !== undefined ? `step-${config.step}` : '',
        link: config.url,
        modalName: config.modalName,
      });
    }
    case 'empty': {
      return;
    }
    default: {
      return trackEvent(`Custom Action`, config);
    }
  }
};

export const useFeatureAction = (config?: FeatureActionConfig) => {
  const { mutate } = useSWRConfig();
  const { openModal, closeModal } = useModalStore();
  const { data: deal } = useCurrentDeal();
  const openUrl = useLink();

  const [urlToOpen] = useValue(config?.url ?? '');
  const trackingPropsArr = Object.entries(config?.trackingProps ?? {}).map(
    ([key, value]) => ({ property: key, value })
  );
  const [values] = useValue({
    paths: trackingPropsArr.map((item) => {
      if (typeof item.value === 'object' && 'field' in item.value) {
        return item.value.field;
      }

      return '';
    }),
  });

  return useCallback(() => {
    if (!config?.action) {
      return;
    }

    switch (config?.action) {
      case 'open_modal':
        if (config.modalName) {
          // TODO delete it when edit_handover_delivery modal
          // migrate in cosmic in non-breaking way
          if (config.modalName === 'edit_handover_delivery') {
            openModal('MULTISTEP_EDIT_HANDOVER');
          } else if (config.modalName === 'edit_return_delivery') {
            openModal('MULTISTEP_EDIT_RETURN');
          } else {
            openModal(config.modalName);
          }
        }
        break;
      case 'close_modal':
        closeModal();
        break;
      case 'open_url':
        if (config.url) {
          openUrl(urlToOpen, config.openUrlInNewTab ?? false);
        }
        break;
      case 'reload':
        window?.location?.reload?.();
        break;
      case 'show_toast':
        // some TS tricks
        // TODO remove it after conditional types introduced
        if (config.status && config.description) {
          showToast({
            ...config,
            status: config.status,
            description: config.description,
          });
        }
        break;
      default:
        break;
    }

    const trackingEventProperties = trackingPropsArr.reduce(
      (acc, { property, value }, index) => {
        if (typeof value === 'object' && 'field' in value) {
          return {
            ...acc,
            [property]: values[index],
          };
        }

        return {
          ...acc,
          [property]: value,
        };
      },
      {}
    );

    trackFeatureAction(config, trackingEventProperties);

    if (config?.resources_to_reload?.length) {
      // this is weard hack, as for useDeal we often use cache
      // from previous all deals request(useDeals), so we need to re-fetch it too
      // to ensure that desired deal updated
      config.resources_to_reload.map(async (key) => {
        if (key === 'deal/{subscription_id}') {
          config.resources_to_reload.unshift('deals');
        }

        return key;
      });
      // now we go via each resource and invalidate it
      config.resources_to_reload.forEach((key) =>
        mutate(parametrizeUrl({ url: key, deal }))
      );
    }
  }, [
    config,
    trackingPropsArr,
    closeModal,
    openModal,
    openUrl,
    urlToOpen,
    values,
    mutate,
    deal,
  ]);
};
