import {
  ActionBanner,
  ActionBannerProps,
} from '../features-components/banners/ActionBanner';
import { DelayBanner } from '../features-components/banners/DelayBanner';
import {
  MessageBanner,
  MessageBannerProps,
} from '../features-components/banners/MessageBanner';
import {
  SwapBanner,
  SwapBannerProps,
} from '../features-components/banners/SwapBanner';
import { VerificationBanner } from '../features-components/banners/VerificationBanner';
import { OrderStatusProps } from './OrderStatus';

export type BannerType =
  | ({ type?: 'message' } & MessageBannerProps)
  | ({ type: 'delay' } & MessageBannerProps)
  | ({ type: 'verification' } & MessageBannerProps)
  | ({ type: 'action' } & ActionBannerProps)
  | ({ type: 'swap' } & SwapBannerProps)
  | ({ type: 'order-status-block' } & OrderStatusProps);

type BannersProps = {
  banners: BannerType[];
};

export const Banners = ({ banners }: BannersProps) => {
  return (
    <>
      {banners?.map((banner) => {
        // we use ifs instead of switch/map to avoid typescript errors
        // with conditional types
        if (banner.type === 'delay') {
          return <DelayBanner {...banner} key={banner.title} />;
        }
        if (banner.type === 'verification') {
          return <VerificationBanner {...banner} key={banner.title} />;
        }
        if (banner.type === 'action') {
          return <ActionBanner {...banner} key={banner.title} />;
        }
        // TODO move order status block from banners to modules after PR
        // that uses order status as module merged
        if (banner.type === 'order-status-block') {
          return null;
        }
        if (banner.type === 'swap') {
          return <SwapBanner {...banner} key={banner.title} />;
        }

        return <MessageBanner key={banner?.title} {...banner} />;
      })}
    </>
  );
};
