import { daysFromNowToDate } from '@finn/b2c-cp/features-data/helpers';
import { useScheduledReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { Deal } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';
import React from 'react';
import { useIntl } from 'react-intl';

import { useHandoverDeliveryDaysLabel } from '../hooks/useHandoverDeliveryDaysLabel';

interface IProps {
  deal: Deal;
}

const useReturnLabel = (deal: Deal) => {
  const scheduledReturn = useScheduledReturn(deal);
  const daysLeftForReturn = daysFromNowToDate(
    scheduledReturn?.planned_delivery_date ??
      scheduledReturn?.preferred_delivery_date
  );
  const isDealInactive = ['INACTIVE', 'ENDED'].includes(deal?.state);
  const isReturnScheduled =
    !!scheduledReturn?.type && !isDealInactive && daysLeftForReturn > 0;

  const daysLeftForEnd = daysFromNowToDate(deal?.end_date);
  const isReturnWindowOpenButNotScheduled =
    deal?.is_return_window_open &&
    !!scheduledReturn &&
    !scheduledReturn.type &&
    !isDealInactive &&
    daysLeftForEnd > 0;

  return {
    isReturnScheduled,
    daysLeftForReturn,
    isReturnWindowOpenButNotScheduled,
    daysLeftForEnd,
  };
};

const ActionItem: React.FunctionComponent<IProps> = ({ deal }) => {
  const i18n = useIntl();

  const handoverLabel = useHandoverDeliveryDaysLabel(deal);

  let displayText: string;
  const displayTextId = `mycars.action_texts.${deal?.state}`;

  const {
    isReturnScheduled,
    isReturnWindowOpenButNotScheduled,
    daysLeftForEnd,
    daysLeftForReturn,
  } = useReturnLabel(deal);

  // TODO: not forget to test all cases
  if (handoverLabel.label) {
    displayText = handoverLabel.label;
  } else if (isReturnWindowOpenButNotScheduled) {
    displayText = i18n.formatMessage(
      {
        id: 'mycars.labels.daysRemaining',
      },
      { days: daysLeftForEnd }
    );
  } else if (isReturnScheduled) {
    displayText = i18n.formatMessage(
      {
        id: 'mycars.labels.returnDaysRemaining',
      },
      { days: daysLeftForReturn }
    );
  } else if (deal?.return_type) {
    const returnDate = i18n.formatDate(
      deal?.return_date || deal?.preferred_return_date || deal?.end_date,
      {
        day: 'numeric',
        month: 'long',
      }
    );
    displayText = i18n.formatMessage({ id: displayTextId }, { returnDate });
  } else {
    displayText = i18n.formatMessage({ id: displayTextId });
  }

  return (
    <h6
      className={cn('body-14-semibold', {
        'text-orange':
          deal?.variant === 'LEAD' ||
          deal?.state === 'CREATED' ||
          isReturnScheduled,
        'text-green': deal?.state === 'ACTIVE' && !isReturnScheduled,
        'text-iron': deal?.state === 'INACTIVE' || deal?.state === 'ENDED',
      })}
    >
      {displayText}
    </h6>
  );
};

export default ActionItem;
