import { isSameDaySwapReturn } from '@finn/b2c-cp/bff/bff-helpers';
import { UpdateReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { Divider } from '@finn/b2c-cp/features/Divider';
import { useReturnChangeFee } from '@finn/b2c-cp/features/EditReturn/hooks/useReturnChangeFee';
import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import {
  daysFromNowToDate,
  useReturnMetadata,
} from '@finn/b2c-cp/features-data';
import { usePreReturnAppraisal } from '@finn/b2c-cp/features-data/hooks/usePreReturnAppraisal';
import { CompoundLocation } from '@finn/b2c-cp/features-data/hooks/useReturnData';
import { formatAddress } from '@finn/b2c-cp/services/format-address';
import { Deal, useCosmicModule } from '@finn/platform-modules';
import { CountryCode, trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

function formatDisplayAddress(formData, compoundLocations: CompoundLocation[]) {
  if (formData.return_type === 'SELF_RETURN') {
    const compoundLocation = compoundLocations?.[0];
    const compoundAddress = formatAddress(compoundLocation);

    return `${String(compoundLocation?.name)}, ${compoundAddress}`;
  }

  return formatAddress({
    street: formData.street,
    house_number: formData.housenumber,
    zip: formData.postcode,
    city: formData.city,
  });
}

export function constructReturnRequest(formData): UpdateReturnData {
  const returnType = formData.return_type;

  if (returnType === 'SELF_RETURN') {
    return {
      return_type: returnType,
      preferred_return_date: formData.slot,
    };
  } else {
    return {
      return_firstname: formData.firstname,
      return_lastname: formData.lastname,
      return_street: formData.street,
      return_housenumber: formData.housenumber,
      return_city: formData.city,
      return_zip: formData.postcode,
      return_address_extra: formData.address_suffix,
      return_type: returnType,
      return_phone_number: formData.phone_number,
      preferred_return_date: formData.slot,
    };
  }
}

const OverviewStep = ({
  deal,
  setCanSubmit,
  compoundLocations,
}: {
  deal: Deal;
  setCanSubmit: (canSubmit: boolean) => void;
  compoundLocations: CompoundLocation[];
}) => {
  const content = useCosmicModule('advanced-edit-return');
  const methods = useFormContext();
  const { locale, region } = useCurrentLocale();
  const formData = methods.getValues();
  const { formatNumber } = useIntl();
  const returnTypePreference = methods
    ? methods.getValues('return_type')
    : null;

  const { selfReturn, pickupReturn } =
    content?.location?.returnPreferences ?? {};

  const returnTypeDisplay =
    returnTypePreference === 'SELF_RETURN'
      ? selfReturn?.label
      : pickupReturn?.label;

  const returnFeeText =
    returnTypePreference === 'SELF_RETURN'
      ? selfReturn?.label
      : content?.overview?.costs?.return_fee;

  const postcode = useWatch({ name: 'postcode' });
  const selectedSlot = useWatch({ name: 'slot' });
  const returnType = useWatch({ name: 'return_type' });

  const {
    data: { availabilities: returnAvailabilities, return_fee: returnFee },
  } = useReturnMetadata(deal?.id, {
    postcode,
    returnType,
  });

  const slots = returnAvailabilities
    ?.map((item) => item.available_slots)
    .flat();
  const slotLabel = slots?.find(
    (slot) => slot.value === formData.slot
  )?.formatted_time;

  const addressDisplay = formatDisplayAddress(formData, compoundLocations);

  const appointmentDisplay = `${new Intl.DateTimeFormat(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(
    formData.appointment_preference
  )} ${content?.overview?.address?.at} ${String(slotLabel)}`;

  const lateChangeFeeRequest = constructReturnRequest(formData);
  const { data: changeFee, isLoading: feeDataIsLoading } = useReturnChangeFee(
    deal?.id,
    lateChangeFeeRequest
  );

  const isSwap = isSameDaySwapReturn(deal);

  const { data: preReturnAppraisal } = usePreReturnAppraisal(deal?.id, {
    // if no return has been scheduled yet, the API uses this field as a proxy for
    // what will be the planned return date after scheduling
    selected_date: selectedSlot,
  });

  const canBookPreReturnAppraisal =
    !preReturnAppraisal?.appointment && !!preReturnAppraisal?.options;

  useEffect(() => {
    setCanSubmit(!feeDataIsLoading);
  }, [feeDataIsLoading, setCanSubmit]);

  useEffect(() => {
    const currentChangeFee = methods.getValues('change_fee');
    if (currentChangeFee !== returnFee) {
      methods.setValue('change_fee', returnFee);
    }

    if (!feeDataIsLoading) {
      trackEvent('Edit Return Overview Shown', {
        daysUntilReturn: daysFromNowToDate(deal?.preferred_return_date),
        returnFee,
        changeFeedisplayAmount: changeFee,
      });
    }
  }, [feeDataIsLoading, deal, returnFee, changeFee, methods]);

  return (
    <div className="mt-6">
      <h6 className="global-t6-semibold">
        {content?.overview?.address?.title}
      </h6>
      <TextBlock text={returnTypeDisplay} className="mt-6 flex flex-row" />
      <TextBlock text={addressDisplay} className="" />
      <TextBlock text={String(formData.address_suffix)} />
      <TextBlock text={appointmentDisplay} className="mt-6 flex flex-row" />
      <Divider className="my-6" />
      <h6 className="global-t6-semibold">
        {content?.overview?.contact?.title}
      </h6>
      <TextBlock
        className="mt-6"
        text={`${String(formData.firstname)} ${String(formData.lastname)}`}
      />
      <TextBlock text={String(formData.phone_number)} />

      <Divider className="my-6" />
      <h6 className="global-t6-semibold">{content?.overview?.costs?.title}</h6>
      <div className="mt-6 flex flex-row justify-between">
        <TextBlock text={returnFeeText} />
        <TextBlock
          text={formatNumber(returnFee, {
            currency: region === CountryCode.US ? 'USD' : 'EUR',
            style: 'currency',
          })}
        />
      </div>

      <>
        <Skeleton loading={feeDataIsLoading} width="100%">
          <div className="mt-1 flex flex-row justify-between">
            <TextBlock
              text={content?.overview?.costs?.short_term_changes_fee}
            />
            <TextBlock
              text={formatNumber(changeFee, {
                currency: region === CountryCode.US ? 'USD' : 'EUR',
                style: 'currency',
              })}
            />
          </div>
          <div className="mt-1 flex flex-row justify-between">
            <TextBlock text={content?.overview?.costs?.total} />
            <TextBlock
              className="font-bold"
              text={`${formatNumber(changeFee + returnFee, {
                currency: region === CountryCode.US ? 'USD' : 'EUR',
                style: 'currency',
              })}</strong>`}
            />
          </div>
        </Skeleton>
        <TextBlock
          className="mt-4 text-xs"
          text={content?.overview?.costs?.fees_on_different_invoices_disclaimer}
        />
      </>

      {isSwap && returnType === 'PICKUP' ? (
        <>
          <Divider className="my-6" />
          <TextBlock text={content?.overview?.swap_changes_disclaimer} />
        </>
      ) : null}

      {canBookPreReturnAppraisal ? (
        <div className="mt-8">
          <Divider className="my-6" />
          <FeatureFormField
            type="boolean"
            name="proceed_to_pre_return_appraisal_booking"
            label={
              content?.overview
                ?.proceed_to_pre_return_appraisal_booking_checkbox
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export const overviewStep = ({
  deal,
  title,
  cta,
  canSubmit,
  setCanSubmit,
  compoundLocations,
}: {
  deal: Deal;
  title?: string;
  cta?: string;
  setCanSubmit?: (canSubmit: boolean) => void;
  canSubmit?: boolean;
  compoundLocations: CompoundLocation[];
}) => {
  const overviewTitle = title || 'Zusammenfassung';

  return prepareWizardStep({
    title: overviewTitle,
    render: () => (
      <OverviewStep
        deal={deal}
        setCanSubmit={setCanSubmit}
        compoundLocations={compoundLocations}
      />
    ),
    isCTADisabled: () => !canSubmit,
    footer: {
      cta: { action: 'submit', label: cta },
    },
    fields: [
      {
        name: 'proceed_to_pre_return_appraisal_booking',
        type: 'checkbox',
        defaultValue: true,
      },
    ],
  });
};
