import ProductSlider from '@finn/b2c-cp/deprecated/modules/ProductSlider';
import { useDeals } from '@finn/platform-modules';
import { PlatformCosmicModule } from '@finn/ui-cosmic';
import { cn, config } from '@finn/ui-utils';

import { Skeleton } from '../../features-components/Skeleton';
import { Banners } from '../Banners';
import { toBannersFormat } from '../SubscriptionAction';
import { SubscriptionItem } from './components/SubscriptionItem';
import { ProductSliderCosmicMetadata } from './types';

export type SubscriptionsListProps = {
  title: string;
  banners?: PlatformCosmicModule[];
  nosubscriptions?: {
    metadata?: ProductSliderCosmicMetadata;
  };
  no_active_subscriptions?: {
    metadata?: ProductSliderCosmicMetadata;
  };
  return_reminder_modal?: {
    metadata?: { [key: string]: string };
  };
  return_and_handover?: {
    [key: string]: string;
  };
  subscription_card_cta?: string;
};

export const SubscriptionsList = ({
  title,
  banners,
  nosubscriptions,
  no_active_subscriptions,
  subscription_card_cta,
}: SubscriptionsListProps) => {
  const { deals, dealsStatus, activeDeals } = useDeals();

  const isEmpty =
    (dealsStatus === 'success' || dealsStatus === 'error') &&
    deals.length === 0;
  const hasOnlyInactiveDeals =
    dealsStatus === 'success' && !isEmpty && activeDeals.length === 0;
  const shouldShowEmptyList = isEmpty || hasOnlyInactiveDeals;

  const emptyListMetadata = isEmpty
    ? nosubscriptions?.metadata
    : no_active_subscriptions?.metadata;

  return (
    <div
      className={cn(
        'container',
        'grid w-full auto-rows-min gap-y-8 md:gap-y-10'
      )}
    >
      {shouldShowEmptyList ? (
        <ProductSlider
          withinContainer
          baseURL={
            typeof window !== 'undefined'
              ? window.location.origin
              : config.DEPLOY_URL
          }
          title={emptyListMetadata?.title}
          cta={emptyListMetadata?.cta}
        />
      ) : (
        <h3 className="mobile-t3-semibold md:web-t3-semibold">{title}</h3>
      )}
      <Banners banners={toBannersFormat(banners)} />
      <Skeleton
        fullWidth
        placeholder={<div className="h-96" />}
        loading={dealsStatus === 'loading'}
        numCols={2}
      >
        <div className="container">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-6">
            {deals?.map((lead) => (
              <SubscriptionItem
                key={lead.id}
                deal={lead}
                subscription_card_cta={subscription_card_cta}
              />
            ))}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};
