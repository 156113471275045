import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { getDefaultRichTextElements } from '@finn/ui-utils';
import { ReactNode } from 'react';

import { useModalStore } from '../deprecated/modules/modals/store';

const OpenReturnModalTag = (chunks: ReactNode[]) => {
  const { openModal } = useModalStore();
  const track = useTrackingStore((state) => state.track);

  return (
    <span
      onClick={() => {
        track(TrackingEventName.CTA_CLICKED, { location: 'Open Return Modal' });
        openModal('MULTISTEP_EDIT_RETURN');
      }}
      className="body-14-semibold inline-block cursor-pointer underline"
    >
      {chunks}
    </span>
  );
};
export const getCPRichTextElements = () => {
  return {
    ...getDefaultRichTextElements(),
    OpenReturnModal: OpenReturnModalTag,
  };
};
