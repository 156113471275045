import { InvoiceResponseItem } from '@finn/b2c-cp/pages/api/bff/invoices';
import { useRemoteData } from '@finn/platform-modules';

export type Invoice = InvoiceResponseItem;

export type InvoicesData = Invoice[];

const invoicesEndpoint = () => `portal/api/bff/invoices`;

export const useInvoicesData = (
  { shouldFetch }: { shouldFetch?: boolean } = { shouldFetch: true }
) =>
  useRemoteData<InvoicesData>(
    shouldFetch ? 'invoices' : null,
    invoicesEndpoint
  );
