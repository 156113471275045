import { cn, isMobileApp, parseToHtml } from '@finn/ui-utils';

export type PageInfoBlockProps = {
  title: string;
  description?: string;
  className?: string;
};

export const PageInfoBlock = ({
  title,
  description,
  className,
}: PageInfoBlockProps) => {
  return (
    <div className={cn(className, 'my-14')}>
      {!isMobileApp() ? (
        <h2 className="mobile-t2-semibold md:web-t2-semibold mb-4">{title}</h2>
      ) : null}
      <div className="body-16-regular">{parseToHtml(description)}</div>
    </div>
  );
};
