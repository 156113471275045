import { If } from '@finn/b2c-cp/features-components/IfRender';
import { Deal } from '@finn/platform-modules';
import { ReactNode } from 'react';

import { Row } from '../../DataRow';
import { SwapBannerProps } from './SwapBanner';

const getCarLabel = (deal: Deal) =>
  `${deal?.car?.oem} ${
    deal?.car?.model
  }${deal?.car?.license_plate ? ` - ${deal?.car?.license_plate}` : ''}`;

type SwapDataSectionProps = {
  title: string;
  hideRows?: boolean;
  children?: ReactNode;
  address: string;
  date: string;
  time?: string;
  deal: Deal;
  labelsMap: SwapBannerProps['labelsMap'];
};

export const SwapDataSection = ({
  title,
  hideRows,
  deal,
  date,
  time,
  address,
  children,
  labelsMap,
}: SwapDataSectionProps) => {
  return (
    <section className="bg-snow h-full w-full max-w-full rounded p-8">
      <h4 className="mobile-t4-semibold md:web-t4-semibold">{title}</h4>
      <If condition={!hideRows}>
        <Row
          variant="table"
          className="mt-4"
          label={labelsMap.car_label}
          type="text"
          value={getCarLabel(deal)}
        />
        <Row
          variant="table"
          label={labelsMap.date_label}
          type="date"
          value={date}
        />
        {time ? (
          <Row
            variant="table"
            label={labelsMap.time_label}
            type="text"
            value={time}
          />
        ) : null}
        <Row
          variant="table"
          label={labelsMap.address_label}
          type="text"
          value={address}
        />
      </If>
      {children}
    </section>
  );
};
