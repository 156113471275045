import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { config, useCurrentLocale } from '@finn/ui-utils';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { ModalProps } from '../modals/components/ModalContainer';
import DevModalSwitch from './DevModalSwitch';
import useActivateTestStripe from './hooks/useActivateTestStripe';
import useCosmicHelperActivation from './hooks/useCosmicHelperActivation';
import useCosmicS3 from './hooks/useCosmicS3';
import useDisableDirectCheckout from './hooks/useDisableDirectCheckout';

type VariantsType = { [key: string]: string };

const DevModal: React.FC<ModalProps> = ({ open, onClose }) => {
  const { isCosmicHelperActive, toggleCosmicHelper } =
    useCosmicHelperActivation();
  const { isTestStripeEnabled, toggleTestStripe } = useActivateTestStripe();
  const { isCosmicS3, toggleCosmicS3 } = useCosmicS3();
  const { isDirectCheckoutDisabled, toggleDirectCheckoutDisabling } =
    useDisableDirectCheckout();

  const [currentVariants, setCurrentVariants] = useState<
    Record<string, string> | undefined
  >();

  const { locale } = useCurrentLocale();
  const featureFlags = {};
  const flagList = Object.keys(featureFlags);
  useEffect(() => {
    const val = {};
    flagList.forEach((name) => {
      val[name] = Cookies.get(`${locale}_${name}`);
    });
    setCurrentVariants(val);
    // Disabling the following lint rule is usually an indicator of a bug. I
    // don't know this well enough to change the code, though, so let's disable
    // it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchExperiment = (name: string) => (variant: string) => {
    Cookies.set(`${locale}_${name}`, variant);
  };

  return (
    <Modal
      open={open}
      variant="large"
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
          // if we close with esc, that esc also prevents the reloading. Adding a delay works
          window.setTimeout(() => {
            window.location.reload();
          }, 250);
        }
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Dev Controls</ModalTitle>
        </ModalHeader>
        <div className="flex flex-col gap-8">
          <DevModalSwitch
            text="Content Helper"
            hotkey="Control+1"
            isActive={isCosmicHelperActive}
            onClick={toggleCosmicHelper}
          />
          <DevModalSwitch
            text="Test Stripe on Prod"
            hotkey="Control+2"
            helperText="Control+2: non-prod ENVs always use test stripe"
            isActive={isTestStripeEnabled}
            onClick={toggleTestStripe}
          />
          <DevModalSwitch
            text="Disable Direct Checkout"
            hotkey="Control+3"
            isActive={isDirectCheckoutDisabled}
            onClick={toggleDirectCheckoutDisabling}
          />
          {config.APP_STAGE === 'development' ? (
            <DevModalSwitch
              text="Use Cosmic Directly From S3 Backup"
              helperText="To toggle, Set COSMIC_USE_S3=yes (or no) in your .env variable"
              hotkey="Control+Shift+Option+9"
              isActive={isCosmicS3}
              onClick={toggleCosmicS3}
            />
          ) : null}

          {currentVariants && (
            <Formik<VariantsType>
              initialValues={currentVariants}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onSubmit={() => {}}
            >
              {() => (
                <Form>
                  <h5 className="global-t5-semibold my-6">Experiments</h5>
                  {flagList.map((name) => (
                    <div
                      className="mb-2 flex items-center justify-between"
                      key={name}
                    >
                      {name}
                      <Select
                        name={name}
                        onValueChange={switchExperiment(name)}
                      >
                        <SelectTrigger className="max-w-sm">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {featureFlags[name]?.map((variant) => (
                            <SelectItem key={variant} value={variant}>
                              {variant}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  ))}
                </Form>
              )}
            </Formik>
          )}
        </div>
        <ModalFooter>
          <Button
            className="w-full"
            onClick={() => {
              onClose();
              window.location.reload();
            }}
          >
            Close & Reload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default DevModal;
