import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';

const selfServiceMasks = [
  'portal',
  'mycars',
  'myaccount',
  'return',
  'report-damage',
  'schedule-service',
];

const isInsideSelfService = (href: string) =>
  selfServiceMasks.some((mask) => href.includes(mask));

type LinkProps = {
  href: string;
  newTab?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const useLink = () => {
  const router = useRouter();

  return useCallback(
    (href: string, newTab?: boolean) => {
      if (isInsideSelfService(href) && !href?.includes('http')) {
        router.push(
          `${router?.asPath}${href?.startsWith('/') ? href : `/${href}`}`
        );
      } else {
        window.open(href, newTab ? '_blank' : '_self');
      }
    },
    [router]
  );
};

// We need to use <a> tag instead of next/link because we need to open external link, as UA run on seprate domain
export const Link = ({
  href,
  newTab = true,
  children,
  className,
  onClick,
}: LinkProps) => {
  const target = newTab ? '_blank' : undefined;

  if (!href) {
    return null;
  }

  if (isInsideSelfService(href)) {
    return (
      <NextLink
        target={target}
        href={href}
        passHref
        className={className}
        onClick={onClick}
        legacyBehavior
      >
        {children}
      </NextLink>
    );
  }

  return (
    <a
      className={className}
      target={target}
      href={href}
      rel="noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  );
};
