import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useTextWithValues } from '@finn/b2c-cp/features-data';
import { cn } from '@finn/ui-utils';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerProps } from 'react-hook-form';

import { Field } from '../types';

type FieldForSomeExceptionProps = Field &
  Parameters<ControllerProps['render']>['0']['field'];

const DynamicLabel = ({ children }: { children: string }) => {
  const [formattedValue, status] = useTextWithValues(children);

  return (
    <span className="mt-1 text-sm font-light">
      <Skeleton width="120px" loading={status === 'loading'}>
        {status !== 'loading' ? formattedValue : null}
      </Skeleton>
    </span>
  );
};

export const FieldForSomeException = forwardRef(
  (
    { ...field }: FieldForSomeExceptionProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    if (field.type !== 'exception') {
      return null;
    }

    switch (field.name) {
      case 'addressLine':
        return (
          <div className={cn('flex gap-24', field.className)}>
            {field.items.map((item) => (
              <div key={item.label} className="flex flex-col" ref={ref}>
                <span className="text-sm font-normal">{item.label}</span>
                <DynamicLabel>{item.value}</DynamicLabel>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  }
);
