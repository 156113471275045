import { OrderStatusStep } from '@finn/b2c-cp/bff/core-api/types';
import { useRemoteData } from '@finn/platform-modules';

const orderStatusEndpoint = (id: string) => () => [
  `portal/api/bff/deals/${id}/order-status`,
];

// Order Status:
// order_received -> customer_verified -> car_arrived_at_compound -> car_ready_for_delivery -> car_in_delivery -> delivery_completed

export const useOrderStatus = (id: string) =>
  useRemoteData<OrderStatusStep>(
    id && id !== 'no-deal' ? `order-status/${id}` : null,
    orderStatusEndpoint(id)
  );
