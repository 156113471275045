import { useCurrentLocale } from '@finn/ui-utils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatValue } from './helpers';

export const useFormattedValue: typeof formatValue = (value, formatConfig) => {
  const intl = useIntl();
  const { region, locale } = useCurrentLocale();
  const valueFormatConfig = useMemo(
    () => ({ intl, region, locale, ...formatConfig }),
    [intl, region, locale, formatConfig]
  );

  return formatValue(value, valueFormatConfig);
};
