import { ReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { isInThePast } from '@finn/b2c-cp/deprecated/time';
import { If } from '@finn/b2c-cp/features-components/IfRender';
import { formatAddress } from '@finn/b2c-cp/services/format-address';
import { Button } from '@finn/design-system/atoms/button';
import { Deal } from '@finn/platform-modules';
import { parseToHtml } from '@finn/ui-utils';
import Link from 'next/link';

import { DataRow } from '../../DataRow';
import { SwapDataSection } from './SwapDataSection';

type SwapReturnSectionProps = {
  labelsMap: { [key: string]: string };
  deal?: Deal;
  returnData?: ReturnData;
};

export const SwapReturnSection = ({
  labelsMap,
  deal,
  returnData,
}: SwapReturnSectionProps) => {
  const isReturnInThePast = isInThePast(deal?.actual_return_date);
  const htmlDescription =
    deal?.is_return_window_open === true
      ? labelsMap.schedule_return_html_description
      : labelsMap.return_window_not_open_html_description;

  const isReturnScheduled = Boolean(deal?.return_type) || isReturnInThePast;
  const date =
    returnData?.planned_delivery_date ?? returnData?.preferred_delivery_date;
  const time = returnData?.slot?.formatted_time;

  return (
    <SwapDataSection
      hideRows={!isReturnScheduled}
      labelsMap={labelsMap}
      deal={deal}
      date={date}
      time={time}
      address={formatAddress(returnData?.address)}
      title={
        isReturnInThePast
          ? labelsMap.car_returned
          : labelsMap.car_to_be_returned
      }
    >
      <If condition={!isReturnScheduled}>
        <span className="body-16-light mt-8 inline-block">
          {
            parseToHtml(
              htmlDescription
                .replace('{carModel}', deal?.car?.model)
                .replace('{carBrand}', deal?.car?.oem)
            ) as string
          }
        </span>
      </If>
      <If condition={isReturnScheduled}>
        <DataRow
          variant="table"
          className="!h-12 !border-0"
          config={{ label: labelsMap.type_label, type: 'text' }}
          value={
            returnData?.type === 'SELF_RETURN'
              ? labelsMap?.self_return
              : labelsMap?.pick_up
          }
        />
      </If>
      <If condition={!isReturnInThePast}>
        <Link
          href={
            isReturnScheduled
              ? `mycars/${deal?.id}?modalName=MULTISTEP_EDIT_RETURN`
              : `mycars/${deal?.id}/return`
          }
          legacyBehavior
        >
          <Button
            className="mt-4 h-max px-6 py-2"
            variant="outline"
            href={
              isReturnScheduled
                ? `mycars/${deal?.id}?modalName=MULTISTEP_EDIT_RETURN`
                : `mycars/${deal?.id}/return`
            }
          >
            {isReturnScheduled
              ? labelsMap.edit_return_button_label
              : labelsMap.schedule_return_button_label}
          </Button>
        </Link>
      </If>
    </SwapDataSection>
  );
};
