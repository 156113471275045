import { useValue } from './useValue';

export const useTextWithValues = (text: string) => {
  const pattern = /\{.*?\}/g;
  const matches = text.match(pattern);

  const [values, status] = useValue({ paths: matches });
  const textWithValues = values.reduce(
    (acc, value, index) => acc.replace(matches[index], value || '?'),
    text
  );

  return [textWithValues, status];
};
