import { Button } from '@finn/design-system/atoms/button';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { trackEvent } from '@finn/ui-utils';
import Link from 'next/link';

import { DataItemProps } from '../../DataList';
import { ValueItem } from './ValueItem';

export type ContactSectionCosmicConfig = {
  section_title?: string;
  section_cta?: {
    label?: string;
    url?: string;
  };
  section_items?: DataItemProps[];
};

type ContactSectionProps = {
  content: ContactSectionCosmicConfig;
};

export const ContactSection = ({ content }: ContactSectionProps) => {
  return (
    <DataModule>
      <DataModuleHeader>
        <DataModuleTitle asChild>
          <p>{content?.section_title}</p>
        </DataModuleTitle>
      </DataModuleHeader>
      <DataModuleBody>
        {content.section_items.map((item, index) => (
          <ValueItem
            key={`row-${index}`}
            label={item.label}
            value={item.value}
            valueType={item.valueType}
          />
        ))}
      </DataModuleBody>
      <DataModuleFooter>
        <Link
          href={content.section_cta?.url}
          onClick={() => {
            trackEvent('CTA Clicked', {
              location,
              label: content.section_cta?.label,
            });
          }}
        >
          <Button size="lg" variant="outline" className="w-full md:w-max">
            {content.section_cta?.label}
          </Button>
        </Link>
      </DataModuleFooter>
    </DataModule>
  );
};
