import { ReturnData, ReturnMetadata } from '@finn/b2c-cp/bff/core-api/types';
import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { MessageBanner } from '@finn/b2c-cp/features-components/banners/MessageBanner';
import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Grid } from '@finn/b2c-cp/features-components/Grid/Grid';
import { GridRow } from '@finn/b2c-cp/features-components/Grid/GridRow';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { useCosmicModule } from '@finn/platform-modules';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface LocationStepProps {
  returnMetadata: ReturnMetadata;
  isLoading: boolean;
}

export const LocationStep: React.FC<LocationStepProps> = ({
  returnMetadata,
  isLoading,
}) => {
  const content = useCosmicModule('advanced-edit-return');
  const compoundLocation = returnMetadata?.compound_locations?.[0];

  const methods = useFormContext();
  const returnType = methods?.getValues('return_type');

  return (
    <div className="mt-8">
      <Skeleton loading={isLoading}>
        <Grid cols={4}>
          {returnType === 'PICKUP' ? (
            <>
              <GridRow colsSpan={3}>
                <FeatureFormField
                  type="text"
                  name="street"
                  label={content?.location?.street?.label}
                />
              </GridRow>
              <GridRow colsSpan={1}>
                <FeatureFormField
                  type="text"
                  name="housenumber"
                  label={content?.location?.housenumber?.label}
                />
              </GridRow>
              <GridRow colsSpan={2}>
                <FeatureFormField
                  type="text"
                  name="postcode"
                  label={content?.location?.postcode?.label}
                />
              </GridRow>
              <GridRow colsSpan={2}>
                <FeatureFormField
                  type="text"
                  name="city"
                  label={content?.location?.city?.label}
                />
              </GridRow>
              <GridRow colsSpan={4}>
                <FeatureFormField
                  type="text"
                  name="address_suffix"
                  label={content?.location?.address_suffix?.label}
                />
              </GridRow>
            </>
          ) : (
            <>
              <GridRow colsSpan={3}>
                <FeatureFormField
                  type="text"
                  name="compoundStreet"
                  label={content?.location?.street?.label}
                  defaultValue={compoundLocation?.street}
                  disabled
                />
              </GridRow>
              <GridRow colsSpan={1}>
                <FeatureFormField
                  type="text"
                  name="compoundHousenumber"
                  label={content?.location?.housenumber?.label}
                  defaultValue={compoundLocation?.house_number}
                  disabled
                />
              </GridRow>
              <GridRow colsSpan={2}>
                <FeatureFormField
                  type="text"
                  name="compoundZip"
                  label={content?.location?.postcode?.label}
                  defaultValue={compoundLocation?.zip}
                  disabled
                />
              </GridRow>
              <GridRow colsSpan={2}>
                <FeatureFormField
                  type="text"
                  name="compoundCity"
                  label={content?.location?.city?.label}
                  defaultValue={compoundLocation?.city}
                  disabled
                />
              </GridRow>
              <GridRow colsSpan={4}>
                <MessageBanner
                  title={
                    content?.location?.returnPreferences?.selfReturn?.label
                  }
                  description={
                    <TextBlock
                      fontSize={14}
                      text={
                        content?.location?.returnPreferences?.selfReturn
                          ?.selfReturnDisclaimer
                      }
                    />
                  }
                />
              </GridRow>
            </>
          )}
        </Grid>
      </Skeleton>
    </div>
  );
};

export const locationStep = ({
  content,
  returnMetadata,
  returnData,
  availabilitiesIsLoading,
}: {
  content?: {
    title?: string;
    footerCta?: string;
    validation?: {
      [key: string]: string | undefined;
    };
  };
  returnMetadata: ReturnMetadata;
  returnData: ReturnData;
  availabilitiesIsLoading: boolean;
}) => {
  const address = returnData?.address;
  const { street, house_number, zip, city, address_extra } = address || {};

  return prepareWizardStep({
    render: () => (
      <LocationStep
        returnMetadata={returnMetadata}
        isLoading={availabilitiesIsLoading}
      />
    ),
    title: content?.title,
    footer: { cta: { action: 'next-step', label: content?.footerCta } },
    isCTADisabled: (errors) =>
      Boolean(errors.postcode) ||
      Boolean(errors.street) ||
      Boolean(errors.housenumber) ||
      Boolean(errors.city),
    fields: [
      {
        name: 'street',
        type: 'text',
        defaultValue: street,
        validation: { required: content?.validation?.street },
      },
      {
        name: 'housenumber',
        type: 'text',
        defaultValue: house_number,
        validation: { required: content?.validation?.housenumber },
      },
      {
        name: 'postcode',
        type: 'text',
        defaultValue: zip,
        validation: {
          required: content?.validation?.postcodeRequired,
          minLength: {
            value: 5,
            message: content?.validation?.postcodeMinLength,
          },
          maxLength: {
            value: 5,
            message: content?.validation?.postcodeMaxLength,
          },
          germanZipCodeForDelivery: {
            message: content?.validation?.germanZipCodeForDelivery,
          },
        },
      },
      {
        name: 'city',
        type: 'text',
        defaultValue: city,
        validation: { required: content?.validation?.city },
      },
      {
        name: 'address_suffix',
        defaultValue: address_extra,
        type: 'text',
      },
    ],
  });
};
