import { useDealId } from '@finn/b2c-cp/features-data';
import { useDeal } from '@finn/platform-modules';
import { useFormContext } from 'react-hook-form';

type ScheduleServiceSummaryProps = {
  fields: { key: string; label: string }[];
  labels_map: { [key: string]: string };
};

export const ScheduleServiceSummary = ({
  fields,
  labels_map,
}: ScheduleServiceSummaryProps) => {
  const { getValues } = useFormContext();
  const dealId = useDealId();
  const { data } = useDeal(dealId);
  const licensePlate = data?.car?.license_plate;

  const formValues = getValues();

  const cosmicFormValuesMap = {
    vehicle: `${data?.car?.oem} ${
      data?.car?.model
    }${licensePlate ? `, ${licensePlate}` : ''}`,
    services: [
      formValues.huau ? 'huau' : null,
      formValues.inspection ? 'inspection' : null,
      formValues.oilChange ? 'oilChange' : null,
    ]
      .filter((item) => Boolean(item))
      .map((item) => labels_map[item])
      .join(', '),
    address: `${formValues.street} ${formValues.housenumber}, ${formValues.zip} ${formValues.city}`,
  };

  return (
    <div>
      {fields.map((field) => (
        <div key={field.key} className="flex justify-between">
          <span className="text-base font-normal">{field.label}</span>
          <span className="text-base font-semibold">
            {cosmicFormValuesMap[field.key]}
          </span>
        </div>
      ))}
    </div>
  );
};
