import { ContactFormFields } from '@finn/b2c-cp/features/ContactFormFields';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { Deal, useCosmicModule } from '@finn/platform-modules';

export const ContactStep = () => {
  const content = useCosmicModule('advanced-edit-return');

  return <ContactFormFields content={content} />;
};

export const contactStep = ({
  deal,
  title,
  cta,
  phoneNumberRequiredValidation,
  phoneNumberFormatValidation,
}: {
  deal: Deal;
  title?: string;
  cta?: string;
  phoneNumberRequiredValidation?: string;
  phoneNumberFormatValidation?: string;
}) => {
  const {
    handover_firstname,
    handover_lastname,
    handover_phone_number,
    return_phone_number,
  } = deal || {};

  const default_phone_number = return_phone_number ?? handover_phone_number;

  return prepareWizardStep({
    render: () => <ContactStep />,
    title,
    footer: { cta: { action: 'next-step', label: cta } },
    isCTADisabled: (errors) => Boolean(errors.phone_number),
    fields: [
      {
        name: 'firstname',
        type: 'text',
        defaultValue: handover_firstname,
      },
      {
        name: 'lastname',
        type: 'text',
        defaultValue: handover_lastname,
      },
      {
        name: 'phone_number',
        type: 'text',
        defaultValue: default_phone_number,
        validation: {
          required: phoneNumberRequiredValidation,
          validPhoneNumber: phoneNumberFormatValidation,
        },
      },
    ],
  });
};
