import { VisibilityConfig } from '@finn/platform-modules';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn, parseToHtml } from '@finn/ui-utils';
import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FormatConfig, useIsFeatureVisible, useValue } from '../features-data';

export type TextBlockProps = {
  textId?: string;
  text?: string | ReactNode;
  formatConfig?: FormatConfig;
  fontSize?: number;
  backgroundColor?: string;
  padding?: string;
  borderRadius?: string;
  className?: string;
  visibilityConfig?: VisibilityConfig;
};

export const TextBlock = ({
  textId,
  text,
  fontSize,
  backgroundColor,
  padding,
  borderRadius,
  formatConfig,
  className,
  visibilityConfig,
}: TextBlockProps) => {
  const [formatedText] = useValue(
    typeof text === 'string' ? text : '',
    formatConfig
  );
  const track = useTrackingStore((state) => state.track);

  const intl = useIntl();

  const isVisible = useIsFeatureVisible(visibilityConfig);

  useEffect(() => {
    if (!isVisible || !textId) {
      return;
    }
    track(TrackingEventName.TEXT_BLOCK_SHOWN, {
      location: textId,
    });
  }, [textId, track, isVisible]);

  const content = textId
    ? intl.formatMessage({ id: textId })
    : typeof text === 'string'
      ? parseToHtml(formatedText)
      : text;

  if (!isVisible) {
    return null;
  }

  return (
    <p
      style={{ fontSize, backgroundColor, padding, borderRadius }}
      className={cn(
        'body-14-light before:hidden after:hidden',
        '[&_ul]:ps-10 [&_ul_li]:list-[circle]',
        className
      )}
    >
      {content}
    </p>
  );
};
