import { If } from '@finn/b2c-cp/features-components/IfRender';
import { useTextWithValues } from '@finn/b2c-cp/features-data';
import { FormDescription, FormItem, FormLabel } from '@finn/b2c-cp/ui/form';
import { Input } from '@finn/design-system/atoms/input';
import { InfoToolTip } from '@finn/ui-components';
import omit from 'lodash/omit';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerProps, useFormContext } from 'react-hook-form';

import { Field } from '../types';

type FileFieldProps = Field &
  Parameters<ControllerProps['render']>['0']['field'];

// TODO use form field from shadcn
// import { Input } from '../../../ui/input';
// currently we are using very old OPS file input for back compability

const DescriptionItem = ({ children }: { children: string }) => {
  const [description] = useTextWithValues(children);

  return (
    <FormDescription className="text-base font-normal">
      {description}
    </FormDescription>
  );
};

export const FileField = forwardRef(
  ({ ...field }: FileFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { formState } = useFormContext();

    if (field.type !== 'file') {
      return null;
    }

    const error = formState.errors?.[field.name];

    return (
      <FormItem className={field.className}>
        <If condition={Boolean(field.title)}>
          <FormLabel className="inline-flex gap-1 text-base font-normal">
            {field.title}
            {field.tooltip?.label ? (
              <InfoToolTip content={field.tooltip?.label} />
            ) : null}
          </FormLabel>
        </If>
        <Input
          ref={ref}
          type="file"
          error={error?.message}
          {...omit(field, 'visibilityConfig', 'value')}
          onChange={(e) => {
            if (e.target?.files?.length) {
              formState.errors[field.name] = '';
              field.onChange(e.target.files[0]);
            } else {
              field.onChange(null);
            }
          }}
        />
        {field.descriptions?.map((description) => (
          <DescriptionItem key={description}>{description}</DescriptionItem>
        ))}
      </FormItem>
    );
  }
);
