'use client';

import { cn } from '@finn/ui-utils';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';
import { Children, useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '../../helpers/media';
import { Button } from '../button';
import { ArrowBackIos, ArrowForwardIos } from '../icons/generated';

const Tabs = TabsPrimitive.Root;

const SCROLL_OFFSET = 100;

const ScrollButton = ({
  onClick,
  position,
}: {
  onClick: () => void;
  position: 'left' | 'right';
}) => (
  <div
    className={cn('absolute top-0 flex h-full items-center bg-white', {
      'left-0 pr-2': position === 'left',
      'right-0 pl-2': position === 'right',
    })}
  >
    <Button
      variant="outline"
      className="h-8 w-8 rounded px-2 [&_svg]:h-3 [&_svg]:w-3"
      onClick={onClick}
      data-testid={`tabs-${position}-scroll-button`}
    >
      {position === 'left' ? <ArrowBackIos /> : null}
      {position === 'right' ? <ArrowForwardIos /> : null}
    </Button>
  </div>
);

const TabsList = ({
  className,
  children,
  direction = 'horizontal',
  ...props
}: React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
  direction?: 'horizontal' | 'vertical';
}) => {
  const ref = useRef<HTMLDivElement>(null);
  // OPS require md screen
  const isMDScreen = useMediaQuery('md');

  const firstChildRef = useRef<Element | null>(null);
  const lastChildRef = useRef<Element | null>(null);

  const [showScrollButton, setShowScrollButton] = useState({
    left: false,
    right: false,
  });

  // after screen resized we need to reset previous states to
  // avoid artifacts
  useEffect(() => {
    if (!isMDScreen) {
      setShowScrollButton({
        left: false,
        right: false,
      });
    }
  }, [isMDScreen]);

  useEffect(() => {
    if (!isMDScreen) {
      return;
    }

    const leftButtonObserver = new IntersectionObserver(
      (entries) => {
        setShowScrollButton((prev) => ({
          ...prev,
          left: !entries[0].isIntersecting,
        }));
      },
      { root: ref.current, threshold: 1 }
    );
    const rightButtonObserver = new IntersectionObserver(
      (entries) => {
        setShowScrollButton((prev) => ({
          ...prev,
          right: !entries[0].isIntersecting,
        }));
      },
      { root: ref.current, threshold: 1 }
    );

    leftButtonObserver.observe(firstChildRef.current as Element);
    rightButtonObserver.observe(lastChildRef.current as Element);

    return () => {
      leftButtonObserver.disconnect();
      rightButtonObserver.disconnect();
    };
  }, [isMDScreen]);

  const tabsDirection = isMDScreen ? 'horizontal' : direction;

  return (
    <div className="relative">
      {showScrollButton.left ? (
        <ScrollButton
          position="left"
          onClick={() => {
            ref.current?.scrollBy({
              left: -SCROLL_OFFSET,
              behavior: 'smooth',
            });
          }}
        />
      ) : null}
      {showScrollButton.right ? (
        <ScrollButton
          position="right"
          onClick={() => {
            ref.current?.scrollBy({
              left: SCROLL_OFFSET,
              behavior: 'smooth',
            });
          }}
        />
      ) : null}
      <TabsPrimitive.List
        ref={ref}
        className={cn(
          'hide-scrollbar inline-flex w-full items-center justify-start gap-4 overflow-x-scroll px-0.5 py-1 pr-1',
          {
            'flex-row': tabsDirection === 'horizontal',
            'flex-col items-start gap-2': tabsDirection === 'vertical',
          },
          className
        )}
        {...props}
      >
        {Children.map(
          children as React.ReactElement<{
            className?: string;
            ref?: React.RefObject<Element | null>;
          }>[],
          (child, index) => {
            if (!React.isValidElement(child)) {
              return null;
            }

            const classNameProps = cn(child.props.className, {
              '!w-full': direction === 'vertical' && !isMDScreen,
            });

            if (index === 0) {
              return React.cloneElement(child, {
                ...child.props,
                ref: firstChildRef,
                className: classNameProps,
              });
            }

            if (index === Children.count(children) - 1) {
              return React.cloneElement(child, {
                ...child.props,
                ref: lastChildRef,
                className: classNameProps,
              });
            }

            return React.cloneElement(child, {
              ...child.props,
              className: classNameProps,
            });
          }
        )}
      </TabsPrimitive.List>
    </div>
  );
};
TabsList.displayName = TabsPrimitive.List.displayName;

const accessabilityStyles =
  'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-trustedBlue';

const borderStyles =
  'data-[state=active]:border-pearl hover:border-pearl rounded-sm border border-solid border-transparent';

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    withDot?: boolean;
  }
>(({ className, children, withDot, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        accessabilityStyles,
        borderStyles,
        'data-[state=active]:bg-snow body-14-semibold md:body-16-semibold disabled:text-steel disabled:fill-steel inline-flex h-9 min-h-9 w-max cursor-pointer items-center gap-x-1 text-nowrap bg-transparent px-3 before:hidden after:hidden disabled:pointer-events-none sm:h-12 md:min-h-14 md:px-4',
        className
      )}
      {...props}
    >
      {children}
      {withDot ? <div className="bg-trustedBlue h-2 w-2 rounded-full" /> : null}
    </TabsPrimitive.Trigger>
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

export { Tabs, TabsList, TabsTrigger };
