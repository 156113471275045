import { ClockOutlined } from '@finn/design-system/icons/clock-outlined';
import { ErrorOutlined } from '@finn/design-system/icons/error-outlined';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { WarningOutlined } from '@finn/design-system/icons/warning-outlined';
import {
  BannerModule,
  BannerModuleDescription,
  BannerModuleIcon,
  BannerModuleTitle,
} from '@finn/design-system/modules/BannerModule';
import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

import { useValue } from '../../features-data';
import { If } from '../IfRender';
import { Link } from '../Link';

export type MessageBannerProps = {
  title?: string;
  description?: string | ReactNode;
  iconName?: string;
  link?: {
    label?: string;
    url?: string;
    newTab?: boolean;
  };
};

const iconsMap = {
  info: InfoOutlined,
  warning: WarningOutlined,
  error: ErrorOutlined,
  clock: ClockOutlined,
};

export const MessageBanner = ({
  title,
  description,
  link,
  iconName,
}: MessageBannerProps) => {
  const Icon = iconsMap[iconName];
  const [formatedDescription] = useValue(
    typeof description === 'string' ? description : '',
    {
      type: 'date',
    }
  );

  return (
    <BannerModule
      className={cn({
        'grid-cols-[max-content,1fr]': Boolean(Icon) && (title || description),
        'grid-cols-[1fr]': !Icon,
      })}
    >
      {Icon && (
        <BannerModuleIcon
          className={title && description && !link ? 'row-span-2' : ''}
        >
          <Icon />
        </BannerModuleIcon>
      )}
      {title ? <BannerModuleTitle>{title}</BannerModuleTitle> : null}
      {description ? (
        <BannerModuleDescription>
          {typeof description === 'string' ? formatedDescription : description}
        </BannerModuleDescription>
      ) : null}
      <If condition={Boolean(link?.url)}>
        <Link newTab={!link?.url?.includes('#')} href={link?.url || ''}>
          <span className="link-14">{link?.label}</span>
        </Link>
      </If>
    </BannerModule>
  );
};
