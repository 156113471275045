import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { useProfileData } from '@finn/b2c-cp/features-data';

import { CONFIRM_DELETE_ACCOUNT_MODAL } from '../../ConfirmDeleteAccountModal';

export const useAccountDeletion = () => {
  const { data: profileData } = useProfileData();
  const { openModal } = useModalStore();

  const routeDeletionRequest = async () => {
    if (profileData?.properties?.is_deletion_requested) {
      openModal('account-deletion-requested');

      return;
    }

    openModal(CONFIRM_DELETE_ACCOUNT_MODAL);
  };

  return { routeDeletionRequest };
};
