import { ModalKey } from '@finn/b2c-cp/deprecated/modules/modals';
import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { Builder, FormWrapper } from '@finn/b2c-cp/features-components/Builder';
import { InfoModal } from '@finn/b2c-cp/features-components/InfoModal';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { toStepFields, Wizard } from '@finn/b2c-cp/features-components/Wizard';
import { useDealId } from '@finn/b2c-cp/features-data';
import { Step, useDeal } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import * as loginModals from '@finn/ua-modals';
import { captureMessage, trackEvent, useSession } from '@finn/ui-utils';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';

import { NoDeal, NoDealProps } from './NoDeal';

type ScheduleServiceProps = {
  steps: Step[];
  no_deal?: NoDealProps;
  translations: {
    success_modal: {
      title: string;
      description: string;
      affirmative_text: string;
    };
    error_modal: {
      title: string;
      affirmative_text: string;
      cancel_text: string;
      description: string;
    };
    field_required: string;
  };
};

export const ScheduleService = ({
  steps,
  no_deal,
  translations,
}: ScheduleServiceProps) => {
  const router = useRouter();
  const dealId = useDealId();
  const { data: deal, status: dealStatus } = useDeal(dealId);
  const { openModal } = useModalStore();

  const [session, loading] = useSession();
  const openLoginModal = loginModals.useOpenModal();

  useEffect(() => {
    if (!session && !loading) {
      openLoginModal(loginModals.ModalKey.LOGIN);
    }
  }, [session, loading, openLoginModal]);

  const renderInfoModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="success"
      open={open}
      onClose={() => {
        router.push(`${SelfServiceRoutes.MY_CARS}/${dealId}`);
        onClose();
      }}
      heading={translations?.success_modal?.title}
      htmlDescription={translations?.success_modal?.description}
      affirmationText={translations?.success_modal?.affirmative_text}
    />
  );

  const renderErrorModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="error"
      open={open}
      onClose={onClose}
      heading={translations?.error_modal?.title}
      htmlDescription={translations?.error_modal?.description}
      affirmationText={translations?.error_modal?.affirmative_text}
      onAffirmation={() => {
        router.push(`${SelfServiceRoutes.MY_CARS}/${dealId}`);
      }}
    />
  );

  const handleSubmit = useCallback(
    async (data) => {
      if (!deal?.car_id) {
        openModal(ModalKey.ERROR_MODAL);
        captureMessage(new Error(`No car_id found in deal ${deal?.id}`));

        return;
      }

      const serviceAddress = `${data.street} ${data.housenumber}, ${data.zip} ${data.city}`;
      const requested_services = [];
      if (data.huau) {
        requested_services.push('main_inspection');
      }
      if (data.inspection) {
        requested_services.push('oem_inspection');
      }
      if (data.oilChange) {
        requested_services.push('oil_change');
      }
      trackEvent('Schedule Service Submitted', {});
      const res = await axios.post(
        'https://hook.finn.integromat.cloud/6stpv5uaceql172ujss5v4yalsf3b3vj',
        {
          data: {
            car_id: deal?.car_id,
            comment: `${serviceAddress}\n${data.serviceDescription}`,
            requested_services,
          },
        }
      );

      // we redirect to my cars page
      if (res.status === 200) {
        trackEvent('Schedule Service Submitted Successful', {});
        openModal(ModalKey.SUCCESS_MODAL);
      } else {
        openModal(ModalKey.ERROR_MODAL);
      }
    },
    [deal?.car_id, deal?.id, openModal]
  );

  const fieldsArr = useMemo(() => toStepFields(steps), [steps]);

  return (
    <>
      <Skeleton loading={dealStatus === 'loading'} fullWidth>
        <FormWrapper fields={fieldsArr}>
          {dealId === 'no-deal' ? (
            <NoDeal {...no_deal} type="schedule-service" />
          ) : (
            <Wizard
              location="schedule_service"
              steps={steps}
              renderChildren={(wizardProps) => <Builder {...wizardProps} />}
              onFormSubmit={handleSubmit}
            />
          )}
        </FormWrapper>
      </Skeleton>
      <ModalContainer
        name={ModalKey.ERROR_MODAL}
        renderModal={renderErrorModal}
      />
      <ModalContainer
        name={ModalKey.SUCCESS_MODAL}
        renderModal={renderInfoModal}
      />
    </>
  );
};
