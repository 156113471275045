import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Grid } from '@finn/b2c-cp/features-components/Grid/Grid';
import { GridRow } from '@finn/b2c-cp/features-components/Grid/GridRow';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { Deal, useCosmicModule } from '@finn/platform-modules';
import { CountryCode } from '@finn/ui-utils';

export const LocationStep = () => {
  const content = useCosmicModule('advanced-edit-handover');

  return (
    <div className="mt-8">
      <Grid cols={4}>
        <GridRow colsSpan={3}>
          <FeatureFormField
            type="text"
            name="street"
            label={content?.location?.street?.label}
          />
        </GridRow>
        <GridRow colsSpan={1}>
          <FeatureFormField
            type="text"
            name="housenumber"
            label={content?.location?.housenumber?.label}
          />
        </GridRow>
        <GridRow colsSpan={2}>
          <FeatureFormField
            type="text"
            name="postcode"
            label={content?.location?.postcode?.label}
          />
        </GridRow>
        <GridRow colsSpan={2}>
          <FeatureFormField
            type="text"
            name="city"
            label={content?.location?.city?.label}
          />
        </GridRow>
        <GridRow colsSpan={4}>
          <FeatureFormField
            type="text"
            name="address_suffix"
            label={content?.location?.address_suffix?.label}
          />
        </GridRow>
      </Grid>
    </div>
  );
};

export const locationStep = ({
  content,
  deal,
  region,
}: {
  content?: {
    title?: string;
    footerCta?: string;
    validation?: {
      [key: string]: string | undefined;
    };
  };
  deal: Deal;
  region: CountryCode;
}) => {
  const {
    handover_street,
    handover_housenumber,
    handover_zip,
    handover_city,
    handover_address_extra,
  } = deal || {};

  return prepareWizardStep({
    render: () => <LocationStep />,
    title: content?.title,
    footer: { cta: { action: 'next-step', label: content?.footerCta } },
    isCTADisabled: (errors) =>
      Boolean(errors.postcode) ||
      Boolean(errors.street) ||
      Boolean(errors.housenumber) ||
      Boolean(errors.city),
    fields: [
      {
        name: 'street',
        type: 'text',
        defaultValue: handover_street,
        validation: { required: content?.validation?.street },
      },
      {
        name: 'housenumber',
        type: 'text',
        defaultValue: handover_housenumber,
        validation: { required: content?.validation?.housenumber },
      },
      {
        name: 'postcode',
        type: 'text',
        defaultValue: handover_zip,
        validation: {
          required: content?.validation?.postcodeRequired,
          minLength: {
            value: region === CountryCode.DE ? 5 : 5,
            message: content?.validation?.postcodeMinLength,
          },
          maxLength: {
            value: region === CountryCode.DE ? 5 : 5,
            message: content?.validation?.postcodeMaxLength,
          },
          germanZipCodeForDelivery: {
            message: content?.validation?.germanZipCodeForDelivery,
          },
        },
      },
      {
        name: 'city',
        type: 'text',
        defaultValue: handover_city,
        validation: { required: content?.validation?.city },
      },
      {
        name: 'address_suffix',
        defaultValue: handover_address_extra,
        type: 'text',
      },
    ],
  });
};
