import axios from 'axios';
import useSWR from 'swr';

export const DEDUPING_INTERVAL = 300000;

type ProlongationOption = {
  original_price?: number;
  term: number;
  price: number;
};

export type ProlongationData = {
  current_subscription_end_date: string;
  prolongation_options: ProlongationOption[];
  reduced_prolongation_price_experiment: boolean;
  is_downpayment_subscription: boolean;
};

type ResponseData = {
  data: ProlongationData;
  status: number;
};

const fetcher = async ({
  url,
  subscriptionId,
}: {
  url: string;
  subscriptionId: string;
}) => {
  return axios.post(url, { dealId: subscriptionId }, { withCredentials: true });
};

export const useProlongationData = (subscriptionId: string | undefined) => {
  const url = `/api/checkout/prolongation/options`;

  const { data, isLoading } = useSWR<ResponseData>(
    subscriptionId ? { url, subscriptionId } : null,
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
      dedupingInterval: DEDUPING_INTERVAL,
    }
  );

  return {
    data: data?.data,
    status: data?.status,
    isLoading,
  };
};
