import { useIsFeatureVisible } from '@finn/b2c-cp/features-data';
import { VisibilityConfigs } from '@finn/platform-modules';
import { useCallback } from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { FormField } from '../../../ui/form';
import { Field } from '../types';
import { BooleanField } from './BooleanField';
import { CalendarField } from './CalendarField';
import { DateCustomField } from './DateCustomField';
import { FieldForSomeException } from './FieldForSomeException';
import { FileField } from './FileField';
import { InputField } from './InputField';
import { RadioField } from './RadioField';
import { SelectField } from './SelectField';

type FeatureFormFieldProps = {
  className?: string;
  disabled?: VisibilityConfigs;
} & Field;

export const FeatureFormField = ({ ...fieldMeta }: FeatureFormFieldProps) => {
  const methods = useFormContext();

  // a bit tricky condition
  // let's begin from the end, we set default value of disabled
  // to fieldMeta?.disabled || false, so if fieldMeta?.disabled is boolean and true
  // it will return true, if it's false or undefined it will return false
  // in order to use fallback we check that typeof fieldMeta?.disabled is boolean
  // if it is a boolean - we set undefined -> to use fallback described above
  // if it is not a boolean, it means it is object or undefined, so we use fieldMeta?.disabled
  // TODO we can not(yet) just do useIsFeatureVisible(fieldMeta?.disabled), because useIsFeatureVisible(false | true) does not work as expected
  const disabled = useIsFeatureVisible(
    typeof fieldMeta?.disabled === 'boolean' ? undefined : fieldMeta?.disabled,
    fieldMeta?.disabled || false
  );

  const isVisible = useIsFeatureVisible(fieldMeta?.visibilityConfig);

  const renderField = useCallback(
    ({ field }: { field: ControllerRenderProps<FieldValues, string> }) => {
      switch (fieldMeta?.type) {
        case 'boolean': {
          return <BooleanField {...fieldMeta} {...field} disabled={disabled} />;
        }
        case 'number':
        case 'text':
        case 'password': {
          return <InputField {...fieldMeta} {...field} disabled={disabled} />;
        }
        case 'file': {
          return <FileField {...fieldMeta} {...field} disabled={disabled} />;
        }
        case 'date-custom':
        case 'date-input': {
          return (
            <DateCustomField {...fieldMeta} {...field} disabled={disabled} />
          );
        }
        case 'calendar': {
          return (
            <CalendarField {...fieldMeta} {...field} disabled={disabled} />
          );
        }
        case 'select': {
          return <SelectField {...fieldMeta} {...field} disabled={disabled} />;
        }
        case 'radio': {
          return <RadioField {...fieldMeta} {...field} disabled={disabled} />;
        }
        case 'exception': {
          return (
            <FieldForSomeException
              {...fieldMeta}
              {...field}
              disabled={disabled}
            />
          );
        }
        default: {
          return null;
        }
      }
    },
    [fieldMeta, disabled]
  );

  if (!methods?.control || !isVisible) {
    return null;
  }

  return (
    <FormField
      control={methods?.control}
      name={fieldMeta?.name}
      render={renderField}
    />
  );
};
