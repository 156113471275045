import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { useIsFeatureVisible, useValue } from '@finn/b2c-cp/features-data';
import {
  CustomDescription,
  Description as DescriptionType,
} from '@finn/platform-modules';
import { parseToHtml } from '@finn/ui-utils';
import { useEffect, useMemo } from 'react';

const toRenderableDescription = (description: DescriptionType) => {
  if (typeof description === 'string') {
    return [{ label: description }];
  }

  if (Array.isArray(description)) {
    return description.map((item) => ({
      ...item,
      label: item?.data,
      valueType: item?.config?.valueType,
      visibilityConfig: item?.config?.visibilityConfig,
    }));
  }
  if (typeof description?.data === 'string') {
    return [{ label: description?.data }];
  }
  if (Array.isArray(description?.data)) {
    return description.data.map((item) => item);
  }

  return description?.data ? [description.data] : [];
};

const useInterceptModalLinks = (description: CustomDescription[]) => {
  const { openModal } = useModalStore();
  // small trick to support dynamic links in a form that open modals
  useEffect(() => {
    if (description?.length) {
      let cleanup = () => void 0;
      // we need to run this in the next tick to make sure the dom is ready
      // as after open === true, modal still needs to be rendered
      const timerId = setTimeout(() => {
        // we look for any link that has modalName in the href query
        const modalLinks = document.querySelectorAll<HTMLAnchorElement>(
          '[href^="/?modalName="]'
        );
        const listener = (e: Event) => {
          e.preventDefault();
          const anchor = e.currentTarget as HTMLAnchorElement;
          const modalName = anchor.dataset.modalName;
          openModal(modalName);
        };

        modalLinks.forEach((link) => {
          const urlParams = new URLSearchParams(link.href?.split('?')?.[1]);
          const modalName = urlParams.get('modalName');
          link.href = '';
          link.dataset.modalName = modalName;
          link.addEventListener('click', listener);
        });

        cleanup = () => {
          modalLinks.forEach((link) => {
            link.removeEventListener('click', listener);
          });
        };
      }, 0);

      return () => {
        clearTimeout(timerId);
        cleanup();
      };
    }
  }, [openModal, description]);
};

type DescriptionItemProps = {
  description: CustomDescription;
};

const DescriptionItem = ({ description }: DescriptionItemProps) => {
  const isVisible = useIsFeatureVisible(description.visibilityConfig, true);
  const [formattedLabel] = useValue(
    description.label,
    description?.config?.formatConfig ?? null
  );

  if (!isVisible) {
    return null;
  }

  return (
    <span className="body-16-light mb-4 inline-block">
      {parseToHtml(formattedLabel)}
    </span>
  );
};

type DescriptionProps = {
  description?: DescriptionType;
};

export const Description = ({ description }: DescriptionProps) => {
  const descriptions = useMemo(
    () => toRenderableDescription(description),
    [description]
  );
  useInterceptModalLinks(descriptions);

  return (
    <>
      {descriptions?.map((desc) => (
        <DescriptionItem key={desc.label} description={desc} />
      ))}
    </>
  );
};
