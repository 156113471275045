export enum ReturnType {
  DELIVERY = 'SELF_RETURN',
  PICKUP = 'PICKUP',
}

export enum ReturnStatus {
  REQUEST_RECEIVED = 'request_received',
  IN_PREPARATION = 'in_preparation',
  CONFIRMED = 'confirmed',
}

export type CompoundLocation = {
  id: string;
  type: string;
  address_complete: string;
  name_short: string;
  address_name: string;
  address_street: string;
  address_city: string;
  address_zip_code: string;
  address_country: string;
  address_house_number: string;
  opening_hour: string;
  closing_hour: string;
  contact_email: string;
  contact_phone_number: string;
  is_return_available: boolean;

  // new names
  name: string;
  short: string;
  street: string;
  house_number: string;
  zip: string;
  city: string;
};
