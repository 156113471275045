import { useMemo } from 'react';

import { useReportDamageSubmit } from './useReportDamageSubmit';

type UseFormSubmitConfig = {
  success_toast?: string;
  error_toast?: string;
  location: string;
  step: number | string;
};

export const useFormSubmit = (
  name: 'report-damage',
  config: UseFormSubmitConfig
) => {
  const { handleSubmit: handleReportDamageSubmit } =
    useReportDamageSubmit(config);

  return useMemo(() => {
    if (name === 'report-damage') {
      return { handleSubmit: handleReportDamageSubmit };
    }

    return { handleSubmit: async () => ({}) };
  }, [name, handleReportDamageSubmit]);
};
