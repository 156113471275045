import { cn, trackEvent } from '@finn/ui-utils';
import Link from 'next/link';
import { ReactNode } from 'react';

export type SectionProps = {
  children: ReactNode;
  title: string;
  cta?: {
    label: string;
    url: string;
  };
  className?: string;
  location?: string;
};

export const Section = ({
  children,
  title,
  cta,
  location,
  className,
}: SectionProps) => {
  return (
    <div
      className={cn(
        'border-pearl h-full w-full rounded-sm border border-solid p-6',
        className
      )}
    >
      <div className="flex flex-row justify-between pb-8">
        <h5 className="global-t5-semibold">{title}</h5>
        {cta ? (
          <Link
            href={cta.url ?? ''}
            className="font-semibold underline"
            onClick={() => {
              trackEvent('CTA Clicked', { location, label: cta.label });
            }}
          >
            {cta.label}
          </Link>
        ) : null}
      </div>
      {children}
    </div>
  );
};
