import { getRequestStatus } from '@finn/platform-modules';
import { REQUEST_ID_HEADER_NAME } from '@finn/ui-utils';
import axios from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
import { v4 as uuid } from 'uuid';

import { parametrizeUrl } from '../../helpers';
import { useDealId } from '../../MetadataProvider';
import { useCurrentDeal } from '../useCurrentDeal';

export const useCustomResource = <T,>(urls: string[] | null) => {
  const currentDeal = useCurrentDeal();
  // useDealId reads deal id from url, so it available for deal is loaded
  const dealId = useDealId();
  const mileagePackageArrID = urls?.findIndex((url) =>
    url.includes('/mileage_packages')
  );
  urls[mileagePackageArrID] =
    '/portal/api/bff/deals/{subscription_id}/mileage_packages';

  const fetcher = (url: string) =>
    axios(url, {
      headers: {
        [REQUEST_ID_HEADER_NAME]: uuid(),
        'Cache-Control': 'no-cache',
      },
      withCredentials: true,
    }).catch((err) => err?.response);

  const parametrizedURLs = urls?.map((url) =>
    parametrizeUrl({ url, deal: currentDeal?.data, dealId })
  );

  const multipleFetcher = (urlsToFetch: string[]) =>
    Promise.all(urlsToFetch?.map(fetcher));
  const { data, error, isLoading } = useSWR(parametrizedURLs, multipleFetcher);

  return useMemo(
    () => ({
      data: data?.map((item) => item?.data?.data as T),
      status: getRequestStatus(
        data?.[0]?.status,
        isLoading,
        error,
        parametrizedURLs?.[0]
      ),
    }),
    [data, isLoading, error, parametrizedURLs]
  );
};
