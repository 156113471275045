import { SubscriptionDocument } from '@finn/b2c-cp/bff/core-api/types';
import { useRemoteData } from '@finn/platform-modules';

const documentsEndpoint = (id: string) =>
  `portal/api/bff/deals/${id}/documents`;

export const useDocuments = (id: string) => {
  return useRemoteData<SubscriptionDocument[]>(
    id ? `documents/${id}` : null,
    () => documentsEndpoint(id)
  );
};
