import { UpdateReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { setTimezone } from '@finn/b2c-cp/deprecated/time';
import { showToast } from '@finn/design-system/atoms/toast';
import { Locale } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback } from 'react';

import { useDealId } from '../../MetadataProvider';

type UseFormSubmit = {
  success_toast_title?: string;
  success_toast_description?: string;
  error_toast_title?: string;
  error_toast_description?: string;
};

export const useUpdateReturnFormSubmit = ({
  success_toast_title,
  success_toast_description,
  error_toast_title,
  error_toast_description,
}: UseFormSubmit) => {
  const dealId = useDealId();

  const handleSubmit = useCallback(
    async (values: UpdateReturnData) => {
      const payload = {
        ...values,
        preferred_return_date: setTimezone(
          new Date(values.preferred_return_date),
          Locale.GERMAN_GERMANY
        ).toISOString(),
      };
      try {
        await axios.put(`/portal/api/bff/deals/${dealId}/return`, payload);

        showToast({
          title: success_toast_title,
          description: success_toast_description,
          status: 'success',
        });

        return 200;
      } catch (error) {
        showToast({
          title: error_toast_title,
          description: error_toast_description,
          status: 'error',
        });

        return 500;
      }
    },
    [
      dealId,
      success_toast_title,
      success_toast_description,
      error_toast_title,
      error_toast_description,
    ]
  );

  return { handleSubmit };
};
