import { VisibilityConfig } from '@finn/platform-modules';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

const configToNames = (config: VisibilityConfig) => {
  if (Array.isArray(config)) {
    return config.filter((item) => item?.field);
  }
  if (config?.field) {
    return [config?.field];
  }

  return null;
};

export const useIsFeatureVisibleWithForm = (config: VisibilityConfig) => {
  const namesToWatch = useMemo(() => configToNames(config), [config]);
  const depsToWatch = useWatch({ name: namesToWatch });

  return useMemo(() => {
    if (!depsToWatch?.length) {
      return true;
    }

    return depsToWatch.every(
      (value) => 'value' in config && value === config.value
    );
  }, [depsToWatch, config]);
};
