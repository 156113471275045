import { useDeal, useRemoteData } from '@finn/platform-modules';

export const useServiceBookingPortal = (dealId: string) => {
  const car_id = useDeal(dealId)?.data?.car_id;

  return useRemoteData<{ url: string } | null>(
    car_id ? `schedule-service/${car_id}` : null,
    () => `portal/api/bff/deals/schedule-service/${car_id}`
  );
};
