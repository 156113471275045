import { If } from '@finn/b2c-cp/features-components/IfRender';
import {
  useFeatureAction,
  useIsFeatureVisible,
} from '@finn/b2c-cp/features-data';
import { Button } from '@finn/design-system/atoms/button';
import { ModalFooter } from '@finn/design-system/atoms/modal';
import { Spinner } from '@finn/design-system/atoms/spinner';
import { Footer as FooterType } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';
import { Primitive } from '@sentry/types';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export type FooterProps = {
  cta?: FooterType['cta'];
  link?: FooterType['link'];
  location?: string;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  secondaryCTA?: FooterType['secondaryCTA'];
  onCTAClick?: () => void;
  onSecondaryCTAClick?: () => void;
  isCTADisabled?: (errors: Record<string, Primitive>) => boolean;
  isModal?: boolean;
};

const directionClasses = {
  row: 'flex-row justify-between',
  column: 'flex-col',
  'row-reverse': 'flex-row-reverse justify-between',
  'column-reverse': 'flex-col-reverse',
};

export const Footer = ({
  cta,
  location,
  direction = 'column',
  secondaryCTA,
  isCTADisabled,
  onSecondaryCTAClick,
  onCTAClick,
  isModal,
}: FooterProps) => {
  const runCTAAction = useFeatureAction({
    location,
    ...cta,
    action: cta?.action ?? 'close_modal',
  });
  const runSecondaryCTAAction = useFeatureAction({ location, ...secondaryCTA });
  const formMethods = useFormContext();

  const isDisabledCTA =
    useIsFeatureVisible(cta?.disabled, false) ||
    isCTADisabled?.(formMethods?.formState?.errors);

  const handleCTAClick = useCallback(() => {
    onCTAClick?.();
    runCTAAction?.();
  }, [onCTAClick, runCTAAction]);

  const handleSecondaryCTAClick = useCallback(() => {
    onSecondaryCTAClick?.();
    runSecondaryCTAAction?.();
  }, [onSecondaryCTAClick, runSecondaryCTAAction]);

  const ContainerComponent = isModal ? ModalFooter : 'div';

  return (
    <ContainerComponent
      className={
        isModal
          ? '!px-0'
          : cn(
              'flex justify-center gap-2 pt-6 sm:pt-12',
              directionClasses[direction]
            )
      }
    >
      <If condition={Boolean(cta?.label)}>
        <Button
          type="button"
          disabled={isDisabledCTA}
          className={cn({ 'w-full': direction === 'column' })}
          onClick={handleCTAClick}
        >
          <>
            <span
              className={cn({
                'opacity-0': formMethods?.formState?.isSubmitting,
              })}
            >
              {cta?.label}
            </span>
            {formMethods?.formState?.isSubmitting ? (
              <Spinner className="absolute text-white" />
            ) : null}
          </>
        </Button>
      </If>
      <If condition={Boolean(secondaryCTA?.label)}>
        <Button
          type="button"
          variant="ghost"
          className={cn({ 'w-full': direction === 'column' })}
          onClick={handleSecondaryCTAClick}
        >
          {secondaryCTA?.label}
        </Button>
      </If>
      <If condition={!secondaryCTA?.label && secondaryCTA?.action === 'empty'}>
        <div className={cn({ 'w-full': direction === 'column' })} />
      </If>
    </ContainerComponent>
  );
};
