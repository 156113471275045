import {
  DataModuleCell,
  DataModuleRow,
} from '@finn/design-system/modules/DataModule';
import { RequestStatus, ValueType } from '@finn/platform-modules';
import { InfoToolTip } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';

import { useFormattedValue, useValue } from '../features-data';
import { Skeleton } from './Skeleton';

export type DataRowItem = {
  key?: string;
  label: string;
  type: ValueType;
  valueLabel?: string;
  valueMap?: { [key: string]: string };
  hideWhileLoading?: boolean;
  tooltip?: {
    key?: string;
    label?: string;
    type?: 'message';
    valueType?: ValueType;
  };
  minWidth?: number;
};

export type DataRowProps = {
  config: DataRowItem;
  status?: RequestStatus;
  value?: string;
  tooltipValue?: string;
  variant?: 'default' | 'table';
  className?: string;
};

export const DataRow = ({
  config,
  status,
  value,
  tooltipValue,
  className,
  variant = 'default',
}: DataRowProps) => {
  const formattedValue = useFormattedValue(value, {
    type: config.type,
    label: config.valueLabel,
    valueMap: config.valueMap,
  });
  const formattedTooltipValue = useFormattedValue(tooltipValue, {
    type: config.tooltip?.type,
    label: config.tooltip?.label,
  });

  const isDynamicTooltip = Boolean(config?.tooltip?.key);

  if (!formattedValue && (status !== 'loading' || config.hideWhileLoading)) {
    return null;
  }

  return (
    <div
      className={cn(
        'border-pearl my-2 flex h-16 items-center justify-between border-b last:border-white',
        { 'justify-start': variant === 'table' },
        className
      )}
    >
      <div
        className={cn('flex items-center', {
          'w-1/3 md:mr-24 md:w-1/6': variant === 'table',
        })}
      >
        <span className="body-16-light mr-2">{config?.label}</span>
        <Skeleton loading={isDynamicTooltip ? status === 'loading' : false}>
          {formattedTooltipValue ? (
            <InfoToolTip content={String(formattedTooltipValue)} />
          ) : null}
        </Skeleton>
      </div>
      <Skeleton
        placeholder={<div style={{ width: config.minWidth, height: 16 }} />}
        loading={status === 'loading'}
      >
        <span
          className={cn('body-16-semibold text-right', {
            'w-2/3 break-all': variant === 'table',
          })}
        >
          {formattedValue}
        </span>
      </Skeleton>
    </div>
  );
};

const rowClassName = '!border-0 !h-12';

type RowProps = {
  label: string;
  type: DataRowItem['type'];
  value: string;
  variant?: 'default' | 'table';
  className?: string;
};

export const Row = ({ label, type, value, variant, className }: RowProps) => (
  <DataRow
    variant={variant}
    className={cn(rowClassName, className)}
    config={{ label, type }}
    value={value}
  />
);

// this row done to be used with DS Data mdoule
export const DSRow = ({
  value,
  config,
  tooltipValue,
  status,
  className,
}: DataRowProps) => {
  const formattedValue = useFormattedValue(value, {
    type: config.type,
    label: config.valueLabel,
    valueMap: config.valueMap,
  });
  const formattedTooltipValue = useFormattedValue(tooltipValue, {
    type: config.tooltip?.type,
    label: config.tooltip?.label,
  });

  if (!formattedValue && status !== 'loading') {
    return null;
  }

  return (
    <DataModuleRow>
      <DataModuleCell tooltip={String(formattedTooltipValue || '')}>
        {config.label}
      </DataModuleCell>
      <DataModuleCell className={className}>
        <Skeleton
          placeholder={
            <span
              style={{
                display: 'inline-block',
                width: config.minWidth,
                height: 16,
              }}
            />
          }
          loading={status === 'loading'}
        >
          {value !== undefined && value !== null ? formattedValue : ''}
        </Skeleton>
      </DataModuleCell>
    </DataModuleRow>
  );
};

export const DSRowWithFallback = ({
  value,
  label,
  type,
}: {
  value?: string | string[];
  label?: string;
  type?: ValueType;
}) => {
  const [valueToRender] = useValue(value, { type });

  return (
    <DataModuleRow>
      <DataModuleCell>{label}</DataModuleCell>
      <DataModuleCell>{valueToRender}</DataModuleCell>
    </DataModuleRow>
  );
};
