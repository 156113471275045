import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { LoyaltyInfo } from '@finn/platform-modules';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { useRouter } from 'next/router';

import useFormattedNumber from '../hooks/useFormattedNumber';

type LoyaltyOverviewProps = {
  data?: LoyaltyCosmicMetadata;
  loyaltyInfo?: LoyaltyInfo;
  levelIndex: number;
};

export const LoyaltyOverview = ({
  data,
  loyaltyInfo,
  levelIndex,
}: LoyaltyOverviewProps) => {
  const router = useRouter();
  const isMobile = useMediaQuery('sm');
  const currentLevel = data?.levels?.[levelIndex];
  const iconUrl = currentLevel?.icon?.url;
  const levelTitle = currentLevel?.title;
  const pointsLabel = data?.points_label;
  const totalPointsFormatted = useFormattedNumber(
    loyaltyInfo?.total_coins || 0
  );

  return (
    <div className="bg-snow m-0 mb-8 rounded px-4 py-8 md:px-6 md:py-10">
      <div className="flex items-center gap-4 md:gap-6">
        <div className="flex">
          <img
            src={iconUrl}
            alt="levelIcon"
            className="h-8 w-8 md:h-12 md:w-12"
          />
        </div>
        <div className="flex flex-col gap-4">
          <h4
            className={cn(
              isMobile
                ? 'global-t6-semibold'
                : 'mobile-t4-semibold md:web-t4-semibold'
            )}
            data-testid="overview-level-heading"
          >
            {levelTitle}
          </h4>
          <div>
            <Button
              variant="action"
              onClick={() => {
                interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
                  location: 'loyalty-how-to-earn-points',
                });
                router.push('#how-loyalty-works');
              }}
            >
              {data?.how_to_title}
            </Button>
          </div>
        </div>
        <div className="ml-auto flex flex-col items-end gap-4">
          <h4
            className={cn(
              isMobile
                ? 'global-t6-semibold'
                : 'mobile-t4-semibold md:web-t4-semibold'
            )}
            data-testid="overview-level-points"
          >
            {totalPointsFormatted}
          </h4>
          <div className="body-14-light">{pointsLabel}</div>
        </div>
      </div>
    </div>
  );
};
