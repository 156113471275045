import { ReturnType } from '@finn/b2c-cp/features-data/hooks/useReturnData';
import { Deal, useRemoteData } from '@finn/platform-modules';

export type ReturnStepStatus = 'completed' | 'disabled' | 'active';

export type ReturnStatus = {
  return_booked: {
    state: ReturnStepStatus;
    link: null;
  };
  prepare_car_for_return: {
    state: ReturnStepStatus;
    link: null;
  };
  return_in_progress: {
    state: ReturnStepStatus;
    link: null;
    return_type?: ReturnType;
  };
  final_invoice: {
    state: ReturnStepStatus;
    link: null;
  };
  return_completed: {
    state: ReturnStepStatus;
    link: null;
  };
};

const returnStatusEndpoint = (id: string) =>
  `portal/api/bff/deals/${id}/return-status`;

export const useReturnStatus = (id: string) => {
  return useRemoteData<ReturnStatus>(id ? `return-status/${id}` : null, () =>
    returnStatusEndpoint(id)
  );
};

export const useScheduledReturnStatus = (deal: Deal) => {
  const hasGreenDragonReturnScheduled =
    deal?.return_type && deal?.belongs_to_subscription_service === true;

  const dealIdIfReturnScheduled = hasGreenDragonReturnScheduled
    ? deal.id
    : null;

  return useReturnStatus(dealIdIfReturnScheduled);
};
