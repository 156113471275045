import { ChangeFee, UpdateHandoverData } from '@finn/b2c-cp/bff/core-api/types';
import { createFetcher } from '@finn/ui-utils';
import useSWR from 'swr';

export const useHandoverChangeFee = (
  dealId: string | undefined,
  handoverData: UpdateHandoverData | undefined
) => {
  const shouldFetch = dealId && !!handoverData;

  const normalizedHandoverData = handoverData
    ? {
        ...handoverData,
        // as this field is optional, it arrives as undefined but the text input makes it an empty string
        // if empty, make it undefined again to achieve caching consistency
        handover_address_extra:
          handoverData.handover_address_extra || undefined,
      }
    : undefined;

  // cache key based on dealId, API resource and data
  const cacheKey = shouldFetch
    ? `${dealId}/handover-change-fee:${JSON.stringify(normalizedHandoverData)}`
    : null;

  const { data: responseData, isLoading } = useSWR<{
    data: ChangeFee;
  }>(
    cacheKey,
    shouldFetch
      ? () =>
          createFetcher({
            baseURL: '',
            withCredentials: true,
          })({
            method: 'POST',
            url: `/portal/api/bff/deals/${dealId}/handover-change-fee`,
            body: normalizedHandoverData,
          }) as Promise<{
            data: ChangeFee;
          }>
      : null
  );

  return {
    data: responseData?.data?.amount,
    isLoading: shouldFetch === false || isLoading,
  };
};
