import { MessageBanner } from '@finn/b2c-cp/features-components/banners/MessageBanner';
import { SwapHandoverSection } from '@finn/b2c-cp/features-components/banners/SwapBanner/SwapHandoverSection';
import { If } from '@finn/b2c-cp/features-components/IfRender';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { SwapHoriz } from '@finn/design-system/icons/swap-horiz';
import { useDeals } from '@finn/platform-modules';

import { SwapReturnSection } from './SwapReturnSection';
import { useSwap } from './useSwap';

export type SwapBannerMeta = {
  title?: string;
  description?: string;
  iconName?: string;
  labelsMap?: { [key: string]: string };
  link?: {
    label?: string;
    url?: string;
  };
};

export type SwapBannerProps = {
  swap?: ReturnType<typeof useSwap>;
} & SwapBannerMeta;

export const SwapBanner = ({
  title,
  description,
  link,
  iconName,
  labelsMap,
}: SwapBannerProps) => {
  const { deals } = useDeals();
  const swap = useSwap(deals);

  if (!swap?.isSwapExpected) {
    return null;
  }

  const { returnDeal, handoverDeal, returnData, handoverData } = swap;

  return (
    <>
      <MessageBanner
        iconName={iconName}
        title={title}
        description={description}
        link={link}
      />
      <If condition={swap?.withDeliveries && Boolean(swap.returnData)}>
        <Skeleton fullWidth height="360px" loading={swap.isLoading}>
          <section className="flex flex-col items-center justify-between">
            <SwapReturnSection
              deal={returnDeal}
              returnData={returnData}
              labelsMap={labelsMap}
            />
            <SwapHoriz className="m-8 min-h-6 min-w-6" />
            <SwapHandoverSection
              deal={handoverDeal}
              handoverData={handoverData}
              labelsMap={labelsMap}
            />
          </section>
        </Skeleton>
      </If>
    </>
  );
};
