import { ReactNode } from 'react';

type TitleProps = {
  children?: ReactNode;
};

export const Title = ({ children }: TitleProps) =>
  children ? (
    <h5 className="global-t5-semibold md:web-t4-semibold mb-4">{children}</h5>
  ) : null;
