import { cn, trackEvent } from '@finn/ui-utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Link } from '../../../features-components/Link';
import { Skeleton } from '../../../features-components/Skeleton';

type ImgProps = {
  src: string;
  alt?: string;
  productId: string;
  className?: string;
  skeletonFullWidth?: boolean;
};

const MAX_WAIT_TIME_FOR_IMAGE_LOADING = 5000;

export const ProductImage = ({
  src,
  productId,
  alt = '',
  className,
  skeletonFullWidth,
}: ImgProps) => {
  const [isLoading, setLoading] = useState(true);
  const ref = useRef<HTMLImageElement>(null);
  const timerId = useRef<ReturnType<typeof setTimeout>>(null);

  const onLoad = useCallback(() => {
    setLoading(false);
    clearTimeout(timerId.current);
  }, []);

  useEffect(() => {
    if (ref.current?.complete && src) {
      onLoad();
    }

    timerId.current = setTimeout(() => {
      onLoad();
    }, MAX_WAIT_TIME_FOR_IMAGE_LOADING);

    return () => {
      clearTimeout(timerId.current);
    };
  }, [onLoad, src]);

  return (
    <Skeleton fullWidth={skeletonFullWidth ?? true} loading={isLoading}>
      <Link
        href={`/pdp/${productId}`}
        onClick={() => {
          trackEvent('Car Image Clicked', {});
        }}
      >
        <img
          ref={ref}
          className={cn(
            'object-contain transition-transform duration-200 ease-in-out hover:scale-105',
            className
          )}
          src={src}
          alt={alt}
          onLoad={onLoad}
        />
      </Link>
    </Skeleton>
  );
};
