import {
  useFeatureAction,
  useFeatureTracking,
  useIsFeatureVisible,
  useValue,
} from '@finn/b2c-cp/features-data';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { Action, FormatConfig, VisibilityConfig } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';

export type ActionBannerProps = {
  title?: string;
  description?: string;
  cta?: string;
  link?: {
    label: string;
    url: string;
  };
  config?: {
    action: Action;
    modalName?: string;
    visibilityConfig?: VisibilityConfig;
    formatConfig?: FormatConfig;
  };
};

export const ActionBanner = ({ title, cta, config }: ActionBannerProps) => {
  const withLargeFonts = !useMediaQuery('md');
  const runAction = useFeatureAction(config);
  const isVisible = useIsFeatureVisible(config?.visibilityConfig);
  const [formattedTitle] = useValue(title, config?.formatConfig);

  useFeatureTracking({ isVisible, variant: 'banner', ...config });
  if (!isVisible) {
    return null;
  }

  return (
    <a
      className="mt-4 flex cursor-pointer flex-col justify-between overflow-hidden rounded-sm bg-black"
      onClick={runAction}
    >
      <div className="flex items-center justify-between p-6">
        <div>
          <h6
            className={cn(
              'text-white',
              withLargeFonts ? 'global-t5-semibold' : 'global-t6-semibold'
            )}
          >
            {formattedTitle}
          </h6>
          {cta ? (
            <span
              className={cn(
                'mt-2 inline-flex text-white sm:mt-4',
                withLargeFonts ? 'body-16-medium' : 'body-12-medium'
              )}
            >
              {cta}
            </span>
          ) : null}
        </div>
        <KeyboardArrowRight className="fill-white sm:!h-8 sm:!w-8" />
      </div>
    </a>
  );
};
