import { createModals } from '@finn/ua-modals';

import { MODAL_UNMOUNT_DELAY_MS, ModalKey } from './constants';

const {
  ModalContainer,
  useOpenModal,
  useCloseModal,
  useCloseAllModals,
  useIsModalOpen,
  useIsAnyModalOpen,
  useOnModalOpen,
  useOnModalClose,
} = createModals<ModalKey>({
  container: { unmountDelay: MODAL_UNMOUNT_DELAY_MS },
});

export {
  ModalKey,
  ModalContainer,
  useOpenModal,
  useCloseModal,
  useCloseAllModals,
  useIsModalOpen,
  useIsAnyModalOpen,
  useOnModalOpen,
  useOnModalClose,
};
