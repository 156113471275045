import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useFormattedValue, useValue } from '@finn/b2c-cp/features-data';
import { RadioGroup, RadioItem } from '@finn/design-system/atoms/radio';
import { ToggleGroup, ToggleItem } from '@finn/design-system/atoms/toggle';
import { cn } from '@finn/ui-utils';
import { get } from 'lodash';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { ControllerProps } from 'react-hook-form';

import { Field } from '../types';

type RadioFieldProps = Field & {
  type: 'radio';
  radioItems?: {
    label: string;
    value: string;
    description?: string;
    endAdornment?: ReactNode;
  }[];
  orientation?: 'horizontal' | 'vertical';
  fullWidth?: boolean;
} & Parameters<ControllerProps['render']>['0']['field'];

type RadioItemProps = {
  value: string;
  name: string;
  label: string;
  col?: number;
  labelConfig: RadioFieldProps['item'];
  description: string;
  descriptionConfig: RadioFieldProps['description'];
  variant?: 'card' | 'circle';
  orientation?: 'horizontal' | 'vertical';
  endAdornment?: ReactNode;
};

const tailwindCols = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12',
};

const RadioGroupItem = ({
  value,
  label,
  labelConfig,
  description,
  descriptionConfig,
  variant,
  name,
  orientation = 'vertical',
  endAdornment,
}: RadioItemProps) => {
  const formattedLabel = useFormattedValue(label, labelConfig);

  const formattedDescription = useFormattedValue(
    description,
    descriptionConfig
  );

  const colSpanClass = tailwindCols[labelConfig?.col] || '';

  if (variant === 'circle') {
    return (
      <div className={cn('flex items-center space-x-2', colSpanClass)}>
        <RadioItem label={label} value={value} id={name} />
      </div>
    );
  }

  if (orientation === 'vertical') {
    return (
      <ToggleItem
        variant="extended"
        title={String(formattedLabel)}
        value={value}
        description={formattedDescription ? String(formattedDescription) : null}
        endAdornment={endAdornment}
      />
    );
  }

  return (
    <ToggleItem
      variant="regular"
      title={String(formattedLabel)}
      value={value}
      description={formattedDescription ? String(formattedDescription) : null}
    />
  );
};

// TODO merge this into useValue
// right now it is separate because useValue does not support custom data input
export const getValue = (data: unknown, path: string) => {
  const pattern = /\{.*?\}/g;
  const matches = path?.replace('form_data.', '')?.match?.(pattern);

  if (path?.startsWith('{') && path?.endsWith('}')) {
    return get(
      data,
      path?.replace('form_data.', '')?.replace(/{|}/g, '')?.split('.')
    );
  }

  return matches?.reduce((acc, match) => {
    const valueFromMatch = get(data, match?.replace(/{|}/g, '')?.split('.'));

    return acc.replace(match, `${valueFromMatch || ''}`);
  }, path);
};

export const RadioField = forwardRef(
  (
    { variant = 'card', ...field }: RadioFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [items, status] = useValue<unknown[]>(field.field);
    const { item, description } = field;

    const loadingIndicator =
      !field.radioItems && status === 'loading' ? (
        <>
          <Skeleton fullWidth height="72px" loading>
            <></>
          </Skeleton>
          <Skeleton fullWidth height="72px" loading>
            <></>
          </Skeleton>
          <Skeleton fullWidth height="72px" loading>
            <></>
          </Skeleton>
          <Skeleton fullWidth height="72px" loading>
            <></>
          </Skeleton>
        </>
      ) : null;

    if (!field.radioItems && !items?.length && status !== 'loading') {
      return null;
    }

    const Wrapper = variant === 'circle' ? RadioGroup : ToggleGroup;

    return (
      <Wrapper
        ref={ref}
        className={cn(
          'mb-4 grid w-full',
          variant === 'circle' ? 'gap-x-4 gap-y-4' : '',
          variant === 'circle'
            ? `${field.fullWidth ? 'grid-cols-1' : 'grid-cols-[1fr_1fr] md:grid-cols-2'}`
            : null,
          field.className
        )}
        onValueChange={(value) => {
          if (value) {
            field.onChange(JSON.parse(value));
          }
        }}
        defaultValue={JSON.stringify(field.defaultValue)}
        value={JSON.stringify(field.value)}
        cols={field.orientation === 'vertical' || field.fullWidth ? 1 : 2}
      >
        {loadingIndicator}
        {(field?.radioItems ?? items)?.map((curItem) => {
          const radioItem = curItem as {
            label: string;
            value: string;
            description?: string;
            endAdornment?: ReactNode;
          };

          return (
            <RadioGroupItem
              key={
                field.radioItems
                  ? radioItem?.value
                  : getValue(curItem, item?.value)
              }
              name={
                field.radioItems
                  ? radioItem?.value
                  : getValue(curItem, item?.value)
              }
              variant={variant}
              value={
                field.radioItems
                  ? JSON.stringify(radioItem?.value)
                  : JSON.stringify(curItem)
              }
              label={
                field.radioItems
                  ? radioItem?.label
                  : getValue(curItem, item?.label)
              }
              labelConfig={item}
              description={
                field.radioItems
                  ? radioItem?.description
                  : getValue(curItem, description?.label)
              }
              descriptionConfig={description}
              orientation={
                (field?.orientation ?? field.fullWidth)
                  ? 'vertical'
                  : 'horizontal'
              }
              endAdornment={radioItem?.endAdornment}
            />
          );
        })}
      </Wrapper>
    );
  }
);
