import { Button } from '@finn/design-system/atoms/button';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { SOCIAL_LOGIN_PROVIDER, trackEvent, useSession } from '@finn/ui-utils';
import Link from 'next/link';

import { DataItemProps } from '../../DataList';
import { useAccountDeletion } from '../hooks/useAccountDeletion';
import { ValueItem } from './ValueItem';

export type AccountSectionCosmicConfig = {
  section_title?: string;
  section_cta?: {
    label?: string;
    url?: string;
  };
  section_items?: DataItemProps[];
  delete_account_cta?: string;
  password_editing_unavailable?: string;
};

type AccountSectionProps = {
  content: AccountSectionCosmicConfig;
};

export const AccountSection = ({ content }: AccountSectionProps) => {
  const [session] = useSession();
  const isSocialLogin = [
    SOCIAL_LOGIN_PROVIDER.GOOGLE,
    SOCIAL_LOGIN_PROVIDER.APPLE,
  ].includes(session?.user?.provider as SOCIAL_LOGIN_PROVIDER);

  const { routeDeletionRequest } = useAccountDeletion();

  return (
    <DataModule>
      <DataModuleHeader>
        <DataModuleTitle asChild>
          <p>{content?.section_title}</p>
        </DataModuleTitle>
      </DataModuleHeader>
      <DataModuleBody>
        {(isSocialLogin
          ? [
              {
                label: '',
                value: content?.password_editing_unavailable,
              },
            ]
          : content.section_items
        ).map((item, index) => (
          <ValueItem
            key={`row-${index}`}
            label={item.label}
            value={item.value}
            valueType={item.valueType}
          />
        ))}
      </DataModuleBody>
      <DataModuleFooter>
        <Link
          href={content.section_cta?.url}
          onClick={() => {
            trackEvent('CTA Clicked', {
              location,
              label: content.section_cta?.label,
            });
          }}
        >
          <Button size="lg" variant="outline" className="w-full md:w-max">
            {content.section_cta?.label}
          </Button>
        </Link>
        {content?.delete_account_cta ? (
          <Button
            size="lg"
            variant="action"
            className="w-full md:w-max"
            onClick={routeDeletionRequest}
          >
            {content?.delete_account_cta}
          </Button>
        ) : null}
      </DataModuleFooter>
    </DataModule>
  );
};
