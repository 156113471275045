import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { getStripeEditPaymentSession } from '@finn/b2c-cp/deprecated/self-service-api';
import { InfoModal } from '@finn/b2c-cp/features-components/InfoModal';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { usePaymentMethodsData } from '@finn/b2c-cp/features-data';
import { Button } from '@finn/design-system/atoms/button';
import { Spinner } from '@finn/design-system/atoms/spinner';
import {
  DataModule,
  DataModuleBody,
  DataModuleCell,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleRow,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import { useCosmicModule, useDeals } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { trackEvent, useCurrentLocale } from '@finn/ui-utils';
import Link from 'next/link';
import { useCallback, useState } from 'react';

enum PAYMENT_METHODS {
  CARD = 'card',
  SEPA_DEBIT = 'sepa_debit',
  US_BANK_ACCOUNT = 'us_bank_account',
  PAYPAL = 'paypal',
}

const CONFIRM_CHANGING_PAYMENT_METHOD_D2C =
  'confirm_changing_payment_method_d2c';

export type PaymentSectionCosmicConfig = {
  section_title?: string;
  section_cta?: {
    label?: string;
    url?: string;
  };
  payment_methods?: {
    card?: string;
    sepa_debit?: string;
  };
  payment_change_failed_modal?: {
    title?: string;
    extra_info?: string;
    description?: string;
    CTA?: string;
  };
  payment_method_label?: string;
  payment_card_number_label?: string;
  payment_expiration_label?: string;
  payment_account_holder_label?: string;
  payment_edit_label?: string;
  payment_deal_id_label?: string;
};

type PaymentSectionContentProps = { content: PaymentSectionCosmicConfig };

const PAYMENT_ERROR_MODAL = 'PAYMENT_ERROR_MODAL';

const PaymentCTA = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="size-4">
        <Spinner />
      </div>
    );
  }

  return (
    <Button
      size="lg"
      variant="action"
      onClick={async () => {
        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}
    >
      {label}
    </Button>
  );
};

const PaymentSectionContent = ({ content }: PaymentSectionContentProps) => {
  const { locale } = useCurrentLocale();
  const { deals } = useDeals();
  const { data: paymentMethodsData } = usePaymentMethodsData();
  const { openModal } = useModalStore();
  const modalContent = useCosmicModule('change-payment-method-d2c');
  const [linkToEditPayment, setLinkToEditPayment] = useState('');

  const onEditNewPaymentRequest = async (dealId: string) => {
    const host = window.location.host;
    const isD2C = deals
      .find((subscription) => subscription.id === dealId)
      ?.recurrent_payment_method?.toLowerCase()
      ?.includes('d2c');

    const cancelRedirectUrl = `https://${host}/portal/${locale}/${SelfServiceRoutes.MYACCOUNT}?edit_payment_method_status=cancel`;
    const successRedirectUrl = `https://${host}/portal/${locale}/${SelfServiceRoutes.MYACCOUNT}?edit_payment_method_status=success`;
    // TODO: think about leveraging useSWR here and use it's status instead of loading state in PaymentMethodHeader
    const {
      status: editPaymentSessionStatus,
      data: editPaymentSessionResponse,
      // USE useSWR here to fetch edit payment session and then use useSWR in MODAL to get the cached session
      // OR MOVE FETCHING OF THE PAYMENT SESSION INSIDE MODAL AND FETCH SESSION WHEN MODAL IS RENDERED BEFORE PRESSING CONFIRM BUTTON CHECK THAT LOADING IS SHOWN WHILE FETCHING SESSION FROM THE BACKEND
    } = await getStripeEditPaymentSession(
      dealId,
      successRedirectUrl,
      cancelRedirectUrl
    );

    if (editPaymentSessionStatus === 201) {
      setLinkToEditPayment(editPaymentSessionResponse.data.session_url);
      if (isD2C) {
        openModal(CONFIRM_CHANGING_PAYMENT_METHOD_D2C);
      } else {
        window.location.replace(editPaymentSessionResponse.data.session_url);
      }
    } else {
      openModal(PAYMENT_ERROR_MODAL);
    }
  };

  const getPaymentDescription = useCallback(
    (paymentMethod) => {
      const data = {
        firstLine: content.payment_methods?.[paymentMethod.payment_type],
        secondLine: null,
      };

      if (paymentMethod.payment_type === PAYMENT_METHODS.CARD) {
        data.firstLine += ` ••••${paymentMethod.identifier_last_4}`;
        data.secondLine = `${content?.payment_expiration_label} ${paymentMethod.exp_month}/${paymentMethod.exp_year}`;
      }

      if (paymentMethod.payment_type === PAYMENT_METHODS.SEPA_DEBIT) {
        data.secondLine = `${paymentMethod.account_holder} ••••${paymentMethod.identifier_last_4}`;
      }

      if (paymentMethod.payment_type === PAYMENT_METHODS.US_BANK_ACCOUNT) {
        data.firstLine += ` ••••${paymentMethod.identifier_last_4}`;
        data.secondLine = `${content?.payment_expiration_label} ${paymentMethod.exp_month}/${paymentMethod.exp_year}`;
      }

      return data;
    },
    [content?.payment_expiration_label, content.payment_methods]
  );

  const renderErrorModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="error"
      open={open}
      onClose={onClose}
      heading={content?.payment_change_failed_modal?.title}
      htmlDescription={`${content?.payment_change_failed_modal?.description} ${content?.payment_change_failed_modal?.extra_info}`}
      affirmationText={content?.payment_change_failed_modal?.CTA}
      onAffirmation={onClose}
    />
  );

  const renderConfirmChangingPaymentMethodModal = (
    open: boolean,
    onClose: () => void
  ) => (
    <ModalBuilder
      title={modalContent?.translations?.title}
      description={modalContent?.translations?.description}
      footer={{
        cta: {
          action: 'empty',
          label: modalContent?.translations?.CTA,
          onCtaClick: () => {
            window.location.replace(linkToEditPayment);
            onClose();
          },
        },
        secondaryCTA: {
          action: 'close_modal',
          label: modalContent?.translations?.secondaryCTA,
        },
      }}
      open={open}
      onClose={onClose}
    />
  );

  return (
    <>
      {paymentMethodsData?.map((paymentMethod, index) => {
        // payment method of a deal becomes editable as soon as it's a Subscription (and thus a Stripe subscription exists with Finance)
        const isEditable = deals.some(
          (d) => d.variant === 'SUBSCRIPTION' && paymentMethod?.deal_id === d.id
        );
        const { firstLine, secondLine } = getPaymentDescription(paymentMethod);

        return (
          <DataModuleRow key={`row${index}`}>
            <DataModuleCell asChild>
              <div className="flex flex-col">
                <p className="body-14-regular text-iron before:inline after:inline">
                  {paymentMethod?.car_model}
                </p>
                <p className="body-14-regular text-iron before:inline after:inline">{`${content?.payment_deal_id_label}${paymentMethod?.deal_id}`}</p>
                <p className="body-16-semibold mt-1 before:inline after:inline">
                  {firstLine}
                </p>
                <p className="body-16-semibold before:inline after:inline">
                  {secondLine}
                </p>
              </div>
            </DataModuleCell>
            {isEditable ? (
              <DataModuleCell>
                <PaymentCTA
                  label={content?.payment_edit_label}
                  onClick={() =>
                    onEditNewPaymentRequest(paymentMethod?.deal_id)
                  }
                />
              </DataModuleCell>
            ) : null}
          </DataModuleRow>
        );
      })}
      <ModalContainer
        name={PAYMENT_ERROR_MODAL}
        renderModal={renderErrorModal}
      />
      <ModalContainer
        name={CONFIRM_CHANGING_PAYMENT_METHOD_D2C}
        renderModal={renderConfirmChangingPaymentMethodModal}
      />
    </>
  );
};

type PaymentSectionProps = {
  content: PaymentSectionCosmicConfig;
};

export const PaymentSection = ({ content }: PaymentSectionProps) => {
  const { data: paymentMethodsData } = usePaymentMethodsData();
  const hasPaymentMethods = paymentMethodsData?.length > 0;

  if (!hasPaymentMethods) {
    return null;
  }

  return (
    <DataModule>
      <DataModuleHeader>
        <DataModuleTitle asChild>
          <p>{content?.section_title}</p>
        </DataModuleTitle>
      </DataModuleHeader>
      <DataModuleBody>
        <PaymentSectionContent content={content} />
      </DataModuleBody>
      <DataModuleFooter>
        <Link
          href={content.section_cta?.url}
          onClick={() => {
            trackEvent('CTA Clicked', {
              location,
              label: content.section_cta?.label,
            });
          }}
        >
          <Button size="lg" variant="primary" className="w-full md:w-max">
            {content.section_cta?.label}
          </Button>
        </Link>
      </DataModuleFooter>
    </DataModule>
  );
};
