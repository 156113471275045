import {
  getLicenseUploadUrl,
  uploadFileBySignedUrl,
} from '@finn/b2c-cp/deprecated/images';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { FormWrapper } from '@finn/b2c-cp/features-components/Builder';
import { FeatureFormApi } from '@finn/b2c-cp/features-components/FeatureForm';
import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import {
  captureMessage,
  formatDate,
  trackEvent,
  useCurrentLocale,
  useSession,
} from '@finn/ui-utils';
import axios from 'axios';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { MessageBannerProps } from '../../features-components/banners/MessageBanner';
import { Banners } from '../Banners';
import { Button, ButtonProps } from '../button';
import { Fields } from '../Fields';
import { PageInfoBlock } from '../PageInfoBlock';

type AddDriversModuleProps = {
  title?: string;
  description?: string;
  cta?: ButtonProps & {
    errors?: {
      [key: string]: string;
    };
  };
  banners?: MessageBannerProps[];
  add_driver_form?: {
    driverData: {
      title?: string;
      fields?: Field[];
    };
    licence: {
      title?: string;
      description?: string;
      fields?: Field[];
    };
    terms: {
      fields?: Field[];
    };
  };
};

const SubmitButton = ({ cta }: { cta: ButtonProps }) => {
  const { formState } = useFormContext();

  return (
    <Button
      loading={formState.isSubmitting}
      type="submit"
      className="max-w-sm"
      {...cta}
    />
  );
};

const FormError = () => {
  const { formState } = useFormContext();

  return formState?.errors?.server ? (
    <p className="text-red text-sm">{formState?.errors?.server?.message}</p>
  ) : null;
};

export const AddDriversModule = ({
  title,
  description,
  banners,
  add_driver_form,
  cta,
}: AddDriversModuleProps) => {
  const formApiRef = useRef<FeatureFormApi>(null);
  const [session] = useSession();
  const { locale } = useCurrentLocale();
  const { driverData, licence, terms } = add_driver_form || {
    driverData: {},
    licence: {},
    terms: {},
  };
  const { openModal } = useModalStore();

  const handleSubmit = async (values) => {
    const identityInfo = {
      contactId: session?.user?.hs_contact_id ?? session?.user?.email ?? '',
      dealId: '',
      hash: dayjs().format('X'),
    };
    try {
      trackEvent('Add Driver Submitted', {});
      // Get upload urls
      const uploadData = await Promise.all([
        getLicenseUploadUrl(
          identityInfo,
          values.dl_front_key,
          'secondary_driver_front',
          locale
        ),
        getLicenseUploadUrl(
          identityInfo,
          values.dl_back_key,
          'secondary_driver_back',
          locale
        ),
      ]);
      await Promise.all([
        uploadFileBySignedUrl(
          uploadData[0].data.url,
          values?.dl_front_key,
          locale
        ),
        uploadFileBySignedUrl(
          uploadData[1].data.url,
          values?.dl_back_key,
          locale
        ),
      ]);

      const dateOfBirth = values.date_of_birth
        ? dayjs(values.date_of_birth, 'DD.MM.YYYY', true)
        : undefined;

      const payload = {
        ...values,
        date_of_birth: values.date_of_birth
          ? formatDate(dateOfBirth, 'YYYY-MM-DD')
          : undefined,
        dl_front_key: uploadData[0].data.filename,
        dl_back_key: uploadData[1].data.filename,
      };

      const { status, data } = await axios.post(
        `/portal/api/bff/drivers`,
        payload
      );
      if (status === 200) {
        trackEvent('Add Driver Submitted Successful', {});
        const driver = data?.data;
        const modalType =
          driver?.properties?.status === 'active'
            ? 'driver_added_active'
            : 'driver_added_pending';

        openModal(`${modalType}-migrated`.replaceAll('_', '-'));
      } else {
        throw new Error(`Add driver failed; server status: ${status}`);
      }
    } catch (e) {
      captureMessage(e, 500);
      formApiRef.current?.setError('server', {
        type: 'server',
        message: cta?.errors?.default,
      });
    }
  };

  const fieldsArr = useMemo(
    () => [...driverData.fields, ...licence.fields, ...terms.fields],
    [driverData, licence, terms]
  );

  return (
    <div className="grid gap-y-8">
      <PageInfoBlock
        className="!mb-0 !mt-0"
        title={title}
        description={description}
      />
      <Banners banners={banners} />
      <FormWrapper
        formApi={formApiRef}
        className="!mt-4"
        fields={fieldsArr}
        onSubmit={handleSubmit}
      >
        <h4 className="mobile-t4-semibold md:web-t4-semibold">
          {add_driver_form?.driverData?.title}
        </h4>
        <Fields fields={add_driver_form?.driverData?.fields} />
        <h4 className="mobile-t4-semibold md:web-t4-semibold">
          {add_driver_form?.licence?.title}
        </h4>
        <Fields fields={add_driver_form?.licence?.fields} />
        <Fields fields={add_driver_form?.terms?.fields} />
        <FormError />
        <SubmitButton cta={cta} />
      </FormWrapper>
    </div>
  );
};
