import { Input } from '@finn/design-system/atoms/input';
import { ValueType } from '@finn/platform-modules';

import { useValue } from '../features-data';

const dummy = () => void 0;

export type FieldElementProps = {
  type?: ValueType;
  value?: string;
  disabled?: boolean;
  label?: string;
};

export const FieldElement = ({
  type,
  value,
  label,
  disabled,
}: FieldElementProps) => {
  const [formattedValue] = useValue(value, { type });

  return (
    <Input
      name={value}
      value={String(formattedValue)}
      label={label}
      disabled={disabled}
      type="text"
      onChange={dummy}
    />
  );
};
