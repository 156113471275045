import { createCalendar } from '@internationalized/date';
import { MutableRefObject, ReactNode, useEffect, useRef } from 'react';
import {
  AriaCalendarProps,
  AriaRangeCalendarProps,
  CalendarAria,
  DateValue,
  I18nProvider,
  useCalendar,
  useRangeCalendar,
} from 'react-aria';
import {
  CalendarState,
  RangeCalendarState,
  useCalendarState,
  useRangeCalendarState,
} from 'react-stately';

import { Table } from './Table';

type BaseCalendarProps = {
  locale: string;
  renderContent: (
    props: CalendarAria,
    state: CalendarState | RangeCalendarState
  ) => ReactNode;
};

type SingleCalendarProps = {
  variant: 'single';
} & BaseCalendarProps &
  AriaCalendarProps<DateValue>;

// renders for single date selection calendar
const SingleCalendar = (props: SingleCalendarProps) => {
  const state = useCalendarState({ ...props, createCalendar });
  const calendarProps = useCalendar(props, state);
  const { value, setFocusedDate } = state;
  const setFocusedDateRef = useRef(setFocusedDate);

  // small trick to autofocus calendar on correct month
  useEffect(() => {
    if (value) {
      setFocusedDateRef?.current(value);
    }
  }, [value]);

  return props.renderContent(calendarProps, state);
};

export type RangeCalendarProps = {
  variant: 'range';
  withOneClickRangeSelection?: boolean;
  rootRef: MutableRefObject<HTMLDivElement | null>;
} & BaseCalendarProps &
  AriaRangeCalendarProps<DateValue>;

// renders for range date selection calendar
const RangeCalendarRenderer = (props: RangeCalendarProps) => {
  const state = useRangeCalendarState({ ...props, createCalendar });

  if (state.anchorDate && props.withOneClickRangeSelection) {
    state.selectDate(state.anchorDate);
  }

  const calendarProps = useRangeCalendar(props, state, props.rootRef);

  return props.renderContent(calendarProps, state);
};

type PropsToIgnore = 'locale' | 'renderContent' | 'rootRef';

// we defined calendar props as a merge of several custom props
// and those that comes from react-aria
export type CalendarProps = {
  locale?: string;
  variant?: 'single' | 'range';
  className?: string;
  cellClassName?: string;
  withOneClickRangeSelection?: boolean;
} & (
  | Omit<RangeCalendarProps, PropsToIgnore>
  | Omit<SingleCalendarProps, PropsToIgnore>
);

export const Calendar = ({
  locale = 'de-DE',
  className,
  cellClassName,
  ...props
}: CalendarProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // depending of calendar variant content will receive different props
  const renderContent: RangeCalendarProps['renderContent'] = (
    params,
    state
  ) => (
    <Table
      {...params}
      cellClassName={cellClassName}
      className={className}
      ref={ref}
      state={state}
      withOneClickRangeSelection={props.withOneClickRangeSelection}
    />
  );

  return (
    <I18nProvider locale={locale}>
      {props.variant === 'range' ? (
        <RangeCalendarRenderer
          {...props}
          rootRef={ref}
          locale={locale}
          renderContent={renderContent}
        />
      ) : (
        <SingleCalendar
          {...props}
          locale={locale}
          renderContent={renderContent}
        />
      )}
    </I18nProvider>
  );
};
