import { getFormattedLocaleDate } from '@finn/b2c-cp/deprecated/time';
import { If } from '@finn/b2c-cp/features-components/IfRender';
import { useDealId } from '@finn/b2c-cp/features-data';
import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { isAfter, useCurrentLocale } from '@finn/ui-utils';
import { FormatDateOptions } from 'react-intl';

import { MessageBanner, MessageBannerProps } from './MessageBanner';

const COMMON_DATE_FORMAT_WITH_WEEK_DAY: FormatDateOptions = {
  weekday: 'long',
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
export const DelayBanner = ({
  title,
  description,
  iconName,
}: MessageBannerProps) => {
  const dealId = useDealId();
  const { locale } = useCurrentLocale();
  const { data: handover } = useHandover(dealId);

  const preferredHandoverDate = handover?.preferred_delivery_date;
  const plannedHandoverDate = handover?.planned_delivery_date;

  const previousDeliveryDate =
    getFormattedLocaleDate(
      preferredHandoverDate,
      locale,
      COMMON_DATE_FORMAT_WITH_WEEK_DAY
    ) ?? '';

  const newDeliveryDate = plannedHandoverDate
    ? getFormattedLocaleDate(
        plannedHandoverDate,
        locale,
        COMMON_DATE_FORMAT_WITH_WEEK_DAY
      )
    : '';

  const isDeliveryDelayed = isAfter(
    new Date(plannedHandoverDate),
    new Date(preferredHandoverDate),
    'day'
  );

  const isDeliveryInTheFuture = isAfter(
    new Date(plannedHandoverDate),
    new Date(),
    'day'
  );

  return (
    <If condition={isDeliveryDelayed && isDeliveryInTheFuture}>
      <MessageBanner
        iconName={iconName}
        title={title}
        description={String(description)
          ?.replace('{previousDeliveryDate}', previousDeliveryDate)
          .replace('{newDeliveryDate}', newDeliveryDate)
          .replace('{newDeliveryTime}', handover?.slot?.formatted_time)}
      />
    </If>
  );
};
