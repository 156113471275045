// small trick to make code SSR friendly
const localStorage =
  typeof window !== 'undefined'
    ? window.localStorage
    : {
        getItem: () => null,
        setItem: () => null,
      };

export const rememberThatUserRequestedToBuyACar = (dealId: string) => {
  localStorage.setItem(`${dealId}-buy-a-car`, 'requested');
};
