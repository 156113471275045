import { COMMON_DATE_FORMAT, Locale } from '@finn/ui-utils';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FormatDateOptions } from 'react-intl';

dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

type dayjsDate = string | number | Date | dayjs.Dayjs | undefined;

export const isInThePast = (date: dayjsDate) => {
  const today = dayjs();

  return today.isAfter(date, 'day');
};

type TZLocale = Locale.ENGLISH_USA | Locale.GERMAN_GERMANY;

const TIME_ZONE_MAPPING: Record<TZLocale, string> = {
  'en-US': 'America/New_York',
  'de-DE': 'Europe/Berlin',
};

export function getFormattedLocaleDate(
  date: Date | string | undefined,
  locale: string,
  format: FormatDateOptions = COMMON_DATE_FORMAT
) {
  if (!date) {
    return '';
  }

  const timeZone = TIME_ZONE_MAPPING[locale as TZLocale];

  return new Intl.DateTimeFormat(locale, {
    ...format,
    timeZone,
  }).format(new Date(date));
}

export function setTimezone(date: Date, locale: Locale): Date {
  const zone = TIME_ZONE_MAPPING[locale as TZLocale];

  return dayjs(date).tz(zone).toDate();
}
