import { constructFormattedNumberResponse } from '@finn/ui-utils';
import { useIntl } from 'react-intl';

const useFormattedNumber = (
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const intl = useIntl();

  return constructFormattedNumberResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};

export default useFormattedNumber;
