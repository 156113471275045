import { ModalProps } from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { daysFromNowToDate } from '@finn/b2c-cp/features-data';
import { useCosmicModule } from '@finn/platform-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import { useIntl } from 'react-intl';

import { TextBlock } from '../../TextBlock';
import { MAX_DAYS_TILL_HANDOVER } from '../helpers';

export const SuccessModal = (
  props: ModalProps & { newHandoverDate: string }
) => {
  const content = useCosmicModule('advanced-edit-handover');
  const { formatMessage } = useIntl();
  const { locale } = useCurrentLocale();
  const daysTillHandover = daysFromNowToDate(props.newHandoverDate);

  return (
    <ModalBuilder
      location="handover"
      open={props.open}
      onClose={props.onClose}
      withAutoClose={false}
      footer={{
        cta: { action: 'close_modal', label: content?.success_modal?.cta },
      }}
    >
      <h4 className="mobile-t4-semibold md:web-t4-semibold">
        {content?.success_modal?.title}
      </h4>
      <TextBlock
        className="mt-8"
        text={formatMessage(
          {
            id:
              daysTillHandover > MAX_DAYS_TILL_HANDOVER
                ? 'editHandover.success.earlyHandoverSuccess'
                : 'editHandover.success.lateHandoverSuccess',
            defaultMessage:
              daysTillHandover > MAX_DAYS_TILL_HANDOVER
                ? content?.success_modal?.earlyHandoverSuccess
                : content?.success_modal?.lateHandoverSuccess,
          },
          {
            date: new Intl.DateTimeFormat(locale, {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            }).format(
              new Date(
                new Date(props.newHandoverDate).getTime() -
                  1000 * 60 * 60 * 24 * 10
              )
            ),
          }
        )}
      />
    </ModalBuilder>
  );
};
