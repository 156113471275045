import { FormItem, useFormField } from '@finn/b2c-cp/ui/form';
import { Checkbox } from '@finn/design-system/atoms/checkbox';
import { cn, parseToHtml } from '@finn/ui-utils';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerProps } from 'react-hook-form';

import { Field } from '../types';

type BooleanFieldProps = Field &
  Parameters<ControllerProps['render']>['0']['field'];

export const BooleanField = forwardRef(
  ({ ...field }: BooleanFieldProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { error } = useFormField();

    if (field.type !== 'boolean') {
      return null;
    }

    return (
      <FormItem
        className={cn('flex items-center gap-2 space-y-0', field.className)}
      >
        <Checkbox
          ref={ref}
          id={field.name}
          className={error ? 'border-red' : ''}
          key={field.name}
          checked={field.value}
          onCheckedChange={field.onChange}
          label={parseToHtml(field?.label)}
          error={Boolean(error)}
        />
      </FormItem>
    );
  }
);
