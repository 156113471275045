import { ModalProps } from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { useCosmicModule, useDeal } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useDealId } from '../../../features-data';
import { TextBlock } from '../../TextBlock';
import { MileagePackageRecommendationResponse } from '../ReportMileageModal';

export const SuccessModal = (
  props: ModalProps & MileagePackageRecommendationResponse
) => {
  const content = useCosmicModule('report-mileage');
  const { formatMessage } = useIntl();
  const dealId = useDealId();
  const deal = useDeal(dealId);
  const { openModal } = useModalStore();

  const monthlyMileageExcess = props?.data?.monthly_mileage_excess;
  const monthlyMileageUsage = props?.data?.monthly_mileage_usage;
  const isUpgradeRecommended = monthlyMileageExcess > 0;
  const isUpgradePossible = !!props?.data?.recommended_mileage_package;
  const isNoUpgradeRecommended = monthlyMileageExcess < 0;
  const isFallback = !monthlyMileageExcess && monthlyMileageExcess !== 0;
  const mileagePackageRecommendation =
    props?.data?.recommended_mileage_package?.mileage_package;
  const additionalMileageFee = deal?.data?.additional_mileage_fee;
  const carOem = deal?.data?.car?.oem;
  const carModel = deal?.data?.car?.model;

  let footer = {};
  let text: ReactNode;

  if (isUpgradeRecommended && isUpgradePossible) {
    footer = {
      cta: {
        action: 'close_modal',
        label: content?.success?.exceeds_mileage?.cta,
        onCtaClick: () => {
          trackEvent('Report-Mileage clicked Mileage Upgrade', {});
          openModal('mileage-upgrade');
        },
      },
      secondaryCTA: {
        action: 'close_modal',
        label: content?.success?.exceeds_mileage?.secondary_cta,
        onCtaClick: () => {
          trackEvent('Report-Mileage secondary CTA clicked', {});
        },
      },
    };
    text = formatMessage(
      {
        id: 'report-mileage.success.exceeds_mileage.description',
        defaultMessage: content?.success?.exceeds_mileage?.description,
      },
      {
        lineBreak: <br />,
        monthly_mileage_usage: monthlyMileageUsage,
        mileage_package_recommendation: mileagePackageRecommendation,
        additional_mileage_fee: additionalMileageFee,
      }
    );
  } else if (isNoUpgradeRecommended) {
    footer = {
      cta: {
        action: 'close_modal',
        label: content?.success?.not_exceeds_mileage?.cta,
      },
    };
    text = formatMessage(
      {
        id: 'report-mileage.success.not_exceeds_mileage.description',
        defaultMessage: content?.success?.not_exceeds_mileage?.description,
      },
      {
        lineBreak: <br />,
        monthly_mileage_usage: monthlyMileageUsage,
      }
    );
  } else if (isFallback) {
    footer = {
      cta: {
        action: 'close_modal',
        label: content?.success?.fallback?.cta,
      },
    };
    text = formatMessage(
      {
        id: 'report-mileage.success.fallback.description',
        defaultMessage: content?.success?.fallback?.description,
      },
      {
        car_oem: carOem,
        car_model: carModel,
      }
    );
  } else {
    openModal('request-error-modal');
  }

  return (
    <ModalBuilder
      location="report-mileage"
      open={props.open}
      onClose={props.onClose}
      withAutoClose={false}
      footer={footer}
    >
      <h4 className="mobile-t4-semibold md:web-t4-semibold">
        {content?.success?.title}
      </h4>
      <TextBlock text={text} />
    </ModalBuilder>
  );
};
