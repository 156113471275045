import {
  Modal as ModalDS,
  ModalContent,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { ReactNode, useCallback, useEffect } from 'react';

import { Builder, BuilderProps } from '../../Builder';

export type ModalProps = BuilderProps & {
  canGoBack?: boolean;
  withAutoClose?: boolean;
  renderAfterContent?: () => ReactNode;
  open: boolean;
  onBack?: () => void;
  onClose?: () => void;
  variant?: 'small' | 'large';
};

export const Modal = ({
  open,
  title,
  caption,
  modules,
  description,
  footer,
  config,
  canGoBack,
  info_rows,
  banners,
  renderAfterContent,
  isCTADisabled,
  renderContent,
  onCTAClick,
  onBack,
  onClose,
  name,
  variant = 'small',
}: ModalProps) => {
  const track = useTrackingStore((state) => state.track);

  const onCloseModal = useCallback(() => {
    if (name) {
      track(TrackingEventName.MODAL_CLOSED, {
        name,
      });
    }
    onClose();
  }, [name, onClose, track]);

  // track open modal
  useEffect(() => {
    if (open && name) {
      track(TrackingEventName.MODAL_OPENED, {
        name,
      });
    }
    // some variables are ignored for dependency array to avoid potential duplicate events
  }, [open, name, track]);

  return (
    <ModalDS
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onCloseModal();
        }
      }}
      variant={variant}
    >
      <ModalContent onBack={canGoBack ? onBack : null}>
        <Builder
          isModal
          config={config}
          title={title}
          caption={caption}
          modules={modules}
          description={description}
          footer={footer}
          info_rows={info_rows}
          banners={banners}
          isCTADisabled={isCTADisabled}
          renderContent={renderContent}
          renderAfterContent={renderAfterContent}
          onCTAClick={onCTAClick}
          onSecondaryCTAClick={onCloseModal}
          waitForUserSession
        />
      </ModalContent>
    </ModalDS>
  );
};
