import { cn } from '@finn/ui-utils';
import {
  Children,
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';

import { InfoOutlined } from '../../atoms/icons/generated/info-outlined';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../atoms/tooltip';
import { getComponentDisplayName } from '../../internal/react';
import { Slot } from '../../internal/Slot';

const ConfigContext = createContext<{ size: DataModuleProps['size'] }>({
  size: 'large',
});

const useConfig = () => useContext(ConfigContext);

export type DataModuleTitleProps = {
  asChild?: boolean;
  children?: ReactNode;
  tooltip?: string;
  className?: string;
};

export const DataModuleTitle = ({
  asChild,
  className,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'p';
  const { size } = useConfig();

  return (
    <Comp
      className={cn(
        'global-t5-semibold',
        {
          'md:web-t4-semibold': size === 'large',
        },
        className
      )}
      {...props}
    />
  );
};

export const DataModuleSubtitle = ({
  asChild,
  className,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'p';
  const { size } = useConfig();

  if (!props.children) {
    return null;
  }

  return (
    <Comp
      className={cn(
        size === 'small' ? 'body-14-regular' : 'body-16-regular',
        className
      )}
      {...props}
    />
  );
};

export const DataModuleRow = ({
  asChild,
  className,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        'border-pearl flex min-h-16 justify-between gap-6 border-0 border-b border-solid py-4',
        className
      )}
      {...props}
    />
  );
};

export const DataModuleCell = ({
  asChild,
  children,
  tooltip,
  className,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';
  const { size } = useConfig();
  let hasValueComponent = false;
  let hasTitleComponent = false;
  Children.forEach(children as ReactElement[], (child) => {
    if (getComponentDisplayName(child) === 'DataModuleCellValue') {
      hasValueComponent = true;
    }
    if (getComponentDisplayName(child) === 'DataModuleCellTitle') {
      hasTitleComponent = true;
    }
  });
  const shouldCellBeWith2Rows = hasValueComponent && hasTitleComponent;

  return (
    <Comp
      className={cn(
        'flex items-center gap-0 !whitespace-normal first:text-nowrap last:justify-self-end last:text-right',
        {
          'flex-col items-start gap-y-3': shouldCellBeWith2Rows,
          'body-16-regular last:body-16-semibold':
            !shouldCellBeWith2Rows && size !== 'small',
          'body-14-regular last:body-14-semibold':
            !shouldCellBeWith2Rows && size === 'small',
        },
        className
      )}
      {...props}
    >
      <>
        {children}
        {tooltip ? (
          <Tooltip open>
            <TooltipTrigger>
              <InfoOutlined className="ml-1 h-4 w-4 cursor-pointer" />
            </TooltipTrigger>
            <TooltipContent body={tooltip} />
          </Tooltip>
        ) : null}
      </>
    </Comp>
  );
};

export const DataModuleCellTitle = ({
  children,
  className,
  ...props
}: DataModuleTitleProps) => {
  const { size } = useConfig();

  return (
    <DataModuleCell
      className={cn(
        'text-iron inline-block whitespace-normal text-left',
        size === 'small' ? 'body-12-regular' : 'body-14-regular',
        className
      )}
      {...props}
    >
      {children}
    </DataModuleCell>
  );
};

DataModuleCellTitle.displayName = 'DataModuleCellTitle';

export const DataModuleCellValue = ({
  children,
  className,
  ...props
}: DataModuleTitleProps) => {
  const { size } = useConfig();

  return (
    <DataModuleCell
      className={cn(
        'inline-block whitespace-normal !text-left text-black',
        size === 'small' ? 'body-14-semibold' : 'body-16-semibold',
        className
      )}
      {...props}
    >
      {children}
    </DataModuleCell>
  );
};

DataModuleCellValue.displayName = 'DataModuleCellValue';

export const DataModuleBody = ({ asChild, ...props }: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return <Comp {...props} />;
};

export const DataModuleHeader = ({
  asChild,
  className,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';
  const { size } = useConfig();

  return (
    <Comp
      className={cn(
        'flex flex-col',
        size === 'small' ? 'mb-4 gap-4' : 'mb-6 gap-6',
        className
      )}
      {...props}
    />
  );
};

export const DataModuleFooter = ({
  asChild,
  ...props
}: DataModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return <Comp className="mt-6 flex flex-col gap-4 md:flex-row" {...props} />;
};

type DataModuleProps = {
  children?: ReactNode;
  className?: string;
  size?: 'small' | 'large';
};

export const DataModule = ({
  children,
  size = 'large',
  className,
}: DataModuleProps) => (
  <ConfigContext.Provider value={{ size }}>
    <div className={cn('flex w-full flex-col rounded-sm', className)}>
      {children}
    </div>
  </ConfigContext.Provider>
);
