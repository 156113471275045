import { useRemoteData } from '@finn/platform-modules';

import { Company } from './useCompanyData';

const companiesEndpoint = () => `portal/api/bff/companies`;

export const useCompaniesData = (
  { shouldFetch }: { shouldFetch?: boolean } = { shouldFetch: true }
) =>
  useRemoteData<Company[]>(shouldFetch ? 'companies' : null, companiesEndpoint);
