import { cn } from '@finn/ui-utils';
import { CalendarDate, DateValue } from '@internationalized/date';
import { useRef } from 'react';
import {
  type AriaCalendarProps,
  type AriaRangeCalendarProps,
  useCalendarCell,
} from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { getSelectionData } from './helpers';

type CalendarCellProps = {
  state: RangeCalendarState | CalendarState;
  withOneClickRangeSelection?: boolean;
  date: CalendarDate;
  isDisabled?: boolean;
  className?: string;
} & (AriaCalendarProps<DateValue> | AriaRangeCalendarProps<DateValue>);

export const Cell = ({ state, className, ...props }: CalendarCellProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isUnavailable,
  } = useCalendarCell(props, state, ref);

  // we determine the selection state of the cell
  const {
    isSelectionStart,
    isSelectionEnd,
    hasWrapperSectionStart,
    hasWrapperSelectionEnd,
    hasWrapperWithInSelection,
  } = getSelectionData(state, {
    date: props.date,
    isSelected,
    withOneClickRangeSelection: props.withOneClickRangeSelection,
  });

  // if cell is selected and it is not part of range or if cell is selection start or end
  // according to figma we use large font of 16px instead of 14px
  const shouldCellHaveLargeFont =
    (isSelected && !hasWrapperWithInSelection) ||
    isSelectionStart ||
    isSelectionEnd;

  return (
    <td {...cellProps} className="relative text-center">
      {hasWrapperWithInSelection ? (
        <div className="bg-cotton absolute -left-1 h-full w-[calc(100%+0.5rem)]" />
      ) : null}
      {hasWrapperSectionStart ? (
        <div className="bg-cotton absolute left-1/2 h-full w-[calc(50%+0.25rem)]" />
      ) : null}
      {hasWrapperSelectionEnd ? (
        <div className="bg-cotton absolute right-1/2 h-full w-[calc(50%+0.25rem)]" />
      ) : null}
      <div
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={cn(
          // layout styles
          'relative mx-auto flex h-10 w-10 cursor-pointer items-center justify-center rounded-full',
          // font styles
          'body-14-regular hover:bg-cotton text-black',
          // accessability styles
          'focus-visible:ring-ring focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1',
          {
            '!body-16-regular': shouldCellHaveLargeFont,
            // define background, text and hover styles
            // for cases when we have regular cell, selected cell, start or end of selection
            'bg-black hover:bg-black': isSelected,
            '!bg-black !text-white': isSelectionStart || isSelectionEnd,
            'hover:bg-pearl bg-transparent': hasWrapperWithInSelection,

            // define disabled and unavailable styles
            'pointer-events-none': isUnavailable,
            'text-steel cursor-default': isDisabled || isUnavailable,
            'text-black': isDisabled && hasWrapperWithInSelection,
          },
          className
        )}
      >
        {
          // we use wrapper span with position relative
          // to render cross line for unavailable dates and to match the size of font
        }
        <span className="relative">
          {isUnavailable ? (
            <div className="bg-iron absolute left-0 right-0 top-1/2 h-[1px]" />
          ) : null}
          {formattedDate}
        </span>
      </div>
    </td>
  );
};
