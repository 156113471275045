import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { useValue } from '@finn/b2c-cp/features-data';
import { Calendar, toCalendarDate } from '@finn/design-system/atoms/calendar';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { ControllerProps, useFormContext } from 'react-hook-form';

import { Field } from '../types';

export const isDateAvailaible = (
  date: dayjs.Dayjs,
  availableDates: string[]
) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');

  return availableDates.some((availableDate) =>
    availableDate.startsWith(formattedDate)
  );
};

type CalendarFieldProps = Field & {
  type: 'calendar';
} & Parameters<ControllerProps['render']>['0']['field'];

export const CalendarField = ({ ...field }: CalendarFieldProps) => {
  const form = useFormContext();
  const [fromDate] = useValue(field.fromDate);
  const [toDate] = useValue(field.toDate);
  const [defaultValue] = useValue(field.defaultValue);
  const isRawData = typeof field.availableDates !== 'string';

  const [availableDates, status] = useValue<string[]>(
    isRawData
      ? ({
          paths: field.availableDates,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)
      : field.availableDates
  );

  // if field mark as disabled and has some value already set -> we want to reset this value
  // otherwise if defaultValue available and field.value not set -> we want to set field value as default value
  // otherwise if field.value is string -> we want to convert it to Date, as Calendar supports only date
  useEffect(() => {
    if (field.disabled) {
      field.value && field.onChange(null);
    } else if (defaultValue && !field.value) {
      field.onChange(new Date(defaultValue));
    } else if (typeof field.value === 'string' && Boolean(field.value)) {
      field.onChange(new Date(field.value));
    }
  }, [defaultValue, field, form]);

  // this function is used to disable dates in calendar
  // availableDates may come from BE or cosmic, if empty we don't want to disable any date
  const isDisabledDate = (date) => {
    if (availableDates?.length) {
      return !isDateAvailaible(dayjs(date), availableDates || []);
    }
    if (field.excludeWeekends) {
      // disable Sunday (0) and Saturday (6)
      return [0, 6].includes(dayjs(date).day());
    }

    return false;
  };

  // small trick to resolve mess with timazones
  // basically our server ISO time converted to local timezone maybe
  // one day before, which we want to avoid
  const maxValue = toDate
    ? toCalendarDate(toDate).add({
        days: 1,
      })
    : undefined;

  return (
    <Skeleton fullWidth loading={status === 'loading'}>
      <Calendar
        className="items-start p-0"
        cellClassName="!m-0"
        variant="single"
        isDateUnavailable={(date) => isDisabledDate(date.toDate('UTC'))}
        value={toCalendarDate(field?.value)}
        minValue={toCalendarDate(fromDate)}
        maxValue={maxValue}
        onChange={(date) => field.onChange(date.toDate('UTC'))}
      />
    </Skeleton>
  );
};
