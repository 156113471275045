import { VisibilityConfig } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';

import { useIsFeatureVisible } from '../features-data';

export type DividerProps = {
  visibilityConfig?: VisibilityConfig;
  className?: string;
  style?: object;
};

export const Divider = ({
  visibilityConfig,
  className,
  style,
}: DividerProps) => {
  const isVisible = useIsFeatureVisible(visibilityConfig, true);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cn('border-pearl w-full border-t', className)}
      style={style}
    />
  );
};
