import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { InfoModalData } from '@finn/ui-modules';
import { CookieKeys, getClientCookie, setClientCookie } from '@finn/ui-utils';
import { useEffect } from 'react';

type Props = {
  openInfoModal: (infoData: InfoModalData) => void;
  data?: LoyaltyCosmicMetadata;
};
export const useShowInfoModal = ({ openInfoModal, data }: Props) => {
  useEffect(() => {
    const alreadyShown =
      getClientCookie(CookieKeys.LOYALTY_INTRO_MODAL) === 'yes';
    if (alreadyShown) {
      return;
    }
    interactionTrackingEvent(TrackingEventName.LOYALTY_INTRO_MODAL_OPENED, {});

    openInfoModal({
      title: data.intro_modal_title,
      description: data.intro_modal_description,
      closeButtonText: data.info_modal_close_text,
      onModalClose: () => {
        setClientCookie(CookieKeys.LOYALTY_INTRO_MODAL, 'yes', 180);
        interactionTrackingEvent(
          TrackingEventName.LOYALTY_INTRO_MODAL_CLOSED,
          {}
        );
      },
    });
  }, [data, openInfoModal]);
};
