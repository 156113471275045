import { If } from '@finn/b2c-cp/features-components/IfRender';
import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { ArrowBackIos } from '@finn/design-system/icons/arrow-back-ios';
import { ArrowForwardIos } from '@finn/design-system/icons/arrow-forward-ios';
import { cn, useIntersectionObserver } from '@finn/ui-utils';
import React, { MutableRefObject, ReactNode, useRef } from 'react';

const SCROLLING_STEP = 500;

type Direction = 'left' | 'right';

type SliderProps = {
  verticalSpacing?: number;
  transformMarginValueInPx?: number;
  isGallery?: boolean;
  isForIframe?: boolean;
  className?: string;
  useContainer?: boolean;
  firstItemForwardedRef?: MutableRefObject<HTMLElement | null>;
  lastItemForwardedRef?: MutableRefObject<HTMLElement | null>;
  withinContainer?: boolean;
  children?: ReactNode;
};
const Slider = ({
  children,
  className,
  useContainer = true,
  isGallery = false,
  isForIframe = false,
  firstItemForwardedRef,
  lastItemForwardedRef,
  withinContainer,
}: SliderProps) => {
  let isMobile = useMediaQuery('md');
  const isMobileIframe = useMediaQuery('sm');
  if (isForIframe) {
    isMobile = isMobileIframe;
    useContainer = false;
  }

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const firstItemRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const rootMargin = `4000px 0px 4000px 0px`; // To get observer only on X axis
  const isFirstItemInView = useIntersectionObserver(
    isGallery ? firstItemForwardedRef : firstItemRef,
    1.0,
    rootMargin
  );
  const isLastItemInView = useIntersectionObserver(
    isGallery ? lastItemForwardedRef : lastItemRef,
    0.9,
    rootMargin
  );

  const scroll = (direction: Direction) => {
    const stepDirection = direction === 'left' ? -1 : 1;
    sliderRef.current?.scrollBy({
      top: 0,
      left: SCROLLING_STEP * stepDirection,
      behavior: 'smooth',
    });
  };

  const isLeftArrowVisible = !isMobile && !isFirstItemInView;
  const isRightArrowVisible = !isMobile && !isLastItemInView;

  const getComponent = () => {
    return (
      // fix slider going outside of screen
      <div className={cn('relative', className)}>
        <If condition={isLeftArrowVisible}>
          <Button
            variant="ghost"
            size="md"
            className="bg-steel hover:bg-iron active:bg-steel absolute left-4 top-1/2 z-10 m-auto h-16 w-16 -translate-y-1/2 rounded-full"
            onClick={() => scroll('left')}
          >
            <ArrowBackIos className="mr-0.5 min-h-8 min-w-8 fill-white" />
          </Button>
        </If>
        <div
          className={cn(
            'w-full flex-row overflow-x-auto',
            isGallery ? 'block' : 'flex'
          )}
          ref={sliderRef}
        >
          {isGallery ? null : <div ref={firstItemRef} />}
          {isGallery ? (
            <div>{children}</div>
          ) : (
            (children as React.ReactElement[]).map((component, index) => (
              <div
                className={
                  isForIframe
                    ? 'pr-6'
                    : cn('pr-6', {
                        'transform-none': withinContainer,
                      })
                }
                key={index}
              >
                {component}
              </div>
            ))
          )}
          {isGallery ? null : (
            <div
              ref={lastItemRef}
              className={cn('pr-6', {
                'transform-none': withinContainer,
              })}
            />
          )}
        </div>
        <If condition={isRightArrowVisible}>
          <Button
            variant="ghost"
            size="md"
            className="bg-steel hover:bg-iron active:bg-steel absolute right-4 top-1/2 z-10 m-auto h-16 w-16 -translate-y-1/2 rounded-full"
            onClick={() => scroll('right')}
          >
            <ArrowForwardIos className="ml-0.5 min-h-8 min-w-8 fill-white" />
          </Button>
        </If>
      </div>
    );
  };

  return (
    <>
      {(isMobile && useContainer) || withinContainer ? (
        <div className="container overflow-x-auto">{getComponent()}</div>
      ) : (
        getComponent()
      )}
    </>
  );
};

export default Slider;
