import { HandoverData, ReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { daysFromNowToDate } from '@finn/b2c-cp/features-data';
import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { Deal } from '@finn/platform-modules';
import { useMemo } from 'react';

const isHandoverDeal = (deal: Deal) =>
  deal?.previous_subscription_id &&
  deal?.subscribed_as !== 'PROLONGATION' &&
  deal?.state === 'CREATED';

const isActiveSub = (deal: Deal) => deal?.state === 'ACTIVE';

type Swap = {
  isLoading: boolean;
  handoverDeal: Deal;
  handoverData?: HandoverData;
  returnDeal: Deal;
  returnData?: ReturnData;
  isSwapExpected: boolean;
  withDeliveries: boolean;
};

export const useSwap = (deals: Deal[]): null | Swap => {
  const metadata = useMemo(() => {
    const handoverDeal = deals.find(isHandoverDeal);
    const returnDeal = deals
      .filter(isActiveSub)
      .find((sub) => sub.id === handoverDeal?.previous_subscription_id);

    const followup_deal_id = returnDeal?.followup_subscription_id;
    const isTheSameDaySwap = returnDeal?.subscribed_to === 'SWAP_SAME_DAY';

    if (!handoverDeal || !followup_deal_id || !isTheSameDaySwap) {
      return null;
    }

    const handoverDate =
      handoverDeal?.preferred_handover_date ||
      handoverDeal?.actual_handover_date;
    const returnDate =
      returnDeal?.preferred_return_date ||
      returnDeal.actual_return_date ||
      returnDeal.end_date;

    if (!handoverDate || !returnDate) {
      return null;
    }

    const gapDaysBetweenSwap = Math.abs(
      daysFromNowToDate(handoverDate.toString()) -
        daysFromNowToDate(returnDate.toString())
    );

    const withDeliveries = handoverDeal?.variant === 'SUBSCRIPTION';

    return {
      isSwapExpected: gapDaysBetweenSwap <= 30,
      withDeliveries,
      handoverDeal,
      returnDeal,
    };
  }, [deals]);

  const { data: returnData, status: returnLoadingStatus } = useReturn(
    metadata?.returnDeal?.id
  );
  const { data: handoverData, status: handoverLoadingStatus } = useHandover(
    metadata?.handoverDeal?.id
  );

  return useMemo(() => {
    if (metadata === null) {
      return null;
    }

    return {
      ...metadata,
      returnData,
      handoverData,
      isLoading: [returnLoadingStatus, handoverLoadingStatus].every(
        (status) => status === 'loading'
      ),
    };
  }, [
    handoverData,
    handoverLoadingStatus,
    metadata,
    returnData,
    returnLoadingStatus,
  ]);
};
