import { ModalProps } from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { Divider } from '@finn/b2c-cp/features/Divider';
import { TextBlock } from '@finn/b2c-cp/features/TextBlock';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { Deal, useCosmicModule } from '@finn/platform-modules';
import { CountryCode, useCurrentLocale } from '@finn/ui-utils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type IntroModalProps = ModalProps & {
  onCTAClick: () => void;
  modalVariant: 'no-fee-swap' | 'fee-swap' | 'fee' | 'no-fee' | 'loading';
  deal: Deal;
  lateChangeFee: number;
};

export const IntroModal = (props: IntroModalProps) => {
  const content = useCosmicModule('advanced-edit-handover');
  const { formatMessage, formatNumber } = useIntl();
  const { region } = useCurrentLocale();
  const { lateChangeFee, modalVariant } = props;

  const introTitle = useMemo(() => {
    if (modalVariant === 'fee') {
      return content?.intro?.fee?.title;
    }

    if (modalVariant === 'no-fee-swap') {
      return content?.intro?.noFeeSwap?.title;
    }

    return content?.intro?.title;
  }, [
    content?.intro?.fee?.title,
    content?.intro?.noFeeSwap?.title,
    content?.intro?.title,
    modalVariant,
  ]);

  return (
    <ModalBuilder
      key={modalVariant}
      open={props.open}
      onClose={props.onClose}
      withAutoClose={false}
      title={introTitle}
      footer={{
        cta: {
          action: 'open_modal',
          label: content?.intro?.cta,
          onCtaClick: props.onCTAClick,
        },
      }}
    >
      {/* indicate loading behavior while the modal variant indicates that */}
      <Skeleton loading={modalVariant === 'loading'} width="100%">
        {/* ...render the text block for the fee modal variant inside the Skeleton so that it's not empty */}
        {(modalVariant === 'fee' || modalVariant === 'loading') && (
          <TextBlock
            text={formatMessage(
              {
                id: 'content.intro.fee.description',
                defaultMessage: content?.intro?.fee?.description,
              },
              {
                price: formatNumber(lateChangeFee, {
                  currency: region === CountryCode.US ? 'USD' : 'EUR',
                  style: 'currency',
                }),
              }
            )}
          />
        )}

        {modalVariant === 'no-fee-swap' && (
          <TextBlock text={content?.intro?.noFeeSwap?.description} />
        )}

        {modalVariant === 'fee-swap' && (
          <>
            <h6 className="global-t6-semibold mt-4">
              {content?.intro?.fee?.title}
            </h6>
            <TextBlock text={content?.intro?.fee?.description} />
            <Divider />
            <h6 className="global-t6-semibold">
              {content?.intro?.noFeeSwap?.title}
            </h6>
            <TextBlock text={content?.intro?.noFeeSwap?.description} />
          </>
        )}
      </Skeleton>
    </ModalBuilder>
  );
};
