import { ValueType, VisibilityConfig } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';

import { Skeleton } from '../features-components/Skeleton';
import { useIsFeatureVisible, useValue } from '../features-data';

export type DataItemProps = {
  label: string;
  value: string | number;
  col?: number;
  valueType?: ValueType;
  visibilityConfig?: VisibilityConfig;
  placeholderWidth?: number;
};

const tailwindColSpan = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12',
};

const tailwindGridCols = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
};

const tailwindPlaceholderWidth = {
  50: 'w-[50px]',
  100: 'w-[100px]',
  150: 'w-[150px]',
  200: 'w-[200px]',
};

const DataItem = ({
  label,
  value,
  col,
  valueType,
  visibilityConfig,
  placeholderWidth,
}: DataItemProps) => {
  const [formattedValue, valueStatus] = useValue(String(value), {
    type: valueType,
  });

  const isVisible = useIsFeatureVisible(visibilityConfig, true);
  if (!isVisible) {
    return null;
  }

  const itemWidthClass = tailwindColSpan[col ?? 2];

  return (
    <div className={cn('flex flex-col font-light', itemWidthClass)}>
      <span className="text-iron text-sm">{label}</span>
      <Skeleton
        placeholder={
          <div
            className={cn(
              'h-6',
              tailwindPlaceholderWidth[placeholderWidth] ?? 'w-[100px]'
            )}
          />
        }
        loading={valueStatus === 'loading'}
      >
        <span className="inline-flex text-base">{formattedValue}</span>
      </Skeleton>
    </div>
  );
};

export type DataListProps = {
  items?: DataItemProps[];
  cols?: number;
};

export const DataList = ({ items, cols = 2 }: DataListProps) => {
  return (
    <div className={cn('grid gap-x-4 gap-y-6', tailwindGridCols[cols])}>
      {items?.map((item, index) => {
        // if cols not defined and we are at the last item and the number of items is even
        // we want to make it full size
        const col =
          item.col ??
          (index === items.length - 1 && !(items.length % 2) ? 2 : 1);

        return <DataItem key={item.label} col={col} {...item} />;
      })}
    </div>
  );
};
