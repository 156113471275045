import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { RequestStatus } from '@finn/platform-modules';

import { Fields } from '../Fields';
import { TextBlock } from '../TextBlock';

const EditAccountForm = ({
  fields,
  serverError,
  description,
  requestStatus,
}: {
  fields: Field[];
  serverError?: string;
  description?: string;
  requestStatus: RequestStatus;
}) => {
  return (
    <>
      {description ? <TextBlock text={description} className="my-4" /> : null}

      <Skeleton
        loading={requestStatus !== 'success'}
        fullWidth
        numFields={fields?.length}
      >
        <div className="grid gap-4">
          <Fields fields={fields} />
        </div>
      </Skeleton>

      {serverError ? (
        <div className="mb-8 flex items-center gap-2">
          <InfoOutlined className="fill-red" />
          <span className="text-red body-14-medium">{serverError}</span>
        </div>
      ) : null}
    </>
  );
};

export const editAccountForm = ({
  fields,
  title,
  serverError,
  footer_cta,
  description,
  requestStatus,
}: {
  fields: Field[];
  title: string;
  serverError?: string;
  footer_cta: string;
  description?: string;
  requestStatus: RequestStatus;
}) => {
  return prepareWizardStep({
    fields: [],
    title,
    render: () => (
      <EditAccountForm
        fields={fields}
        serverError={serverError}
        description={description}
        requestStatus={requestStatus}
      />
    ),
    footer: { cta: { action: 'submit', label: footer_cta } },
    isCTADisabled: (errors) => Object.keys(errors).length > 0,
  });
};
