import { FieldPath, FormatConfig, RequestStatus } from '@finn/platform-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useDealId } from '../../MetadataProvider';
import { formatValue, getValueFromPath, toArray } from './helpers';
import { pathToResources, useDataForFeature } from './useDataForFeature';

/* eslint-disable no-redeclare */
function useValue<T = string>(
  path: FieldPath,
  formatConfig?: FormatConfig
): [T, RequestStatus];
function useValue<T = string>(
  path: {
    paths: FieldPath[];
  },
  formatConfig?: FormatConfig
): [T[], RequestStatus];
function useValue<T = string>(
  path: FieldPath | { paths: FieldPath[] },
  formatConfig?: FormatConfig
): [T | T[], RequestStatus] {
  const dealId = useDealId();
  const isMultipleValues =
    typeof path !== 'undefined' &&
    typeof path !== 'number' &&
    typeof path !== 'string' &&
    'paths' in path;

  const intl = useIntl();
  const { region, locale } = useCurrentLocale();
  const valueFormatConfig = useMemo(
    () => ({ intl, region, locale, ...formatConfig }),
    [intl, region, locale, formatConfig]
  );

  const { fields, resources } = useMemo(() => {
    const pathToValue = isMultipleValues ? path.paths : [path];
    // 1. we normalize entry fields to always be array
    // 2. after we normalize each field to always be array(because some are just string and some array for fallbacks)
    // 3. after we map each field to know which resources it depends on
    // 4. we concatenate all resources to one object to feed to our data hook
    const items = toArray(pathToValue).map(toArray);

    return {
      fields: items,
      resources: items
        .map(pathToResources)
        .reduce((acc, item) => ({ ...acc, ...item.resources }), {
          deal: true,
        } as { [key: string]: boolean }),
    };
  }, [path, isMultipleValues]);

  const data = useDataForFeature(dealId, resources);

  return useMemo(() => {
    const values = fields
      .map((field) => {
        const availablePath = field.find((fieldPath) => {
          const val = getValueFromPath(fieldPath, data);

          return val !== undefined && val !== null;
        });

        return getValueFromPath(availablePath, data);
      })
      .map((value) => {
        if (valueFormatConfig?.type) {
          return formatValue(value as string, { ...valueFormatConfig });
        }
        if (
          typeof value === 'string' &&
          value?.includes('{') &&
          value?.includes('}')
        ) {
          return value?.replace(/{|}/g, '');
        }

        return value;
      });

    if (isMultipleValues) {
      return [values, data?.status] as [T[], RequestStatus];
    } else {
      return [values[0], data?.status] as [T, RequestStatus];
    }

    // return [isMultipleValues ? values : values[0], data?.status] as const;
  }, [fields, data, isMultipleValues, valueFormatConfig]);
}
/* eslint-enable */

export { useValue };
export type { FormatConfig };
