import { useRemoteData } from '@finn/platform-modules';
import { CustomerProfile } from '@finn/ua-auth';

// we use function as useRemoteData may pass some params for us
const profileEndpoint = () => `portal/api/bff/profile`;

export const useProfileData = (
  { shouldFetch }: { shouldFetch?: boolean } = { shouldFetch: true }
) =>
  useRemoteData<CustomerProfile>(
    shouldFetch ? 'profile' : null,
    profileEndpoint
  );
