import { useDealId } from '@finn/b2c-cp/features-data';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const Return = () => {
  const router = useRouter();
  const dealId = useDealId();

  useEffect(() => {
    // The :id/return page is deprecated. Therefore we move all customers with such a link to the my-car-details page
    router.replace(
      `${SelfServiceRoutes.MY_CARS}/${dealId}?modalName=MULTISTEP_EDIT_RETURN`
    );
  }, [dealId, router]);

  return null;
};
