import { UNSUPPORTED_GERMAN_ZIP_CODES_FOR_DELIVERIES } from '@finn/b2c-cp/features-components/FeatureForm/zipcodes';
import { useValue } from '@finn/b2c-cp/features-data';
import { checkPhoneNumberValidation } from '@finn/ua-auth';
import { cn, getAgeInYears, useCurrentLocale } from '@finn/ui-utils';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { isValid as isValidIBAN } from 'iban';
import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form } from '../../ui/form';
import { Field } from './types';

type FeatureFormProps<T extends z.ZodRawShape> = {
  fields: Field[];
  children: ReactNode;
  className?: string;
  onSubmit: (data: Record<string, string>) => void;
  customSchema?: z.ZodEffects<z.ZodObject<T>>;
};

export type FeatureFormApi = {
  setError?: (name: string, error: { type: string; message: string }) => void;
  getValues: () => Record<string, string | number | boolean | Date>;
  submit?: () => Promise<void>;
  validate?: (name: string[]) => Promise<Record<string, unknown>>;
};

export const FeatureForm = forwardRef(
  <T extends z.ZodRawShape>(
    {
      className,
      fields,
      children,
      onSubmit,
      customSchema,
    }: FeatureFormProps<T>,
    ref: MutableRefObject<FeatureFormApi>
  ) => {
    const { region } = useCurrentLocale();
    const [values] = useValue({
      paths: fields?.map((field) => {
        if (typeof field?.field === 'string') {
          return field?.field;
        }
        if (field?.defaultValue !== undefined && field?.defaultValue !== null) {
          return field?.defaultValue;
        }
        if (field?.field) {
          return field?.field;
        }

        return '';
      }),
    });

    const defaultValues = useMemo(
      () =>
        fields?.reduce(
          (acc, field, index) => {
            const value =
              field?.type === 'file'
                ? values[index] || field?.defaultValue || null
                : (values[index] ?? field?.defaultValue ?? undefined);

            return {
              ...acc,
              [field?.name]: value,
            };
          },
          {} as { [key: string]: string | number | boolean }
        ),
      [fields, values]
    );

    const schema = useMemo(() => {
      if (customSchema) {
        return customSchema;
      }
      const rules = {};

      const getInitialRule = (field: Field) => {
        if (field?.type === 'boolean') {
          return z.boolean();
        }
        if (field?.type === 'file') {
          return (
            z
              // if we use object here zod will remove any properties from it
              // besides the ones we define here, but in case of file
              // we need to keep all the properties, in order to be able to upload it
              .any()
              .nullable()
          );
        }

        return z.string({ required_error: field?.validation?.required });
      };

      fields?.forEach((field) => {
        const currentRule = () => rules[field?.name] || getInitialRule(field);
        if (field?.validation?.required) {
          if (field?.type === 'boolean') {
            rules[field?.name] = currentRule().refine(
              (value) => value === true,
              {
                message: field?.validation?.required,
              }
            );
          } else if (field?.type === 'file') {
            rules[field?.name] = currentRule()?.refine(
              (file) => Boolean(file?.name),
              { message: field?.validation?.required }
            );
          } else {
            rules[field?.name] = currentRule().min(
              1,
              field?.validation?.required
            );
          }
        }
        if (field?.validation?.minLength) {
          rules[field?.name] = currentRule().min(
            field?.validation?.minLength?.value,
            field?.validation?.minLength?.message
          );
        }
        if (field?.validation?.maxLength) {
          rules[field?.name] = currentRule().max(
            field?.validation?.maxLength?.value,
            field?.validation?.maxLength?.message
          );
        }
        if (field?.validation?.maxLength && field?.validation?.minLength) {
          rules[field?.name] = currentRule()
            .max(
              field?.validation?.maxLength?.value,
              field?.validation?.maxLength?.message
            )
            .min(
              field?.validation?.minLength?.value,
              field?.validation?.minLength?.message
            );
        }
        if (field?.validation?.germanZipCodeForDelivery) {
          rules[field?.name] = currentRule().refine(
            (value) => {
              if (value.length !== 5) {
                // valid until this becomes a legit german zip code, other validators are responsible for length
                return true;
              }

              const isZipCodeSupported =
                !UNSUPPORTED_GERMAN_ZIP_CODES_FOR_DELIVERIES.includes(value);

              return isZipCodeSupported;
            },
            { message: field?.validation?.germanZipCodeForDelivery?.message }
          );
        }
        if (field?.validation?.minAge) {
          rules[field?.name] = currentRule().refine(
            (date) =>
              getAgeInYears(dayjs(date, 'DD.MM.YYYY', true).toDate()) >=
              field?.validation?.minAge?.value,
            {
              message: field?.validation?.minAge?.message,
            }
          );
        }
        if (field?.validation?.maxAge) {
          rules[field?.name] = currentRule().refine(
            (date) =>
              getAgeInYears(dayjs(date, 'DD.MM.YYYY', true).toDate()) <
              field?.validation?.maxAge?.value,
            {
              message: field?.validation?.maxAge?.message,
            }
          );
        }
        if (field?.validation?.maxSize) {
          rules[field?.name] = currentRule().refine(
            (file) => file?.size < field?.validation?.maxSize?.value,
            {
              message: field?.validation?.maxSize?.message,
            }
          );
        }
        if (field?.validation?.extension) {
          rules[field?.name] = currentRule().refine(
            (file) =>
              field?.validation?.extension?.value?.includes(
                file?.name?.split('.')?.pop()
              ),
            {
              message: field?.validation?.extension?.message,
            }
          );
        }
        if (field?.validation?.validPhoneNumber) {
          rules[field?.name] = currentRule().refine(
            (phonenumber) => checkPhoneNumberValidation(phonenumber, region),
            {
              message: field?.validation?.validPhoneNumber?.message,
            }
          );
        }
        if (field?.validation?.validIBAN) {
          rules[field?.name] = currentRule().refine(
            (iban) => isValidIBAN(iban),
            {
              message: field?.validation?.validIBAN?.message,
            }
          );
        }
      });

      return z.object(rules);
    }, [customSchema, fields, region]);

    const methods = useForm({
      defaultValues,
      resolver: fields?.length ? zodResolver(schema) : undefined,
      mode: 'onChange',
    });

    useEffect(() => {
      // if we received some fields later, in async way
      // we need to update form values if they was not yet touched
      // sort of smart async default values
      if (values?.length) {
        fields?.forEach((field, index) => {
          if (values[index] && !methods.formState.touchedFields[field?.name]) {
            methods.setValue(field?.name, values[index], {
              shouldTouch: true,
              shouldValidate: false,
            });
          }
        });
      }
    }, [values, methods, fields]);

    const handleSubmit = useCallback(
      (data) =>
        onSubmit({
          ...methods.getValues(),
          ...data,
        }),
      [methods, onSubmit]
    );

    useImperativeHandle(
      ref,
      () => ({
        setError: methods.setError,
        getValues: methods.getValues,
        submit: methods.handleSubmit(handleSubmit),
        validate: async (name: string[]) => {
          await methods.trigger(name);

          return methods.formState.errors;
        },
      }),
      [methods, handleSubmit]
    );

    return (
      <Form {...methods}>
        <form
          className={cn('mt-8 grid gap-8', className)}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          {children}
        </form>
      </Form>
    );
  }
);
