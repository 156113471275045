import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useCosmicModule, useDeals } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';

import { useModalStore } from '../deprecated/modules/modals/store';
import { createAccountDeletionRequest } from '../deprecated/self-service-api';
import { InfoModal } from '../features-components/InfoModal';
import { ModalBuilder } from '../features-components/ModalBuilder';

type ConfirmDeleteAccountModalProps = {
  open: boolean;
  onClose: () => void;
};

const ACCOUNT_DELETION_REQUESTED_SUCCESSFULLY =
  'account-deletion-requested-successfully';
const ERROR_DELETE_ACCOUNT_MODAL = 'ERROR_DELETE_ACCOUNT_MODAL';
export const CONFIRM_DELETE_ACCOUNT_MODAL = 'CONFIRM_DELETE_ACCOUNT_MODAL';

const ConfirmDeleteAccountModal = ({
  open,
  onClose,
}: ConfirmDeleteAccountModalProps) => {
  const { dealsPreventingAccountDeletion } = useDeals();
  const content = useCosmicModule('confirm-delete-account');
  const { openModal } = useModalStore();

  const hasDealsPreventingDeletion = Boolean(
    dealsPreventingAccountDeletion?.length
  );

  const requestDeletion = async () => {
    const { data: wasSuccessful, status } =
      await createAccountDeletionRequest();
    if (status === 200 && wasSuccessful) {
      trackEvent('Delete Account Requested Successfull', {});
      openModal(ACCOUNT_DELETION_REQUESTED_SUCCESSFULLY);
    } else {
      openModal(ERROR_DELETE_ACCOUNT_MODAL);
    }
  };

  const translations = hasDealsPreventingDeletion
    ? content?.with_active_subscriptions
    : content?.without_active_subscriptions;

  return (
    <ModalBuilder
      title={translations?.title}
      description={translations?.description}
      footer={{
        cta: {
          action: 'empty',
          label: translations?.CTA,
          onCtaClick: () => {
            trackEvent('Delete Account Requested', {});
            hasDealsPreventingDeletion
              ? window.location.replace('/contact')
              : requestDeletion();
          },
        },
        secondaryCTA: {
          action: 'close_modal',
          label: translations?.secondaryCTA,
        },
      }}
      open={open}
      onClose={onClose}
    />
  );
};

const renderConfirmDeleteAccountModal = (
  open: boolean,
  onClose: () => void
) => {
  return <ConfirmDeleteAccountModal open={open} onClose={onClose} />;
};

export const ConfirmDeleteAccountModalContainer = () => {
  const content = useCosmicModule('confirm-delete-account');

  const renderErrorModal = (open: boolean, onClose: () => void) => (
    <InfoModal
      variant="error"
      open={open}
      onClose={onClose}
      heading={content?.error_modal?.title}
      htmlDescription={content?.error_modal?.description}
      affirmationText={content?.error_modal?.CTA}
      onAffirmation={onClose}
    />
  );

  return (
    <>
      <ModalContainer
        name={CONFIRM_DELETE_ACCOUNT_MODAL}
        renderModal={renderConfirmDeleteAccountModal}
      />
      <ModalContainer
        name={ERROR_DELETE_ACCOUNT_MODAL}
        renderModal={renderErrorModal}
      />
    </>
  );
};
