import { isSameDaySwapHandover } from '@finn/b2c-cp/bff/bff-helpers';
import ModalContainer, {
  ModalProps,
} from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { saveHandoverData } from '@finn/b2c-cp/deprecated/self-service-api';
import { getFlowVariant } from '@finn/b2c-cp/features/EditHandover/helpers';
import { useHandoverChangeFee } from '@finn/b2c-cp/features/EditHandover/hooks/useHandoverChangeFee';
import { appointmentStep } from '@finn/b2c-cp/features/EditHandover/steps/appointmentStep';
import { contactStep } from '@finn/b2c-cp/features/EditHandover/steps/contactStep';
import { IntroModal } from '@finn/b2c-cp/features/EditHandover/steps/introModal';
import { locationStep } from '@finn/b2c-cp/features/EditHandover/steps/locationStep';
import { overviewStep } from '@finn/b2c-cp/features/EditHandover/steps/overviewStep';
import { SuccessModal } from '@finn/b2c-cp/features/EditHandover/steps/successModal';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { useDealId } from '@finn/b2c-cp/features-data';
import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { Deal, useCosmicModule, useDeal } from '@finn/platform-modules';
import { trackEvent, useCurrentLocale } from '@finn/ui-utils';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

type EditHandoverModalProps = ModalProps & {
  subscription?: Deal;
};

const MULTISTEP_EDIT_HANDOVER_MODAL = 'MULTISTEP_EDIT_HANDOVER';

export const EditHandoverModal = (props: EditHandoverModalProps) => {
  const dealId = useDealId();
  const content = useCosmicModule('advanced-edit-handover');
  const { data: deal, mutate: mutateDeal } = useDeal(dealId);
  const { mutate: mutateHandover } = useHandover(dealId);
  const [newHandoverDate, setNewHandoverDate] = useState('');
  const { region } = useCurrentLocale();

  // to simulate a handover update with significant changes, assume that the address stays the same but the date changes +1 day
  // this will result in a the change fee if we're inside the short/long term change time window already
  const differentPreferredHandoverDate = useMemo(
    () => dayjs(deal?.preferred_handover_date).add(1, 'day'),
    [deal]
  );
  const { data: changeFee, isLoading: feeDataIsLoading } = useHandoverChangeFee(
    dealId,
    deal
      ? {
          handover_firstname: deal?.handover_firstname,
          handover_lastname: deal?.handover_lastname,
          handover_housenumber: deal?.handover_housenumber,
          handover_street: deal?.handover_street,
          handover_city: deal?.handover_city,
          handover_zip: deal?.handover_zip,
          handover_phone_number: deal?.handover_phone_number,
          handover_address_extra: deal?.handover_address_extra,
          preferred_handover_date: differentPreferredHandoverDate.toDate(),
        }
      : undefined
  );

  const modalVariant = getFlowVariant(
    changeFee,
    isSameDaySwapHandover(deal),
    feeDataIsLoading
  );
  const [canSubmit, setCanSubmit] = useState(true);
  const { openModal } = useModalStore();
  const [modalToShow, setModalToShow] = useState<
    'form' | 'no-fee-swap' | 'fee-swap' | 'fee' | 'success' | 'loading'
  >(modalVariant === 'no-fee' ? 'form' : modalVariant);

  const handleSubmit = useCallback(
    async (formData) => {
      const payload = {
        handover_firstname: formData.firstname,
        handover_lastname: formData.lastname,
        handover_housenumber: formData.housenumber,
        handover_street: formData.street,
        handover_city: formData.city,
        handover_zip: formData.postcode,
        handover_phone_number: formData.phone_number,
        handover_address_extra: formData.address_suffix,
        preferred_handover_date: formData.slot,
      };
      setNewHandoverDate(formData.slot);
      trackEvent('Edit Handover Submitted', {});

      const { status } = await saveHandoverData(dealId, payload);

      if (status === 200) {
        trackEvent('Edit Handover Submitted Successful', {});
        setModalToShow('success');
        mutateHandover();
        mutateDeal();
      } else {
        openModal('request-error-modal');
      }
    },
    [dealId, openModal, mutateDeal, mutateHandover]
  );

  useEffect(() => {
    if (modalVariant === 'no-fee' && !isSameDaySwapHandover(deal)) {
      setModalToShow('form');
    }
  }, [modalVariant, deal]);

  const steps = useMemo(
    () => [
      {
        ...locationStep({
          deal,
          content: {
            title: content?.location?.title,
            footerCta: content?.location?.cta,
            validation: {
              street: content?.location?.street?.validation,
              housenumber: content?.location?.housenumber?.validation,
              city: content?.location?.city?.validation,
              postcodeRequired: content?.location?.postcode?.required,
              postcodeMinLength: content?.location?.postcode?.minLength,
              postcodeMaxLength: content?.location?.postcode?.maxLength,
              germanZipCodeForDelivery:
                content?.location?.postcode?.germanZipCodeForDelivery,
            },
          },
          region,
        }),
        id: 'locationStep',
        slug: 'location',
      },
      {
        ...appointmentStep({
          deal,
          title: content?.appointment?.title,
          footerCta: content?.appointment?.cta,
        }),
        id: 'appointmentStep',
        slug: 'appointment',
      },
      {
        ...contactStep({
          deal,
          title: content?.contact.title,
          cta: content?.contact?.cta,
          phoneNumberRequiredValidation:
            content?.contact?.phone_number?.required_validation,
          phoneNumberFormatValidation:
            content?.contact?.phone_number?.format_validation,
        }),
        id: 'contactStep',
        slug: 'contact',
      },
      {
        ...overviewStep({
          deal,
          title: content?.overview?.title,
          cta: content?.overview?.cta,
          canSubmit,
          setCanSubmit: (value) => setCanSubmit(value),
        }),
        id: 'overviewStep',
        slug: 'overview',
      },
    ],
    [
      deal,
      content?.location?.title,
      content?.location?.cta,
      content?.location?.street?.validation,
      content?.location?.housenumber?.validation,
      content?.location?.city?.validation,
      content?.location?.postcode?.required,
      content?.location?.postcode?.minLength,
      content?.location?.postcode?.maxLength,
      content?.location?.postcode?.germanZipCodeForDelivery,
      content?.appointment?.title,
      content?.appointment?.cta,
      content?.contact.title,
      content?.contact?.cta,
      content?.contact?.phone_number?.required_validation,
      content?.contact?.phone_number?.format_validation,
      content?.overview?.title,
      content?.overview?.cta,
      region,
      canSubmit,
    ]
  );

  if (modalToShow === 'success') {
    return (
      <SuccessModal
        open={props.open}
        onClose={props.onClose}
        newHandoverDate={newHandoverDate}
      />
    );
  }

  if (modalToShow === 'form') {
    return (
      <ModalBuilder
        location="handover"
        key={modalToShow}
        open={props.open}
        onFormSubmit={handleSubmit}
        onClose={props.onClose}
        steps={steps}
      />
    );
  }

  return (
    <IntroModal
      deal={deal}
      open={props.open}
      onClose={props.onClose}
      onCTAClick={() => setModalToShow('form')}
      modalVariant={modalVariant}
      lateChangeFee={changeFee}
    />
  );
};

const renderNewEditHandoverModal = (open: boolean, onClose: () => void) => {
  return <EditHandoverModal open={open} onClose={onClose} />;
};

export const EditHandoverModalContainer = () => {
  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer
      name={MULTISTEP_EDIT_HANDOVER_MODAL}
      renderModal={renderNewEditHandoverModal}
    />
  );
};
