import { VisibilityConfig } from '@finn/platform-modules';

import { daysFromNowToDate } from '../../helpers';

const isValueInRange = ({
  minValue,
  maxValue,
  value,
}: {
  minValue?: number;
  maxValue?: number;
  value: number;
}) => {
  if (minValue !== undefined && maxValue !== undefined) {
    return value >= minValue && value <= maxValue;
  }
  if (minValue !== undefined) {
    return value >= minValue;
  }
  if (maxValue !== undefined) {
    return value < maxValue;
  }

  return false;
};

export const doesValueSatisfiesConfig = (
  fieldValue: string | number | boolean,
  config: VisibilityConfig
) => {
  // in case of exception we need to run
  // separate process to know should we show it or not
  if ('exceptionId' in config) {
    return false;
  }

  switch (config.type) {
    case 'days': {
      const daysFromNow = daysFromNowToDate(fieldValue as string);

      return isNaN(daysFromNow)
        ? undefined
        : isValueInRange({
            ...config,
            value: daysFromNow,
          });
    }
    case 'number': {
      return isValueInRange({ ...config, value: Number(fieldValue) });
    }
    case 'not_equal': {
      // TODO expected value is deprecated, remove it after deleted from cosmic
      const valueToCompareTo = config?.expectedValue ?? config?.value ?? null;

      return fieldValue !== valueToCompareTo;
    }
    case 'truthy': {
      return !!fieldValue;
    }
    case 'falsy': {
      return !fieldValue;
    }
    case 'equal': {
      // we use undefined as fallback, because JSON does not support undefined
      // so we want to convert null to undefined
      const valueToCompareTo = config?.value ?? null;

      return fieldValue === valueToCompareTo;
    }
    // TODO boolean is deprecated, we should use equal instead
    case 'boolean': {
      return fieldValue === config?.expectedValue;
    }
    default:
      return false;
  }
};
