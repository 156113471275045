import { VisibilityConfig } from '@finn/platform-modules';
import { ReactNode } from 'react';

import { useIsFeatureVisible } from '../features-data';

type IfProps = {
  condition?: boolean;
  children?: ReactNode;
};

type IfRenderProps = IfProps & {
  visibilityConfig?: VisibilityConfig;
};

export const If = ({ condition, children }: IfProps) =>
  condition ? <>{children}</> : null;

export const IfRender = ({
  condition,
  children,
  visibilityConfig,
}: IfRenderProps) => {
  const isVisible = useIsFeatureVisible(visibilityConfig);

  if (!isVisible) {
    return null;
  }

  return (
    <If condition={condition !== undefined ? condition : true}>{children}</If>
  );
};
