import { Button } from '@finn/design-system/atoms/button';
import { SettingsFilled } from '@finn/design-system/icons/settings-filled';
import { SupportAgent } from '@finn/design-system/icons/support-agent';
import { Action, VisibilityConfig } from '@finn/platform-modules';
import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { memo } from 'react';

import {
  useFeatureAction,
  useFeatureTracking,
  useIsFeatureVisible,
} from '../features-data';

type Icon = 'support' | 'settings';

const iconsMap = {
  support: SupportAgent,
  settings: SettingsFilled,
};

export type ServiceItem = {
  config: {
    devonly?: boolean;
    label: string;
    icon?: Icon;
    action: Action;
    modalName?: string;
    visibilityConfig?: VisibilityConfig;
  };
};

export type ServicesProps = {
  services: ServiceItem[];
};

const Service = ({ service }: { service: ServiceItem }) => {
  const runAction = useFeatureAction(service.config);
  const isVisible = useIsFeatureVisible(service.config.visibilityConfig);

  useFeatureTracking({ isVisible, ...service.config });

  if (!isVisible) {
    return null;
  }

  const Icon = iconsMap[service?.config?.icon];

  return (
    <Button variant="outline" onClick={runAction}>
      {Icon ? <Icon className="mr-1" /> : null}
      {service?.config?.label}
    </Button>
  );
};

export const Services = memo(({ services }: ServicesProps) => {
  const devPass = Cookies.get(CookieKeys.DEV_PASS);
  const cosmicHelperPass = Cookies.get(CookieKeys.COSMIC_HELPER);

  return (
    <div className="flex flex-col gap-6 md:flex-row md:flex-wrap">
      {services
        .filter((service) => {
          if (service.config.devonly) {
            const isDevMode = devPass && devPass === cosmicHelperPass;
            if (!isDevMode) {
              return false;
            }
          }

          return true;
        })
        .map((service) => (
          <Service key={service.config.label} service={service} />
        ))}
    </div>
  );
});
