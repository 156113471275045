import {
  ExecutionItem,
  ExecutionModule,
  ExecutionModuleContent,
} from '@finn/design-system/modules/ExecutionModule';
import { Action, useDeal, VisibilityConfig } from '@finn/platform-modules';
import { CosmicModuleType, PlatformCosmicModule } from '@finn/ui-cosmic';
import { getCloudinaryImgUrl } from '@finn/ui-utils';

import { Skeleton } from '../features-components/Skeleton';
import {
  useDealId,
  useFeatureAction,
  useIsFeatureVisible,
  useValue,
} from '../features-data';
import { Banners, BannerType } from './Banners';
import { Divider } from './Divider';
import ActionItem from './SubscriptionsList/components/ActionItem';
import { ProductImage } from './VehicleDetailsBlock/components/ProductImage';

type SubscriptionAction = {
  label: string;
  href?: string;
  visibilityConfig?: VisibilityConfig;
  action: Action;
  modalName?: string;
  trackingProps?: {
    eventName?: string;
    [key: string]: string | { field: string };
  };
  location?: string;
};

type SubscriptionActionProps = {
  actions?: SubscriptionAction[];
  number_of_columns?: number;
  banners: PlatformCosmicModule[];
};

export const toBannersFormat = (
  banners: {
    config?: {
      type?: string;
      component?: string;
      gap_between_moduels?: number;
    };
    data?: PlatformCosmicModule;
  }[]
) =>
  banners?.map((banner) => ({
    ...banner?.data?.metadata,
    ...banner.config,
    type:
      banner.config?.type ||
      banner?.data?.type ||
      (banner?.data?.slug as CosmicModuleType),
  })) as BannerType[];

const SubscriptionActionItem = ({
  subAction,
  ...rest
}: {
  subAction: SubscriptionAction;
}) => {
  const runAction = useFeatureAction(subAction);

  const isVisible = useIsFeatureVisible(subAction.visibilityConfig);

  const [link] = useValue(subAction.href);

  if (!isVisible) {
    return null;
  }

  return (
    <ExecutionItem href={link} withActionIcon onClick={runAction} {...rest}>
      {subAction.label}
    </ExecutionItem>
  );
};

export const SubscriptionActionModule = ({
  actions,
  number_of_columns,
  banners,
}: SubscriptionActionProps) => {
  const dealId = useDealId();
  const { data: deal, status: dealStatus } = useDeal(dealId);

  const src = deal?.car?.picture
    ? getCloudinaryImgUrl(deal?.car?.picture, {
        c: 'fill',
        dpr: 2,
        fl: 'progressive',
      })
    : null;

  return (
    <div>
      <div className="w-full">
        <div className="mb-4 flex flex-col gap-4">
          <Banners banners={toBannersFormat(banners)} />
        </div>
      </div>
      <Skeleton
        loading={!deal || dealStatus !== 'success'}
        placeholder={<div className="h-52 w-full" />}
      >
        <div className="bg-snow flex flex-col rounded">
          <div className="flex items-center px-6">
            <div className="flex grow flex-col justify-center py-4 md:py-0">
              <ActionItem deal={deal} />
              <p className="global-t5-semibold mt-3">{`${deal?.car?.oem} ${deal?.car?.model}`}</p>
            </div>
            <ProductImage
              src={src}
              productId={deal?.car?.external_product_id}
              className="h-[88px] min-h-[88px] max-w-[148px] md:h-[133px] md:min-h-[133px] md:max-w-[225px]"
              skeletonFullWidth={false}
            />
          </div>
          <Divider />
          <ExecutionModule>
            <ExecutionModuleContent
              size="md"
              cols={
                number_of_columns >= 1 && number_of_columns <= 3
                  ? (number_of_columns as 1 | 2 | 3)
                  : 3
              }
              className="p-6"
            >
              {actions?.map((item, index) => (
                <SubscriptionActionItem
                  key={item.label + index}
                  subAction={item}
                />
              ))}
            </ExecutionModuleContent>
          </ExecutionModule>
        </div>
      </Skeleton>
    </div>
  );
};
