import { updateProfile } from '@finn/b2c-cp/deprecated/self-service-api';
import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import { useProfileData } from '@finn/b2c-cp/features-data';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { trackEvent } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { Builder, FormWrapper } from '../../features-components/Builder';
import { Wizard } from '../../features-components/Wizard';
import { editAccountForm } from './EditAccountForm';

type EditAccountProps = {
  title?: string;
  footer_cta?: string;
  fields?: Field[];
  description?: string;
  server_error?: string;
};

export const EditAccount = ({
  title,
  footer_cta,
  fields,
  description,
  server_error,
}: EditAccountProps) => {
  const router = useRouter();
  const [serverError, setServerError] = useState('');
  const {
    data: profileData,
    status: profileStatus,
    mutate: mutateProfile,
  } = useProfileData();

  const handleSubmit = useCallback(
    async (data) => {
      const { birthday, firstname, lastname, ...rest } = data;
      const payload = { ...rest };
      // Include restricted fields in payload only if user is allowed to update them
      if (!profileData?.properties?.date_of_birth && birthday) {
        payload.birthday = new Date(birthday).toISOString();
      }
      if (!profileData?.properties?.firstname) {
        payload.firstname = firstname;
      }
      if (!profileData?.properties?.lastname) {
        payload.lastname = lastname;
      }
      trackEvent('Update Profile Submitted', {});
      const { status } = await updateProfile(payload);
      if (status === 200) {
        trackEvent('Update Profile Submitted Successfull', {});
        mutateProfile(); // invalidate profile data cache
        await router.push(`${SelfServiceRoutes.MYACCOUNT}`);
      } else {
        setServerError(server_error);
      }
    },
    [
      mutateProfile,
      profileData?.properties?.date_of_birth,
      profileData?.properties?.firstname,
      profileData?.properties?.lastname,
      router,
      server_error,
    ]
  );

  return (
    <FormWrapper fields={fields}>
      <Wizard
        location="edit_account"
        steps={[
          editAccountForm({
            title,
            description,
            footer_cta,
            serverError,
            fields,
            requestStatus: profileStatus,
          }),
        ]}
        renderChildren={(wizardProps) => <Builder {...wizardProps} />}
        onFormSubmit={handleSubmit}
      />
    </FormWrapper>
  );
};
