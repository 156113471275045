import { Skeleton } from '@finn/b2c-cp/features-components/Skeleton';
import { ReturnType, useDealId } from '@finn/b2c-cp/features-data';
import { useScheduledReturnStatus } from '@finn/b2c-cp/features-data/hooks/useReturnStatus';
import { Button } from '@finn/design-system/atoms/button';
import {
  ProgressModule,
  ProgressModuleBody,
  ProgressModuleHeader,
  ProgressModuleRow,
  ProgressModuleRowBody,
  ProgressModuleRowSubtitle,
  ProgressModuleRowTitle,
  ProgressModuleTitle,
} from '@finn/design-system/modules/ProgressModule';
import {
  getDataStatus,
  useCosmicModule,
  useDeal,
} from '@finn/platform-modules';
import { parseToHtml, trackEvent } from '@finn/ui-utils';
import React from 'react';

type StepType = {
  title: string;
  description: string;
  button?: {
    href: string;
    text: string;
    eventName?: string;
  };
  status: 'completed' | 'disabled' | 'active';
};

type StatusDataType = {
  [key: string]: {
    state: 'completed' | 'disabled' | 'active';
    return_type?: ReturnType;
    link: string | null;
  };
};

const toModuleStatus = (status: 'completed' | 'disabled' | 'active') => {
  switch (status) {
    case 'completed':
      return 'completed';
    case 'disabled':
      return 'upcoming';
    case 'active':
      return 'in-progress';
  }
};

function combineSteps(translations?, statusData?: StatusDataType): StepType[] {
  if (!translations || !statusData) {
    return [];
  }

  return Object.keys(statusData)
    .filter((key) => translations[key])
    .map((key) => {
      let translation = translations[key];
      if (key === 'return_in_progress') {
        // the translations object of this step is split by return type
        const returnType = statusData[key]?.return_type || ReturnType.PICKUP;
        translation = translation[returnType];
      }

      const status = statusData[key]?.state;
      const link = statusData[key]?.link;

      if (!translation || !status) {
        return null;
      }

      return {
        title: translation.title,
        description: translation.description,
        // button becomes visible once the API delivers a link
        button: link
          ? {
              href: link,
              text: translation.buttonText,
              eventName: translation.buttonTrackingEventName,
            }
          : undefined,
        status,
      };
    })
    .filter((step) => step !== null) as StepType[];
}

const ReturnStatus: React.FunctionComponent = () => {
  const dealId = useDealId();
  const content = useCosmicModule('return-status');
  const title = content?.page?.title;
  const stepTranslations = content?.step_translations;
  const { data: deal } = useDeal(dealId);

  const { data: returnStatus, status: returnStatusState } =
    useScheduledReturnStatus(deal);
  const status = getDataStatus([returnStatusState]);

  if (!returnStatus || status === 'error') {
    return null;
  }

  const steps = combineSteps(stepTranslations, returnStatus);

  return (
    <Skeleton loading={status === 'loading'}>
      <ProgressModule>
        <ProgressModuleHeader>
          <ProgressModuleTitle asChild>
            <h4>{parseToHtml(title)}</h4>
          </ProgressModuleTitle>
        </ProgressModuleHeader>
        <ProgressModuleBody>
          {steps.map((step, index) => (
            <ProgressModuleRow
              className="grid-cols-[max-content,1fr]"
              status={toModuleStatus(step.status)}
              key={index}
            >
              <ProgressModuleRowTitle>{step.title}</ProgressModuleRowTitle>
              <ProgressModuleRowSubtitle>
                {parseToHtml(step.description)}
              </ProgressModuleRowSubtitle>
              {step?.button?.text ? (
                <ProgressModuleRowBody>
                  <Button
                    target="_blank"
                    href={step?.button.href || undefined}
                    className="mt-8"
                    variant="outline"
                    onClick={() => {
                      if (step?.button?.text) {
                        window.open(step?.button.href, '_blank');

                        if (step?.button?.eventName) {
                          trackEvent(step?.button?.eventName, {});
                        }
                      }
                    }}
                  >
                    {step?.button?.text}
                  </Button>
                </ProgressModuleRowBody>
              ) : null}
            </ProgressModuleRow>
          ))}
        </ProgressModuleBody>
      </ProgressModule>
    </Skeleton>
  );
};

export { ReturnStatus };
