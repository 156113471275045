import { CallOutlined } from '@finn/design-system/icons/call-outlined';
import { parseToHtml } from '@finn/ui-utils';

export type EmergencyCallBlockProps = {
  title: string;
  description: string;
};

// TODO design-system: refactor !important styles after migration to new typography APP-1443

export const EmergencyCallBlock = ({
  title,
  description,
}: EmergencyCallBlockProps) => (
  <article className="bg-cotton grid grid-cols-[min-content_1fr] items-center gap-4 rounded px-4 py-6">
    <CallOutlined className="h-8 w-8" />
    <div>
      <h6 className="global-t6-semibold">{title}</h6>
      <div className="body-14-regular pt-3">{parseToHtml(description)}</div>
    </div>
  </article>
);
