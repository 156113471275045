import { useRouter } from 'next/router';
import { ReactNode, useCallback, useMemo } from 'react';

import { FormWrapper } from '../Builder';
import { toStepFields, Wizard, WizardProps } from '../Wizard';
import { Modal, ModalProps } from './components/Modal';

type ModalBuilderProps = { children?: ReactNode; open?: boolean } & (
  | ModalProps
  | WizardProps
);

const isModalWithWizard = (props: ModalBuilderProps): props is WizardProps =>
  'steps' in props && props?.steps?.length > 0;

export const ModalBuilder = (props: ModalBuilderProps) => {
  const router = useRouter();

  const fieldsArr = useMemo(
    () => (isModalWithWizard(props) ? toStepFields(props.steps) : []),
    [props]
  );
  const renderContent = useCallback(() => props?.children, [props]);
  const modalContent = isModalWithWizard(props) ? (
    <Wizard
      {...props}
      renderChildren={(wizardProps) => (
        <Modal
          open={props.open}
          renderContent={renderContent}
          {...props}
          {...wizardProps}
        />
      )}
    />
  ) : (
    <Modal
      renderContent={renderContent}
      onCTAClick={
        props?.footer?.cta?.redirect
          ? () => router.push(props.footer?.cta?.redirect)
          : null
      }
      {...props}
    />
  );

  if (fieldsArr.length > 0) {
    return <FormWrapper fields={fieldsArr}>{modalContent}</FormWrapper>;
  }

  return modalContent;
};
