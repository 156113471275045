import { useHandover } from '@finn/b2c-cp/features-data/hooks/useHandover';
import { usePreReturnAppraisal } from '@finn/b2c-cp/features-data/hooks/usePreReturnAppraisal';
import { useReturn } from '@finn/b2c-cp/features-data/hooks/useReturn';
import { useDeal } from '@finn/platform-modules';

import { useDealId } from '../MetadataProvider';
import { useDocuments } from './useDocuments';
import { useHandoverMetadata } from './useHandoverMetadata';
import { useOrderStatus } from './useOrderStatus';
import { useProfileData } from './useProfileData';
import { useReturnMetadata } from './useReturnMetadata';

export const useWarmUpDealData = () => {
  const dealId = useDealId();

  // this is data used in modals and other places,
  // we just warm up cache here, to have it later ready instantly
  useDeal(dealId);
  useReturn(dealId);
  useReturnMetadata(dealId);
  usePreReturnAppraisal(dealId);
  useHandover(dealId);
  useHandoverMetadata(dealId);
  useOrderStatus(dealId);
  useDocuments(dealId);
  useProfileData();
};
