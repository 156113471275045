export const MAX_DAYS_TILL_HANDOVER = 10;

export const getFlowVariant = (
  changeFee: number | undefined,
  isSwap: boolean,
  isFeeLoading: boolean
) => {
  if (isFeeLoading) {
    return 'loading';
  }

  if (changeFee === 0) {
    if (isSwap) {
      return 'no-fee-swap' as const;
    } else {
      return 'no-fee' as const;
    }
  }

  if (isSwap) {
    return 'fee-swap' as const;
  } else {
    return 'fee' as const;
  }
};
