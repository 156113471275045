import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

const tailwindCols = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
};

type GridProps = {
  cols: number;
  children: ReactNode;
};

export const Grid = ({ cols, children }: GridProps) => {
  return (
    <div className={cn('grid max-w-96 gap-4', tailwindCols[cols])}>
      {children}
    </div>
  );
};
