import { getFormattedLocaleDate } from '@finn/b2c-cp/deprecated/time';
import { Invoice } from '@finn/b2c-cp/features-data';
import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { cn, trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

const getInvoiceLabel = (status: string) => {
  if (status === 'paid') {
    return 'paid' as const;
  }

  if (status === 'void' || status === 'uncollectible') {
    return 'failed' as const;
  }

  return 'pending' as const;
};

const InvoiceItemsWrapper = ({
  children,
  isMobile,
}: {
  children: ReactNode;
  isMobile: boolean;
}) =>
  isMobile ? (
    <div className="flex w-full flex-col">{children}</div>
  ) : (
    <>{children}</>
  );

type InvoiceItemProps = {
  invoice: Invoice;
  translations: {
    invoice_labels: {
      paid: string;
      failed: string;
      pending: string;
    };
    open_invoice: string;
  };
};

export const InvoiceItem = ({ invoice, translations }: InvoiceItemProps) => {
  const i18n = useIntl();
  const { locale } = useCurrentLocale();
  const isMobile = useMediaQuery('sm');

  const invoiceLabel = getInvoiceLabel(invoice.status);

  return (
    <div
      key={invoice.id}
      className={cn(
        'my-8 grid w-full items-center justify-items-center gap-4',
        {
          'grid-cols-3': isMobile,
          'grid-cols-5': !isMobile,
        }
      )}
    >
      <InvoiceItemsWrapper isMobile={isMobile}>
        <span>{getFormattedLocaleDate(invoice.created_date, locale)}</span>
        <span
          className={cn('whitespace-nowrap font-bold', {
            'text-trustedBlue': invoiceLabel === 'pending',
            'text-green': invoiceLabel === 'paid',
            'text-red': invoiceLabel === 'failed',
          })}
        >
          {translations?.invoice_labels?.[invoiceLabel]}
        </span>
        <span>{invoice.number}</span>
      </InvoiceItemsWrapper>
      <span className="whitespace-nowrap font-bold">
        {i18n.formatNumber(invoice.total / 100, {
          style: 'currency',
          currency: 'EUR',
        })}
      </span>
      <Button
        onClick={() => trackEvent('Invoice Opened', { invoiceId: invoice.id })}
        variant="outline"
        size="md"
        href={invoice.hosted_invoice_url}
      >
        {translations?.open_invoice}
      </Button>
    </div>
  );
};
