// TODO extract it when design finalized

import { useFeatureAction } from '@finn/b2c-cp/features-data';
import { Button } from '@finn/design-system/atoms/button';
import { Action, VisibilityConfig } from '@finn/platform-modules';
import { parseToHtml } from '@finn/ui-utils';

export type ReturnRentetionBannerProps = {
  description?: string;
  cta?: string;
  config?: {
    action: Action;
    url?: string;
    visibilityConfig?: VisibilityConfig;
  };
};

export const ReturnRentetionBanner = ({
  description,
  cta,
  config,
}: ReturnRentetionBannerProps) => {
  const runAction = useFeatureAction(config);

  return (
    <div className="bg-snow p-4">
      <p className="text-sm font-light">{parseToHtml(description)}</p>
      <Button className="mt-2" variant="outline" size="md" onClick={runAction}>
        {cta}
      </Button>
    </div>
  );
};
