import { cn } from '@finn/ui-utils';
import { Children, cloneElement, ReactElement } from 'react';

import { CheckCircleOutlined } from '../../atoms/icons/generated/check-circle-outlined';
import { RadioToggleDefault } from '../../atoms/icons/generated/radio-toggle-default';
import { getComponentDisplayName } from '../../internal/react';
import { Slot } from '../../internal/Slot';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleSubtitle,
  DataModuleTitle,
  DataModuleTitleProps,
} from '../DataModule';

type ProgressModuleTitleProps = DataModuleTitleProps;

export const ProgressModuleTitle = DataModuleTitle;
export const ProgressModuleSubtitle = DataModuleSubtitle;
export const ProgressModuleBody = DataModuleBody;
export const ProgressModuleHeader = DataModuleHeader;
export const ProgressModule = DataModule;
export const ProgressModuleFooter = DataModuleFooter;

export const ProgressModuleRow = ({
  asChild,
  status,
  children,
  className,
  ...props
}: ProgressModuleTitleProps & {
  status?: 'completed' | 'in-progress' | 'upcoming';
}) => {
  const Comp = asChild ? Slot : 'div';

  let subtitle: ReactElement | undefined;
  let body: ReactElement | undefined;
  const restChildren: ReactElement[] = [];

  // we need to render subtitle separatly from the rest of the children
  // as it shown conditionally
  Children.forEach(children as ReactElement[], (child) => {
    if (getComponentDisplayName(child) === 'ProgressModuleRowSubtitle') {
      subtitle = child;
    } else if (getComponentDisplayName(child) === 'ProgressModuleRowBody') {
      body = child;
    } else {
      restChildren.push(child);
    }
  });

  return (
    <Comp
      className={cn(
        'border-pearl grid grid-cols-[max-content,max-content] items-center gap-x-4 gap-y-2 border-0 border-b border-solid py-6',
        {
          'text-steel': status === 'upcoming',
          'grid-rows-[min-content,min-content]': status === 'in-progress',
        },
        className
      )}
      {...props}
    >
      {status === 'completed' ? (
        <CheckCircleOutlined />
      ) : (
        <RadioToggleDefault
          className={cn({
            'text-steel': status === 'upcoming',
          })}
        />
      )}
      {restChildren}
      {status === 'in-progress' ? (
        <>
          {subtitle &&
            cloneElement(subtitle, {
              className: cn('col-start-2', (subtitle as any)?.props.className),
            } as any)}
          {body &&
            cloneElement(body, {
              className: cn('col-start-2', (body?.props as any)?.className),
            } as any)}
        </>
      ) : null}
    </Comp>
  );
};

export const ProgressModuleRowTitle = ({
  asChild,
  className,
  ...props
}: ProgressModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return <Comp className={cn('body-16-semibold', className)} {...props} />;
};
export const ProgressModuleRowSubtitle = ({
  asChild,
  className,
  ...props
}: ProgressModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return <Comp className={cn('body-16-regular', className)} {...props} />;
};

ProgressModuleRowSubtitle.displayName = 'ProgressModuleRowSubtitle';

export const ProgressModuleRowBody = ({
  asChild,
  className,
  ...props
}: ProgressModuleTitleProps) => {
  const Comp = asChild ? Slot : 'div';

  return <Comp className={className} {...props} />;
};

ProgressModuleRowBody.displayName = 'ProgressModuleRowBody';
