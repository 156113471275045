import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import { useCompaniesData, useCompanyData } from '@finn/b2c-cp/features-data';
import { getDataStatus } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { trackEvent } from '@finn/ui-utils';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

import { Builder, FormWrapper } from '../../features-components/Builder';
import { Wizard } from '../../features-components/Wizard';
import { editAccountForm } from './EditAccountForm';

type EditCompanyProps = {
  title?: string;
  footer_cta?: string;
  fields?: Field[];
  description?: string;
  server_error?: string;
};

export const EditCompany = ({
  title,
  footer_cta,
  fields,
  description,
  server_error,
}: EditCompanyProps) => {
  const router = useRouter();
  const [serverError, setServerError] = useState('');
  const { data: companyResults, status: companiesStatus } = useCompaniesData();
  const companyData = companyResults?.[0];
  const { status: companyStatus, mutate } = useCompanyData(companyData?.id);

  const fieldsArr = useMemo(() => {
    const [companyStreet, companyHousenumber] =
      companyData?.properties?.address?.split(', ') ?? [];
    const streetField = fields.find((field) => field.name === 'street');
    const housenumber = fields.find((field) => field.name === 'housenumber');
    streetField.field = companyStreet;
    housenumber.field = companyHousenumber;

    return fields;
  }, [companyData?.properties?.address, fields]);

  const handleSubmit = useCallback(
    async (data) => {
      const { name, housenumber, street, ...rest } = data;
      const payload = { ...rest };
      payload.address = `${street}, ${housenumber}`;
      if (!companyData.properties?.name) {
        payload.name = name;
      }

      try {
        trackEvent('Update Profile Submitted', {});
        const { status } = await axios.patch(
          `/portal/api/bff/companies/${companyData?.id}`,
          payload
        );
        if (status === 200) {
          trackEvent('Update Profile Submitted Successful', {});
          mutate({
            data: {
              data: {
                ...companyData,
                properties: {
                  ...companyData.properties,
                  address: payload.address,
                  ...rest,
                },
              },
            },
            status: 200,
          });
          await router.push(`${SelfServiceRoutes.MYACCOUNT}`);
        } else {
          setServerError(server_error);
        }
      } catch (error) {
        setServerError(server_error);
      }
    },
    [companyData, mutate, router, server_error]
  );

  return (
    <FormWrapper fields={fieldsArr}>
      <Wizard
        location="edit_company"
        steps={[
          editAccountForm({
            title,
            description,
            footer_cta,
            serverError,
            fields,
            requestStatus: getDataStatus([companyStatus, companiesStatus]),
          }),
        ]}
        renderChildren={(wizardProps) => <Builder {...wizardProps} />}
        onFormSubmit={handleSubmit}
      />
    </FormWrapper>
  );
};
