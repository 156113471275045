import {
  CosmicHelperWrapper,
  LoginModal,
  StaticSelfServiceObject,
  useDeal,
} from '@finn/platform-modules';
import { fetchCache, Json } from '@finn/platform-modules/cache/helpers';
import { useBannerDeepLink } from '@finn/ua-app';
import { Footer } from '@finn/ua-footer';
import { Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import {
  config,
  getLocaleFromContext,
  isMobileApp,
  useSession,
} from '@finn/ui-utils';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import HeadTags from '../deprecated/HeadTags';
import { Navigation, NavigationProps } from '../features/navigation';
import { If } from '../features-components/IfRender';
import { ModalsRegistry } from '../features-components/ModalsRegistry';
import { PlatformComponent } from '../features-components/PlatformComponent';
import { MetadataProvider } from '../features-data/MetadataProvider';

export type UniversalPageBuilderProps = {
  pageId?: string;
  params?: string[];
  page: StaticSelfServiceObject | null;
  navigationModule?: {
    metadata?: NavigationProps;
  };
};

const UniversalPageBuilder = ({
  page,
  navigationModule,
}: UniversalPageBuilderProps) => {
  const metadata = page?.metadata;
  const router = useRouter();
  // we use deal id from query
  // this query is not visible in browser url
  // as self service used under UA domain
  // so UA shows /mycars/1234 but self service will see /my-car-details?dealId=1234
  // inside of the router object
  const dealId = router.query.dealId as string;

  const [session, isSessionLoading] = useSession();

  useDeal(dealId, (httpStatus) => {
    if (httpStatus === 404 && router.isReady) {
      router.push('/404');
    }
  });

  useEffect(() => {
    if (!metadata) {
      router.push('/404');
    }
  }, [metadata, router]);

  const withHeader = Boolean(metadata?.header?.metadata) && !isMobileApp();
  const withFooter = Boolean(metadata?.footer?.metadata) && !isMobileApp();
  const smartBannerConfig = metadata?.smart_banners;

  const { bannerDeepLink } = useBannerDeepLink({
    ...smartBannerConfig,
    deepLink: smartBannerConfig?.deepLink
      ? `${config.FINN_APP_SCHEME}${smartBannerConfig.deepLink}`
      : undefined,
  });

  const pageMetadata = useMemo(() => ({ dealId }), [dealId]);

  return (
    <MetadataProvider value={pageMetadata}>
      <CosmicHelperWrapper data={page} preview={false} />
      <HeadTags
        tabTitle={metadata?.page_title}
        seoHeader={metadata?.seo_header}
        appDeepLink={bannerDeepLink}
      />
      <If condition={withHeader}>
        <Header data={metadata?.header?.metadata} />
        <ModalContainer
          modalKey={ModalKey.LOGIN}
          ModalComponent={LoginModal}
          cartURL=""
        />
      </If>
      <main
        className="relative mx-auto my-0 mt-8 flex h-full w-full max-w-[calc(100vw-2rem)] flex-col gap-14 md:mt-20 md:max-w-[calc(100vw-4rem)] md:flex-row md:gap-20 lg:max-w-[1260px]"
        style={{ gap: metadata?.global_modules_config.gap_between_modules }}
      >
        <If
          condition={
            metadata?.modules?.length > 0 &&
            (isSessionLoading || Boolean(session))
          }
        >
          <Navigation {...navigationModule?.metadata} />
        </If>
        <div className="flex w-full flex-col gap-14 md:gap-20">
          {metadata?.modules?.map((module) => (
            <PlatformComponent key={module.id} module={module} />
          ))}
        </div>
      </main>
      <div className="mt-24 flex flex-1 sm:mt-36" />
      <If condition={withFooter}>
        <Footer data={metadata?.footer?.metadata} variant="minimal" />
      </If>
      <ModalsRegistry modals={metadata?.modals} />
    </MetadataProvider>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  /* istanbul ignore next  */
  if (process.env.NODE_ENV === 'development') {
    // keep in mind cosmic changes visible only on second page reload
    require('../../../libs/platform-modules/scripts/prepare-cache/generator').generateCache();
  }

  const { pagesMapByLocale, modules } = await fetchCache();
  let slugs = ctx.params?.slug || [];

  if (slugs?.[0] === 'mobile') {
    // back compatibility for older versions of mobile app
    slugs = slugs.slice(1);
  }
  const locale = getLocaleFromContext(ctx);

  const navigationModule = Object.values(modules).find(
    (module: Json) => module.slug === 'navigation'
  );

  return {
    props: {
      params: slugs,
      navigationModule,
      page: pagesMapByLocale?.[locale]?.[slugs[0]] || null,
    },
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { pages } = await fetchCache();

  return {
    paths: pages.map((page) => ({
      params: {
        slug: [page?.slug],
        locale: page?.locale,
      },
    })),
    fallback: 'blocking',
  };
};

export default UniversalPageBuilder;
