import { ModalProps } from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { Check } from '@finn/design-system/icons/check';
import { ErrorOutlined } from '@finn/design-system/icons/error-outlined';
import { parseToHtml } from '@finn/ui-utils';
import React from 'react';

type InfoModalProps = ModalProps & {
  variant?: 'success' | 'error';
  heading: string;
  htmlDescription?: string;
  affirmationText: string;
  onAffirmation?: () => void;
};

export const InfoModal = ({
  open,
  variant = 'success',
  onClose,
  heading,
  htmlDescription,
  affirmationText,
  onAffirmation,
}: InfoModalProps) => {
  return (
    <ModalBuilder
      open={open}
      renderContent={() => {
        return (
          <div className="grid w-full justify-center gap-4">
            {variant === 'success' ? (
              <Check className="m-auto h-14 w-14" />
            ) : (
              <ErrorOutlined className="m-auto h-14 w-14" />
            )}
            <h3 className="mobile-t3-semibold md:web-t3-semibold text-center">
              {heading}
            </h3>
            <p className="body-14-regular m-auto">
              {parseToHtml(htmlDescription ?? '')}
            </p>
          </div>
        );
      }}
      footer={{
        cta: {
          action: 'empty',
          label: affirmationText,
          onCtaClick: () => {
            onAffirmation?.();
            onClose?.();
          },
        },
      }}
      onClose={onClose}
    />
  );
};
