import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { Field } from '@finn/b2c-cp/features-components/FeatureForm/types';
import { changePassword, validatePassword } from '@finn/ua-auth';
import { Locale, trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { useCallback, useState } from 'react';
import { z } from 'zod';

import { Builder, FormWrapper } from '../../features-components/Builder';
import { Wizard } from '../../features-components/Wizard';
import { changePasswordForm } from './ChangePasswordForm';

type ChangePasswordFormProps = {
  title?: string;
  translations: {
    error_field_required?: string;
    error_same_password?: string;
    error_password_not_matching?: string;
    error_weak_password?: string;
    server_errors?: {
      wrong_password?: string;
      user_does_not_exist?: string;
      user_already_exist?: string;
      general_error?: string;
    };
  };
  footer_cta?: string;
  fields?: Field[];
};

const PASSWORD_CHANGED_SUCCESSFULLY = 'password-changed-successfully';

export const ChangePassword = ({
  translations,
  title,
  footer_cta,
  fields,
}: ChangePasswordFormProps) => {
  const { locale } = useCurrentLocale();
  const [serverError, setServerError] = useState('');
  const { openModal } = useModalStore();

  const handleSubmit = useCallback(
    async (data) => {
      trackEvent('Change Password Submitted', {});
      const result = await changePassword(
        data.currentPassword,
        data.newPassword,
        locale as Locale
      );

      if (result.ok) {
        trackEvent('Change Password Submitted Successful', {});
        openModal(PASSWORD_CHANGED_SUCCESSFULLY);
      } else {
        setServerError(result.error);
      }
    },
    [locale, openModal]
  );

  const validationSchema = z
    .object({
      currentPassword: z
        .string({
          required_error: translations?.error_field_required,
        })
        .min(1, translations?.error_field_required),
      newPassword: z
        .string({
          required_error: translations?.error_field_required,
        })
        .min(1, translations?.error_field_required),
      confirmedNewPassword: z
        .string({
          required_error: translations?.error_field_required,
        })
        .min(1, translations?.error_field_required),
    })
    .superRefine((data, ctx) => {
      if (data.newPassword === data.currentPassword) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translations?.error_same_password,
          path: ['newPassword'],
        });
      }
      if (data.confirmedNewPassword !== data.newPassword) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translations?.error_password_not_matching,
          path: ['confirmedNewPassword'],
        });
      }
      if (!validatePassword(data.newPassword)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translations?.error_weak_password,
          path: ['newPassword'],
        });
      }
    });

  return (
    <FormWrapper fields={fields} customSchema={validationSchema}>
      <Wizard
        location="change_password"
        steps={[
          {
            ...changePasswordForm({
              title,
              footer_cta,
              serverError,
              serverErrorMessage:
                translations?.server_errors?.[serverError] ??
                translations?.server_errors?.general_error,
              fields,
            }),
          },
        ]}
        renderChildren={(wizardProps) => <Builder {...wizardProps} />}
        onFormSubmit={handleSubmit}
      />
    </FormWrapper>
  );
};
