import { HandoverData } from '@finn/b2c-cp/bff/core-api/types';
import { Deal, useRemoteData } from '@finn/platform-modules';

const handoverEndpoint = (id: string) => `portal/api/bff/deals/${id}/handover`;

export const useHandover = (id: string) => {
  return useRemoteData<HandoverData>(
    id && id !== 'no-deal' ? `handover/${id}` : null,
    () => handoverEndpoint(id)
  );
};

export const usePendingHandover = (deal: Deal) => {
  const dealIdIfHandoverPending =
    deal?.state === 'CREATED' && deal?.variant === 'SUBSCRIPTION'
      ? deal.id
      : null;

  return useHandover(dealIdIfHandoverPending);
};
