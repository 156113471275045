import { Deal } from '@finn/platform-modules';

export const isSameDaySwapReturn = (deal?: Deal) => {
  if (deal?.subscribed_to) {
    return deal?.subscribed_to === 'SWAP_SAME_DAY';
  }

  return false;
};

export const isSameDaySwapHandover = (deal?: Deal) => {
  if (deal?.subscribed_as) {
    return deal?.subscribed_as === 'SWAP_SAME_DAY';
  }

  return false;
};

export const isProlongation = (deal: Deal) =>
  deal?.subscribed_as === 'PROLONGATION';

const RETENTION_WINDOW = 90;

const isDealProlongedOrNotActive = (deal?: Deal) => {
  if (deal?.variant === 'LEAD') {
    return true;
  }
  if (deal?.term_type === 'OPEN_ENDED') {
    return false;
  }
  if (deal?.state !== 'CREATED' && deal?.state !== 'ACTIVE') {
    return true;
  }
  if (deal?.followup_subscription_id && deal?.return_type) {
    return true;
  }

  return false;
};

export const isRententionWindowOpen = (deal: Deal) => {
  if (isDealProlongedOrNotActive(deal)) {
    return false;
  }

  const daysLeftForReturn = deal?.days_left_to_schedule_return;

  return (
    daysLeftForReturn &&
    daysLeftForReturn > 0 &&
    daysLeftForReturn <= RETENTION_WINDOW
  );
};
