import { config, generateLocalizationHeaders } from '@finn/ui-utils';
import * as Sentry from '@sentry/node';
import axios from 'axios';

// Get pre-signed urls for file Driver License uploads
export const getLicenseUploadUrl = async (
  identityInfo: {
    contactId: number | string;
    dealId: number | string;
    hash: string;
  },
  file: File,
  nameSuffix: string,
  locale: string
) => {
  const typeOfDestination = 'DL';
  const { name, type } = file;
  const ext = name.substring(name.lastIndexOf('.'));
  const filename = `${identityInfo.contactId}_${identityInfo.dealId}_${identityInfo.hash}_${nameSuffix}${ext}`;
  const url = `${config.CHECKOUT_API_URL}/uploadurl`;
  const { status, data } = await axios.get(url, {
    params: {
      filename,
      filetype: type,
      bucket: typeOfDestination,
    },
    headers: {
      ...generateLocalizationHeaders(locale),
      'X-Request-Source': 'web',
      'X-Request-ABS': 'enabled',
    },
  });

  return { status, data };
};

// Upload files directly to s3 using pre-signed urls
export const uploadFileBySignedUrl = async (
  url: string,
  file: File,
  locale: string
) => {
  const options = {
    headers: {
      'Content-Type': file.type,
      ...generateLocalizationHeaders(locale),
    },
  };

  try {
    const { data, status } = await axios.put(url, file, options);

    return { data, status };
  } catch (err: unknown) {
    Sentry.captureException(err);
    await Sentry.flush(2000);
    const error = err as { status: string; statusCode: number };
    const statusCode = error.status || error.statusCode || 500;

    return { status: statusCode, data: err };
  }
};
