import {
  useCurrentDeal,
  useDealId,
  useFeatureAction,
} from '@finn/b2c-cp/features-data';
import { parametrizeUrl } from '@finn/b2c-cp/features-data/helpers';
import { Primitive, Step } from '@finn/platform-modules';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { trackFeatureAction } from '../features-data/hooks/useFeatureAction';
import { BuilderProps } from './Builder';
import { getValue } from './FeatureForm/components/RadioField';

type StepWithOptionalSlug = Omit<Step, 'id' | 'slug'> &
  Partial<Pick<Step, 'id' | 'slug'>>;

export type WizardProps = BuilderProps & {
  withAutoClose?: boolean;
  steps: StepWithOptionalSlug[];
  location?: string;
  renderChildren: (
    props: BuilderProps & {
      sub_description_pickup?: BuilderProps['description'];
      sub_description_self_return?: BuilderProps['description'];
      currentStepIndex?: number;
      canGoBack: boolean;
      onBack?: () => void;
      onClose?: () => void;
    }
  ) => ReactNode;
  onClose?: () => void;
  onFormSubmit?: (data: { [key: string]: Primitive }) => Promise<void>;
};

export const prepareWizardStep = ({
  render,
  fields,
  footer,
  isCTADisabled,
  title,
}: {
  render: Step['metadata']['render'];
  footer: Step['metadata']['footer'];
  fields: Step['fields'];
  isCTADisabled?: (errors: Record<string, string>) => boolean;
  title: Step['metadata']['title'];
}): Omit<Step, 'id' | 'slug'> => {
  return { fields, metadata: { render, footer, isCTADisabled, title } };
};

export const toStepFields = (steps: StepWithOptionalSlug[]) =>
  steps
    ?.map(
      (step) =>
        step?.fields ||
        step?.metadata?.modules.map(
          (module) => module?.metadata?.fields || module?.fields
        )
    )
    // flat(2) takes the 2-level deep array and makes 1-dim arr [[[{}, {}...], [{}, {}...]...]] -> [{}, {}, ...]
    .flat(2);

const convertStepsToCodeFormat = (steps: StepWithOptionalSlug[]) =>
  (steps.map((step) => ({
    ...step,
    ...step?.metadata,
  })) as Step['metadata'][]) || [];

export const Wizard = ({
  title,
  steps,
  withAutoClose,
  location,
  renderChildren,
  onFormSubmit,
  onClose,
}: WizardProps) => {
  const dealId = useDealId();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const adjustedSteps = useMemo(() => convertStepsToCodeFormat(steps), [steps]);
  const currentStep = adjustedSteps[currentStepIndex];
  const formMethods = useFormContext<{ [key: string]: Primitive }>();
  const { data: deal } = useCurrentDeal();
  const runAction = useFeatureAction({
    ...currentStep?.footer?.cta,
    location,
    step: currentStepIndex + 1,
  });
  const runSuccessAction = useFeatureAction({
    ...currentStep?.footer?.cta?.success_action,
    location,
    step: currentStepIndex + 1,
  });
  // TODO: APP-1477 as right now we do not support array of actions
  // but we need to run several of them sometimes(show_toast and close modal for example)
  // we introduce after_success_action, that runs after success_action in sequence
  const runAfterSuccessAction = useFeatureAction({
    ...currentStep?.footer?.cta?.after_success_action,
    location,
    step: currentStepIndex + 1,
  });
  const runErrorAction = useFeatureAction({
    ...currentStep?.footer?.cta?.error_action,
    location,
    step: currentStepIndex + 1,
  });

  const handleCTAClick = useCallback(
    async ({ isValid = true }: { isValid?: boolean } = { isValid: true }) => {
      const cta = currentStep?.footer?.cta;
      if (cta?.action === 'next-step') {
        if (isValid) {
          cta?.onCtaClick?.(formMethods?.getValues());
          trackFeatureAction({
            action: 'next-step',
            location,
            step: currentStepIndex,
          });
          setCurrentStepIndex((prev) => prev + 1);
        }
      } else if (cta?.action === 'submit') {
        const schema = cta?.schema;
        const endpoint = cta?.endpoint;

        const onSubmit = async () => {
          let values = formMethods?.getValues();
          if (typeof schema === 'object') {
            values = Object.entries(schema).reduce((acc, [key, value]) => {
              acc[key] = getValue(values, value as string);

              return acc;
            }, {});
          } else if (typeof schema === 'string') {
            values = getValue(values, schema);
          }
          if (endpoint?.url) {
            try {
              const endpointUrl = endpoint.url;

              const res = await fetch(
                parametrizeUrl({ url: endpointUrl, deal, dealId }),
                {
                  method: endpoint.method || 'POST',
                  // TODO remvoe this after confirm that mileage-report is good
                  // added for debug(originUrl)
                  body: endpoint?.url?.includes('mileage-report')
                    ? JSON.stringify({
                        ...values,
                        dealId,
                      })
                    : JSON.stringify(values),
                }
              );

              if (!res.ok) {
                throw new Error('Failed to submit');
              }

              runSuccessAction();
              runAfterSuccessAction();
            } catch (e) {
              runErrorAction();
            }
          } else {
            await onFormSubmit?.(values);
          }
        };
        await formMethods?.handleSubmit?.(onSubmit)();
      } else {
        runAction();
        if (withAutoClose) {
          onClose?.();
        }
      }
    },
    [
      location,
      currentStepIndex,
      deal,
      dealId,
      formMethods,
      currentStep,
      runAction,
      runSuccessAction,
      runAfterSuccessAction,
      runErrorAction,
      onFormSubmit,
      withAutoClose,
      onClose,
    ]
  );

  const handleBack = useCallback(
    () => setCurrentStepIndex((prev) => prev - 1),
    []
  );

  return (
    <>
      {renderChildren({
        ...currentStep,
        location,
        currentStepIndex,
        canGoBack: currentStepIndex > 0,
        title: currentStep.title || title,
        // TODO extract caption to cosmic
        caption:
          adjustedSteps.length > 1
            ? `${currentStepIndex + 1} von ${adjustedSteps.length}`
            : '',
        renderContent: currentStep.render,
        onCTAClick: handleCTAClick,
        onSecondaryCTAClick: handleBack,
        onBack: handleBack,
        onClose,
      })}
    </>
  );
};
