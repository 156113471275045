import { LoyaltyBenefitsData } from '@finn/platform-modules';
import { traceabilityHeadersBrowser, useSession } from '@finn/ui-utils';
import axios from 'axios';
import useSWR from 'swr';

// not proxying this endpoint as proxying through UA app will not work on CP deploy preview and proxying through CP will not work on prod
const LOYALTY_BENEFITS_ENDPOINT = '/api/getLoyaltyBenefits';
const loyaltyBenefitsFetcher =
  async (): Promise<LoyaltyBenefitsData | null> => {
    try {
      const response = await axios.get(LOYALTY_BENEFITS_ENDPOINT, {
        headers: traceabilityHeadersBrowser(),
      });

      return response.data;
    } catch {
      return null;
    }
  };

export const DEDUPING_INTERVAL = 300000;

export const useGetLoyaltyBenefits = () => {
  const [session] = useSession();

  const { data, isLoading } = useSWR(
    session ? { key: 'loyaltyBenefits' } : null,
    loyaltyBenefitsFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
      dedupingInterval: DEDUPING_INTERVAL,
    }
  );

  return { data, isLoading };
};
