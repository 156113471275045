import { ChangeFee, UpdateReturnData } from '@finn/b2c-cp/bff/core-api/types';
import { createFetcher } from '@finn/ui-utils';
import useSWR from 'swr';

export const useReturnChangeFee = (
  dealId: string | undefined,
  returnData: UpdateReturnData | undefined
) => {
  const shouldFetch = dealId && returnData;

  const normalizedReturnData =
    returnData?.return_type === 'PICKUP'
      ? {
          ...returnData,
          // as this field is optional, it arrives as undefined but the text input makes it an empty string
          // if empty, make it undefined again to achieve caching consistency
          return_address_extra: returnData.return_address_extra || undefined,
        }
      : undefined;

  // cache key based on dealId, API resource and data
  const cacheKey = shouldFetch
    ? `${dealId}/return-change-fee:${JSON.stringify(normalizedReturnData)}`
    : null;

  const { data: responseData, isLoading } = useSWR<{
    data: ChangeFee;
  }>(
    cacheKey,
    shouldFetch
      ? () =>
          createFetcher({
            baseURL: '',
            withCredentials: true,
          })({
            url: `/portal/api/bff/deals/${dealId}/return-change-fee`,
            method: 'POST',
            body: normalizedReturnData,
          }) as Promise<{
            data: ChangeFee;
          }>
      : null
  );

  return {
    data: responseData?.data?.amount,
    isLoading,
  };
};
